import { $ } from "../third-party-imports"
import { verifyOtpRegFun } from "./verifyOtpRegFun";
import { generateOtpNumber } from "./generateOtpNumber";
import { validatePhoneNumber } from "./validatePhoneNumber";


export function resendCodeFun(phoneNumber: any)
{
    $('.recent-code ul li a').off('click').click(function ()
    {
        var phoneVerify = phoneNumber;
        var type = $(this).data('type');
        if(phoneNumber.contactDetail){
            phoneVerify = phoneNumber.contactDetail
        }
        if (phoneNumber)
        {
            generateOtpNumber(phoneVerify, type, function (data: any, success: any)
            {
                if (data.status == 'Success')
                {
                    validatePhoneNumber(data.neighborlyAccountOtpid);
                    verifyOtpRegFun(data.neighborlyAccountOtpid, phoneNumber);
                }
            });
        }
    });
}
