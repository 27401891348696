import {get_data,remove_data} from "../util";
export function setAboutHomeData(){
    let setAboutDataObj = get_data('aboutYourHome');
    if(setAboutDataObj){
        $('.about-home-screen .next').removeClass('disble-btn').prop('disabled', false);
        $('#about-home-screen #inputsquarefeet').val(setAboutDataObj.squareFeet);
        $("#about-home-screen #inputbedrooms option[value="+setAboutDataObj.bedrooms+"]").attr('selected','selected');
        $("#about-home-screen #inputbathrooms option[value="+setAboutDataObj.inputbathrooms+"]").attr('selected','selected');
        $("#about-home-screen #reference-details option[value="+setAboutDataObj.referenceDetails+"]").attr('selected','selected');
        remove_data('aboutYourHome');
    }
}