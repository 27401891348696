import { getMSALInstance } from "../config";

export const logout = () =>
{
    const myMSALObj = getMSALInstance();
    localStorage.removeItem('loginWith');
    localStorage.removeItem('profile');
    localStorage.removeItem('isRememberMeNotSelected');
    sessionStorage.removeItem('isRememberMeNotSelected');
    myMSALObj.logout();
}
