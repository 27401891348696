
import { $ } from "../third-party-imports"
export function keywordSearch()
{
	const searchEl = document.getElementById('keywordSearch') as HTMLInputElement | null;
    const searchValue = searchEl?.value?.trim() ?? "";
    const searchRegex = /^[a-zA-Z0-9-\s]+$/g;
    const errMsg = document.querySelector('#searchInputErrMsg');

    if (!searchRegex.test(searchValue))
    {

        if (errMsg)
            return;

        const errorElem = `<div class="error help-block invalid-feedback" id="searchInputErrMsg" style="visibility: visible;margin-left: 55px;">Please enter a valid search term</div>`;
        $('.srchBtnn').after(errorElem);

        if (window.g2Data.response.numFound === 0)
        {
            $('.pagination-box').remove();
        }

        return;
    }

    if (errMsg)
    {
        $('#searchInputErrMsg').remove();
    }

    if (!searchValue)
        return;
    let searchTermUpdated = searchValue.replace(/\s/g, '-');
    searchTermUpdated = searchTermUpdated.replace(/\'/g, '#');
    
    var afterCom =window.location.href.split('.com')[1];
    var isWebSite =  afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    if (isWebSite == 'ca'){
    window.location.href = encodeURI(`${window.location.origin}/ca/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
    //window.location.href = encodeURI(`${window.location.origin}/expert-tips/search/${encodeURIComponent(searchValue)}`);
    }
    else{
        window.location.href = encodeURI(`${window.location.origin}/expert-tips/search/${encodeURIComponent(searchTermUpdated)}`);
    }
}
