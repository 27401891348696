import { apiConfig } from "../config";
import { deleteNotification } from "./deleteNotification";
import { getNblyMicroServiceApiHeaders } from '../api/getNblyMicroServiceApiHeaders';
import { $ } from "../third-party-imports"
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { start_loader, stop_loader } from "../util";

// Read Notification
export function notificationAsReadByUser()
{
    $(".nbly_notification").on("click", "li", function ()
    {
        var nblyNotificationId = $(this).attr("data-notificationId");
        if (nblyNotificationId)
        {
            // start_loader();
            $.ajax({
                url: apiConfig.notificationApi + 'NotificationAsRead/' + nblyNotificationId+"?"+getNblyApiHeaders(),
                type: 'PUT',
                headers: getNblyMicroServiceApiHeaders(),
                success: function (data, status)
                {
                    // stop_loader();
                    if (data == "success")
                    {
                        deleteNotification(nblyNotificationId);
                    }
                },
                error: function (error)
                {
                    // stop_loader();
                    console.log(error);
                }
            });
        }
    });
}
