import { neiLocalStorage } from "../config";

export const isRememberMe = () =>
{
    if (!neiLocalStorage.accessToken)
    {
        return false;
    }
    let decodeData = neiLocalStorage.decodedAccessToken;
    return decodeData.isRememberMe === "True";
}
