import { $ } from "./jquery-global";

import "jquery-validation";
import "./jquery-ui";
import "owl.carousel";
import "magnific-popup";
//import "popper.js";
import 'bootstrap';
import "./lazyload";
import "./popper.min";

$.fn.center = function (this: JQuery)
{
    this.css("position", "absolute");
    this.css("top", ($(window).height() - this.height()) / 2 + $(window).scrollTop() + "px");
    this.css("left", ($(window).width() - this.width()) / 2 + $(window).scrollLeft() + "px");
    return this;
}

export { $ };