import { $ } from "../third-party-imports";
import { get_data } from "../util";
import {getReviewBrandBox} from "../util/getReviewBrandBox"

$(function ()
{
    if($('.five-star-badge-display a').attr("href")) {
    let conceptId = $('.five-star-badge-display a').attr("href").split("cid=")[1];
    conceptId && getReviewBrandBox(conceptId);
    }
    
    // $('.js-next-btn').click(function() {
    //     let nextId = $(this).parents('.tab-pane').next().attr("id");
    //     $('[href="#' + nextId + '"]').tab('show');

    //     if (nextId == "step2" || nextId == "step3" || nextId == "step4" || nextId == "step5" || nextId == "step6") {
    //         $('.scheduler-modal .modal-content').addClass('banner-wizard');
    //     } else {
    //         $('.scheduler-modal .modal-content').removeClass('banner-wizard');
    //     }
    //     return false;

    // })
    // $('.js-previous-btn').click(function() {
    //     let prevId = $(this).parents('.tab-pane').prev().attr("id");
    //     if (prevId == "step1" || prevId == "step2" || prevId == "step3" || prevId == "step4" || prevId == "step5" || prevId == "step6") {
    //         $('.scheduler-modal .modal-content').addClass('banner-wizard');
    //     }

    //     $('[href="#' + prevId + '"]').tab('show');

    //     return false;
    // })
    //$('a[data-toggle="tab"]').on('shown.bs.tab', function(e: any) {
    //$(document).on('click', '#schedulerWizard .js-next-btn', function(e) {
    
    $('.first').click(function() {
        $('#schedulerWizard a:first').tab('show')
    })
    if ($('.js-nei-popover').length) {
        $('.js-nei-popover').popover({
            html: true,
            title: function() {
                return $('.popover-title').html();
            },
            content: function() {
                return $('.popover-content').html();
            }
        });

        $('.js-nei-popover').on('click', function() {
            $("body").addClass('stamp-popover-open');
        });
    }
    $(document).on('click', '.js-close-icon', function() {
        $('.js-nei-popover').popover('hide');
        setTimeout(function() {
            $("body").removeClass('stamp-popover-open');
        }, 200);
    });

    $(document).on("click", ".popover", function(e) {
        e.stopPropagation();
        $('.js-nei-popover').popover('hide');
    });

    if ($('.js-service-list-control').length) {
        $(document).on('keyup', '.js-service-list-control', function(e) {
            var listLength = $('.location-search input[name="locationSearch"]').val();
            if (listLength != "") {
                $('.js-services-list-dd').show();
                $('#serviceEntryText-error').css('top','81px');
            } else {
                $('.js-services-list-dd').hide();
                $('#serviceEntryText-error').css('top','40px');
            }
        });
    }
    // $("input[name='chkFrequesy']").click(function() {

    //     if ($("#inputOneTime").is(":checked")) {
    //         //$("#js-one-time-data").show();
    //         $("#js-recurring-data").hide();
    //     }
    //     if ($("#inputRecurring").is(":checked")) {
    //         $("#js-recurring-data").show();
    //         $("#js-one-time-data").hide();

    //     }
    // });

});

export function setProgressBar(step: string) {   
    //update progress
    //let step = $(e.target).data('step');
    //let step = $(this).closest('.tab-pane').attr('id');
    let percent; //((parseInt(step) / 6) * 100).toFixed(2);
    let isMRAWarranty:Boolean = get_data('conceptID') == '8' && window?.objOSGlobal?.requestBodyValues?.Warranty == true;
    let isLeadOnly = window?.objOSGlobal?.requestBodyValues?.IsLeadOnly;

    let startStepPercent: Number = window.innerWidth < 767 ? 6.3 : step == "contact-information-screen" ? 3.7 : 3.3;
    let modifier = sessionStorage.getItem('ServiceModifierValue');
    switch (step) {
        case "1":
            percent = '0';
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "2":
            percent = '0';
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "3":
            percent = '0';
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "calendar-screen":
            percent = startStepPercent; //3.3;
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "about-home-screen":
            if(modifier =='Commercial'){
                percent = startStepPercent;
            }else{
                if(isLeadOnly){
                    percent = startStepPercent; //3.7;
                }else{
                    percent = (get_data('selectedAboutHome') == 'onetime' || 'recurring') ? 20 : 26;                    
                }
            } 
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "estimate-screen":
            if(isLeadOnly){
                percent = 26;
            }else{
                percent = 41;
            }
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "contact-information-screen":
            if(get_data('conceptID') == 1){
                if(modifier =='Commercial'){
                    percent = 36;
                }else{
                    percent = isLeadOnly ? (get_data('selectedAboutHome') == 'onetime' || 'recurring') ? 52 : 36 : (get_data('selectedAboutHome') == 'onetime' || 'recurring') ? 62.1 : 52;
                }
            }else if(get_data('conceptID') == 8){
                percent = isLeadOnly && !isMRAWarranty ? startStepPercent : isMRAWarranty ?  startStepPercent : 26;
            }else if(get_data('conceptID') == 14){
                percent = isLeadOnly ?  startStepPercent : 20;
            }else{
                percent = isLeadOnly ?  startStepPercent : 26;
            }
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "service-address-screen":
            if(get_data('conceptID') == 1){
                if(modifier =='Commercial'){
                    percent = 68;
                }else{
                    percent = isLeadOnly ? (get_data('selectedAboutHome') == 'onetime' || 'recurring') ? 77 : 68 : (get_data('selectedAboutHome') == 'onetime' || 'recurring') ? 84 : 77;
                }
                
            }
            else if(get_data('conceptID') == 14) {
                percent = isLeadOnly ? 26 : 41; //get_data('selectedPLGFilter') == 'Auto' ? isLeadOnly ? 26 : 41 :
            }
            else{
                percent = isLeadOnly || isMRAWarranty ?  36 : 52;
            }
            $('.progress-bar').css({ width: percent + '%' });
            if($('#service-address-screen select[name="state"]').children("option:selected").val() !== '') {
              $("#service-address-screen .next").removeClass("disble-btn");
              $("#service-address-screen .next").prop("disabled", false);
            }else {
              $("#service-address-screen .next").addClass("disble-btn");
              $("#service-address-screen .next").prop("disabled", true);
            }
            break;
       case "about-service-needs-screen":
            percent = isLeadOnly ? 52 : 62.1; //get_data('selectedPLGFilter') == 'Auto' ? isLeadOnly ? 52 : 62.1 :
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "vehicle-information-screen":
            percent = isLeadOnly ? 77 : 84; //get_data('selectedPLGFilter') == 'Auto' ? isLeadOnly ? 77 : 84 :
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "service-details-screen":
            if (get_data('conceptID') == '14') {
                percent = isLeadOnly ? 77 : 84;
            }
            else {
                percent = isLeadOnly || isMRAWarranty ?  68 : 77;
            }            
            $('.progress-bar').css({ width: percent + '%' });
            break;
        case "8":
            percent = '100';
            $('.progress-bar').css({ width: percent + '%' });
            $(".progress").addClass("d-none");
            $(".progressbar").addClass("d-none");
            $(".modal-header-two").addClass("d-none");
            $(".modal-header-one").addClass("d-none");
            break;
        default:
            percent = startStepPercent;
            $('.progress-bar').css({ width: percent + '%' });
            break;
    }


    //$('.progress-bar-info').text("" + percent + "% complete");
}

window.addEventListener("resize", () => {
    if($('#js-schedulerDialog .progress-bar').offsetParent != null && $('.progress').css("display") === 'block'){
        var step = $('#schedulerWizard .tab-pane.active').attr('id');
        setProgressBar(step);
    }
});