
export const b2cPolicies = {
    names: {
        signUpSignIn: window.DEFINE_VALUE.JS_signInPolicy,
        forgotPassword: window.DEFINE_VALUE.JS_changePasswordPolicy,
        signUpPolicy: window.DEFINE_VALUE.JS_signUpPolicy
    },
    authorities: {
        signUpSignIn: {
            authority: window.DEFINE_VALUE.JS_APP_ADB2C_INSTANCE +"/"+ window.DEFINE_VALUE.JS_APP_ADB2C_TENANT +"/"+ window.DEFINE_VALUE.JS_signInPolicy,
        },
        signUpPolicy:{
            authority: window.DEFINE_VALUE.JS_APP_ADB2C_INSTANCE +"/"+ window.DEFINE_VALUE.JS_APP_ADB2C_TENANT +"/"+ window.DEFINE_VALUE.JS_signUpPolicy,
        },
        forgotPassword: {
            authority: window.DEFINE_VALUE.JS_APP_ADB2C_INSTANCE +"/"+ window.DEFINE_VALUE.JS_APP_ADB2C_TENANT +"/"+ window.DEFINE_VALUE.JS_changePasswordPolicy,
        }
    },
}