import { $ } from "../third-party-imports"

export function window_Size()
{
    var windWidth = $(window).width();

    //neighbotly_leftTxt
    if (windWidth <= 1024)
    {
        $('.neighbotly_leftTxt').removeAttr('style');
    }

    else
    {
        var offset = $('footer .container').offset();
        var left = offset.left;
        $('.neighbotly_leftTxt').css('padding-left', left);
    }

    //neighbotly_topTxt
    let houseJq = $(".house_row");
    if (houseJq.length)
    {
        var offset = houseJq.offset();
        var posY = offset.top - $(window).scrollTop();
        $('.neigh_top_txt').css({ 'padding-top': posY / 2 - 45 });
    }
}
