import { getNblyMicroServiceApiHeaders } from "../api";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { $ } from "../third-party-imports";
import { start_loader, stop_loader } from "../util";

// Get Count All Notification
export function getCountAllNotification()
{
    // start_loader();
    $.ajax({
        url: apiConfig.notificationApi + 'WebNotificationsCount'+"?"+getNblyApiHeaders(),
        type: 'GET',
        headers: getNblyMicroServiceApiHeaders(),
        success: function (data, status)
        {
            // stop_loader();
            $('.notify-count').text(data);
        },
        error: function (error)
        {
            // stop_loader();
            console.log(error);
        }
    });

}
