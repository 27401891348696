import { neiLocalStorage } from "../config";

export function isAccessTokenExpire()
{
    if (!neiLocalStorage.accessToken)
    {
        return true;
    }
    let decodeData = neiLocalStorage.decodedAccessToken;
    let exp = decodeData.exp;
    let currentTime = Math.floor(Date.now() / 1000);
    return currentTime > exp;
}
