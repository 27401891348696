import { $ } from "../third-party-imports";
import { profileVars } from ".";

export function setProfileName(profile: any)
{
    $('.profileName').append('<div class="text-withedit profileName">' +
        '<div class="label-name">' +
        '<div class="labelTxtBox">' +
        '<div class="labelTxt">' + profile.firstName + ' ' + profile.lastName + '</div>' +
        '</div>' +
        '</div>' +
        '<div class="editBox">' +
        '<a href="#" class="link-btn editLink" data-attr="editName">Edit</a>' +
        '</div>' +
        '</div>'
    );
    $('.profileName .editLink').click(function ()
    {
        profileVars.allowSubmit = true;
        $('#profileFName').val(profile.firstName);
        $('#profileLName').val(profile.lastName);
        $('.form_container_wrapperBox:first-child').hide();
        $('#editName').addClass('active');
    });
}
