
import { $ } from "../third-party-imports";
import { extractVidId } from "./extractVidId";


$(function ()
{
    if ($('.owl-carousel-Testimonial, .owl-carousel-videos, .owl-carousel-photo, .owl-carousel-expert').length)
    {
        // Carousol Testimonial Start
        $('.owl-carousel-Testimonial').owlCarousel({
            margin: 10,
            nav: false,
            loop: true,
            autoplay: true,
            responsive: {
                0: {
                    items: 1,
                    margin: 0,

                },
                520: {
                    items: 1,
                    stagePadding: 0
                },
                768: {
                    items: 1,
                    stagePadding: 100
                },
                1000: {
                    items: 1,
                    stagePadding: 300
                },
                1366: {
                    items: 1,
                    stagePadding: 340
                },
                1400: {
                    items: 1,
                    stagePadding: 365
                }
            }
        })
        // Carousol Testimonial End
        // Carousol Photo Start
        $('.owl-carousel-photo').owlCarousel({
            margin: 20,
            nav: false,
            loop: true,
            autoplay: false,
            dotsEach: true,
            items: 1,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 80
                },
                520: {
                    items: 1,
                    stagePadding: 80
                },
                768: {
                    items: 3,
                    stagePadding: 100
                },
                1000: {
                    items: 3,
                    stagePadding: 200
                },
                1400: {
                    items: 3,
                    stagePadding: 265
                },
                2250: {
                    items: 4,
                    stagePadding: 300
                }
            }
        })
        // Carousol Photo End
        // Carousol Videos Start
        $('.owl-carousel-videos').owlCarousel({
            margin: 20,
            nav: false,
            loop: true,
            dotsEach: true,
            autoplay: false,
            responsive: {
                0: {
                    items: 1,
                    stagePadding: 50
                },
                520: {
                    items: 1,
                    stagePadding: 50
                },
                768: {
                    items: 3,
                    stagePadding: 100
                },
                1000: {
                    items: 3,
                    stagePadding: 100
                },
                1400: {
                    items: 3,
                    stagePadding: 120
                },
                2250: {
                    items: 4,
                    stagePadding: 300
                }
            }
        })
        // Carousol Videos End
        // Carousol Expert Start
        $('.owl-carousel-expert').owlCarousel({
            margin: 20,
            nav: false,
            loop: true,
            autoplay: true,
            responsive: {
                0: {
                    items: 1
                },
                520: {
                    items: 1,
                    stagePadding: 0
                },
                768: {
                    items: 1,
                    stagePadding: 100
                },
                1000: {
                    items: 1,
                    stagePadding: 200
                },
                1400: {
                    items: 1,
                    stagePadding: 200
                },
                1900: {
                    items: 1,
                    stagePadding: 300
                },
                2200: {
                    items: 1,
                    stagePadding: 500
                },
                2570: {
                    items: 2,
                    stagePadding: 500
                }
            }
        })
        // Carousol Expert End
        
        $('.owl-carousel.owl-carousel-videos').owlCarousel({
            loop: true,
            margin: 30,
            nav: true,
            startPosition: 2,
            responsive: {
                0: {
                    items: 1
                },
                600: {
                    items: 3
                },
                1000: {
                    items: 5
                }
            }
        });
    }

    //-------------------------owl carousel  youtube url logic --------------------


    $(".itemspan").map(function (this: any)
    {
        let videoId = extractVidId($(this).text().trim());

        let videosRow = '<div class="item">' +
            '<div class="videos-row">' +
            ' <a id="vid-link" href="https://www.youtube.com/watch?v=' + videoId + '" target="_blank"> ' +
            ' <img src="https://img.youtube.com/vi/' + videoId + '/0.jpg' + ' " alt='+ videoId +' loading="lazy"/>' +
            '</a>' +
            '</div>' +
            '</div>';

        return videosRow;
    }).get();
});

