export function aboutHereAboutYourHome(){
    let referencedetails = $('.about-home-screen #reference-details').val()
    let squareFeet = $(".about-home-screen input[name='squarefeet']").val();
    let inputbathrooms = $(".about-home-screen #inputbathrooms").val();
    let inputbedrooms = $(".about-home-screen #inputbedrooms").val();
    if (referencedetails != '' && inputbathrooms != '' && squareFeet != '' && inputbedrooms !=''){
        return true;
    }else{
        return false;
    }
}