// validation mobile promo start 
import { $ } from "../third-party-imports"

export function validatePhoneUsAndCa(currentEl:any,event: any)
{
    var phoneNumber = currentEl.value;
    var phoneNumberReg = /^([\s-]?)?\(?[2-9]\d\d\)?[\s-]?[2-9]\d\d[\s-]?\d\d\d\d$/;
    if (phoneNumberReg.test(phoneNumber))
    {
        $(currentEl).siblings('.searchEnterIcon').addClass('active-input');
        if (event.keyCode === 13)
        {
            $(currentEl).siblings('.searchEnterIcon').click();
        }
    }
    else
    {
        $(currentEl).siblings('.searchEnterIcon').removeClass('active-input');
    }
}
