export function msieversion() 
{
    var user_agent = window.navigator.userAgent;
    var msie = user_agent.indexOf("MSIE ") > -1 || user_agent.indexOf("Trident/") > -1;

    if (msie) // If Internet Explorer, return version number
    {
        $('#js-schedulerDialog').modal('show');
        $("#no-search-result .head-title").text("You're using an unsupported browser.");
        $("#no-search-result .head-title").append("<p>Please use the latest version of Google Chrome, Safari, Microsoft Edge, or Mozilla Firefox to have the best experience on our site.</p>");
        $("#no-search-result").addClass('active');
    }
}