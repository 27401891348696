/**
 * Filter Blog from List
 */
export function filterBlogFromList(blog: any)
{
    var blogTitle = blog.custom_s_context_url.split("/")[blog.custom_s_context_url.split("/").length-1];
    blogTitle = blogTitle.replace(/["']/g, ""); //remove single/double quote
    blogTitle = blogTitle.replace(/[^a-zA-Z0-9 ]/g, ""); //remove special character
    blogTitle = blogTitle.split(" ").join("").toLowerCase(); //remove space and convert to lowercase

    var decodeHref = decodeURI(window.location.href);
    var blogTitleToCheck = decodeHref.substring(decodeHref.lastIndexOf('/') + 1);
    blogTitleToCheck = blogTitleToCheck.replace('%E2%80%99', ''); //remove %E2%80%99
    blogTitleToCheck = blogTitleToCheck.replace(/["']/g, ""); //remove single/double quote
    blogTitleToCheck = blogTitleToCheck.replace(/[^a-zA-Z0-9 ]/g, ""); //remove special character
    blogTitleToCheck = blogTitleToCheck.split(" ").join("").toLowerCase(); //remove space and convert to lowercase
    if (blogTitle === blogTitleToCheck)
    {
        return true;
    }
}
