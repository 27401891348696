
import { ServiceResultItem } from "./ServiceResultItem";
import { customerReviewService } from '../services';

export async function NPSScore(value: ServiceResultItem)
{
    if (!value.npsScore || value.netPromoterStarRating <= 0)
    {
        return {
            css: "float:none;padding-right:0px",
            stars: ""
        };
    }
    else{
        const params = {
            page: 1,
            pageSize: 10,
            conceptId: value.conceptID,
            dataSource: "embed" as const,
            franchiseId: value.franchiseId,
            franchiseWebLocationId: value.franchiseWebLocationId,
            franchiseWebLocationMarketingId: value.franchiseWebLocationMarketingId
        };

        const {reviews} = await customerReviewService.loadReviews(params);
        let displayCount:number,rating:number;
        if (reviews.length && reviews[0].type =='aggregate'){
            rating = reviews[0].overallRating;
            displayCount = reviews[0].grandTotal;
        }

        // const { displayCount } = await customerReviewService.loadPageCount(params);
        // const rating = await customerReviewService.loadRating(params);

        const afterCom = window.location.href.split('.com')[1];
        const isWebSite =  afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
        const url= isWebSite == "ca" ? "/ca" : "";

        return {
            css: "float:right;padding-right:60px",
            stars: `
            <div class="result-stack-rating">
                <div class="center-flex">
                    <five-star-badge-display
                        rating="${rating}"
                        review-count="${displayCount}"
                        badge-url="${url}/customer-reviews?page=1&conceptId=${value.conceptID}&franchiseWebLocationId=${value.franchiseWebLocationId}"
                    >
                    </five-star-badge-display>
                </div>
            </div>`
        };
    }
}
