import { neiLocalStorage } from "../config";
import { makeid } from ".";


export function getTransactionID()
{    
    if(neiLocalStorage){    
        var decodeData = neiLocalStorage.decodedAccessToken;
        var userStatus = decodeData.tid ? decodeData.tid : "";
        var uniqueId = makeid(5);
        if (userStatus)
        {
            return decodeData.tid;
        }
        var timeStamp = Math.round(new Date().getTime() / 1000) + 120;
        return uniqueId + '-' + timeStamp;
    }
    else{
        return sessionStorage.getItem('corelationID');
    }
}
