export function start_loader ()
{
    // $('#loader-wrapper-img').show();
    $('#loader-wrapper-img').addClass('loader-wrapper-test');;
    $('#img-loader').addClass('loader-ring-test');
    $('body').append('<div id = "overlayrow"></div>');
    $('#overlayrow').addClass('overlay');
    // $("body").find("*").attr("disabled", "disabled");
    // $("body").find("a").bind('click', false);
    // $("input").attr('disabled','disabled');
}