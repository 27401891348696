import { getNblyMicroServiceApiHeaders } from "../api";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { setProfileName, setProfileEmail, setMultipleContact, linkAndUnlinkSocial, setMultipleaddress } from "../profile";
import { $ } from "../third-party-imports";
import { start_loader, stop_loader} from "../util";


export function updateProfilInfo(userDetails: any)
{
    /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
    $.ajax({
        url: apiConfig.profileApi+"?"+getNblyApiHeaders(),// + userDetails.neighborlyUserId,
        headers: getNblyMicroServiceApiHeaders(),
        type: "GET",
        success: function (data, status)
        {
            $("body").find('header').removeClass('hideHeader');
            $('#js-popupModalDialog .popupModalbody').removeClass("padfingTop40");
            $('#js-popupModalDialog .closeDialogCont').show();
            $('#js-popupModalDialog .close.js-closePopUpModal').show();
            $('#js-popupModalDialog  #editPhone .top_txt .delete').show();
            $('.text-withedit').remove();
            setProfileName(userDetails);
            setProfileEmail(userDetails);
            setMultipleContact(userDetails);
            setMultipleaddress(userDetails);
            linkAndUnlinkSocial(userDetails);
            // stop_loader();
        },
        error: function (xhr, status, error)
        { 
            //stop_loader();
            console.log(error);
        }
    });
}
