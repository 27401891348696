
import { $ } from "../third-party-imports"

export function windSize()
{
    var windWidth = $(window).width();
    /**Topmenu code copy in mobile-view--start*/
    if (windWidth <= 1023)
    {
        var all_html = $('.reqAsstBtn').html();
        $('.reqAsstBtn_for_mobile').html('');
        $('.reqAsstBtn_for_mobile').html(all_html);
        $('.reqAsstBtn_for_mobile').show();
        $('.reqAsstBtn').hide();
    }
    else
    {
        $('.reqAsstBtn_for_mobile').hide();
        $('.reqAsstBtn').show();
    }

    //Banner_leftTxt
    if ($('.bannerTopBox').length)
    {
        if (windWidth <= 1024)
        {
            $('.bannerTopBox').removeAttr('style');
        }
        else
        {
            var offset = $('footer .container').offset();
            var left = offset.left + 12;
            $('.bannerTopBox').css('padding-left', left);
        }
    }
}
