import { init as initApm } from '@elastic/apm-rum'
var apm = initApm({
 // Set required service name (allowed characters: a-z, A-Z, 0-9, -, _, and space)
 serviceName: window.DEFINE_VALUE.SERVICE_NAME,
 // Set the version of your application
 // Used on the APM Server to find the right sourcemap
 serviceVersion: window.DEFINE_VALUE.SERVICE_VERSION,
 // Set custom APM Server URL (default: http://localhost:8200)
 serverUrl: window.DEFINE_VALUE.APM_SERVER_URL,

 distributedTracingOrigins: [window.DEFINE_VALUE.DISTRIBUTED_TRACING_ORIGINS],
 apmRequest({ xhr }) {
    xhr.setRequestHeader('x-requested-with', 'xmlhttprequest')
    return true
    }    
})
export default apm;