export function errorModal(value: any)
{
    $('#js-schedulerDialog').modal('show');
    $(".tab-pane.fieldset").removeClass('active');
    $("#no-search-result").addClass('active');
  if(!value){
    $("#no-search-result .head-title").text('Due to a system error, we didn’t receive the form you sent. Please try again later.');
  }
  else {
    $("#no-search-result .head-title").text(value);
  }
}