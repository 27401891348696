import { filterBlogFromList } from "./filterBlogFromList";
import { $ } from "../third-party-imports"

export function updateFooterLinks(allBlogs: any)
{
    var allBlogs = allBlogs;
    var currentBlogIndex: number = null;
    $.each(allBlogs, function (index, blog)
    {
        if (filterBlogFromList(blog) === true)
        {
            currentBlogIndex = index as number;
        }
    });

    if (currentBlogIndex === null)
    {
        $($('.returnPageLink .backLinkArrow')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .backLinkArrow')[0]).addClass('disable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('disable-next-link');
        return;
    }
     
    if (currentBlogIndex < 1 && currentBlogIndex < (allBlogs.length - 1))
    {
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        $($('.returnPageLink .backLinkArrow')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .backLinkArrow')[0]).addClass('disable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).attr("href", `${window.location.origin}${nextBlogURL}`);
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('enable-next-link');
    } 
    else if (currentBlogIndex >= 1 && currentBlogIndex < (allBlogs.length - 1))
    {
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        var nextBlogURL = allBlogs[currentBlogIndex + 1].custom_s_local_url;
        $($('.returnPageLink .backLinkArrow')[0]).attr("href", `${window.location.origin}${prevBlogURL}`);
        $($('.returnPageLink .backLinkArrow')[0]).addClass('enable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).attr("href", `${window.location.origin}${nextBlogURL}`);
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('enable-next-link');
    } 
    else if (currentBlogIndex > 1 && currentBlogIndex >= (allBlogs.length - 1))
    {
        var prevBlogURL = allBlogs[currentBlogIndex - 1].custom_s_local_url;
        $($('.returnPageLink .backLinkArrow')[0]).attr("href", `${window.location.origin}${prevBlogURL}`);
        $($('.returnPageLink .backLinkArrow')[0]).addClass('enable-back-link');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).css('pointer-events', 'none');
        $($('.returnPageLink .nextArticleLink .linkText')[0]).addClass('disable-next-link');
    }
}
