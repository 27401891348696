export function addressStateValidate(){
    let address = $('#service-address-screen input[name="address"]').val();
    let city =  $('#service-address-screen input[name="city"]').val();
    let zipcode =  $('#service-address-screen input[name="zipcode"]').val();
    let addresState = $('#service-address-screen select[name="state"]').children("option:selected").val()
    if(addresState!=''&& address!=''&& city!='' && zipcode!=''){
        return true;
    }else{
        return false;
    }
}