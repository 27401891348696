
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { $, googleMaps } from "../third-party-imports"
import { checkZip } from "./checkZip";
import { getBrandList } from "./getBrandList";
import { getPropTypes } from "./getPropTypes";
import { get_data } from "./get_data";
import { isValidUsOrCanadaZip } from "./isValidUsOrCanadaZip";
import { mobileAppPromo } from "./mobileAppPromo";
import { msieversion } from "./msieversion";
import { phoneFormatterNei } from "./phoneFormatterNei";
import { remove_data } from "./remove_data";
import { serviceCategoryByName } from "./serviceCategoryByName";
import { store_data } from "./store_data";
import { validatePhoneUsAndCa } from "./validatePhoneUsAndCa";
import { youtubeInit } from "./youtubeInit";
import { start_loader } from "./start_loader";
import { stop_loader } from "./stop_loader";

$('.invalid-zip-meg').css('visibility','hidden');
$(".postalSearchInput").keyup(function (this: any)
{
    var charLengthV = ($(this).val() as string).length

    if (charLengthV <= 4)
    {
        $('.invalid-zip-meg').css('visibility','visible');
    } else
    {
    }
    if (charLengthV == 0)
    {
    }
});


$('#currentLocationId').on('click', async function ()
{
    if (navigator.geolocation)
    {
        const { LatLng, Geocoder } = await googleMaps();
        navigator.geolocation.getCurrentPosition(function (position)
        {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var point = new LatLng(lat, long);
            var zipcode = '';

            new Geocoder().geocode({ 'location': point }, function (res, status)
            {
                var address = res[0].address_components;
                for (var i = 0; i < address.length; i++)
                {
                    if (address[i].types.includes("postal_code"))
                    {
                        zipcode = address[i].short_name;
                        $('#zipCode').val(zipcode);
                        if (isValidUsOrCanadaZip(zipcode)){
                            $('#findLocalHelp').prop('disabled', false);
                            remove_data('zipcode');
                            store_data(zipcode, 'zipcode');
                        } else
                        {
                            $('.notValidZip').show();
                        }
                    }
                }
            });
        });
    }
});

$('#findLocalHelp').click(function ()
{
    window.postMessage('ScheduleNow', undefined);
})

var findLocalHelpBtn = document.getElementById('findLocalHelp');
var zipCode = document.getElementById("zipCode") as HTMLInputElement;
let MRAproblemlist: any[] = [];

if (zipCode){
    zipCode.addEventListener('input', function ()
    {
        checkZip(zipCode.value, findLocalHelpBtn);
    });
}
if (findLocalHelpBtn){
    findLocalHelpBtn.addEventListener('click', function (){
        store_data(zipCode.value, 'zipcode');
        $('#js-serviceTypeDialog').css("display", "block");
        $('.servive-form-wrapperBox').removeClass('active');
        $("#service-search-page").addClass("active");
        $('body').addClass('serviceHeaderFixed');
        $('.zipcodeServiceSearch').text(zipCode.value);
        var serviceModifier = 4;
        getPropTypes(zipCode.value,serviceModifier);
    });
}

getBrandList();

$("#serviceCategoryBtn").click(function (event: any){
    event.preventDefault();
    remove_data('servicecategorypage');
    var zipCode = get_data('zipcode');
    var serviceID = get_data('serviceID')
    var serviceSearchValue = $('#service-list-query').val();
    serviceCategoryByName(zipCode, serviceID, serviceSearchValue,false)

})

$("#serviceCategoryBtnFinal").click(function (event: any){
    event.preventDefault();
    remove_data('servicecategorypage');
    var zipCode = get_data('zipcode');
    var serviceID = get_data('serviceID')
    var serviceSearchValue = $('#serviceListQueryFinal').val();
    serviceCategoryByName(zipCode, serviceID, serviceSearchValue,false)

})

$("#js-onlineScheduleDialog .js-closeServiceModal").click(function (){
    window.objOSGlobal.paramsUI = {};
    $('.resultStackLogoImg').parent().addClass('hidden');
    $('.col-md-4.callUsTextCont').addClass("hidden");
    $("#serviceEntryBtn").removeClass('prevent-click');
});

/*-------------------------------------Mr. Appliances-------------------------------------*/

$('#service-address-next').on('click', function (){
    $('select[name="howdidyouhear"] option').remove();
    $('.diagnoseFee').addClass("hidden");

    var leadSourceNames: any[] = [];
    // start_loader();
    $.ajax({
        url: `${apiConfig.genericLeadSources}?apikey=${window.DEFINE_VALUE.JS_API_KEY}`+"&"+getNblyApiHeaders(),
        type: "GET",
        success: function (data: any)
        {
            // stop_loader();
            $.each(data, function (index: any, element: any)
            {
                leadSourceNames.push(`<option id="${element.syncGenericLeadSourceId}" value="${element.syncGenericLeadSourceId}">${element.leadSourceName}</option>`)
            });
            $('select[name="howdidyouhear"]').append(leadSourceNames);
            $('select[name="howdidyouhear"]').prepend("<option value='0'>Where did you hear about us?</option>").val('0');
        }
    });


    var addresses: any[] = $('.service-address-form').serializeArray();

    const address1Obj = addresses.find(form => form.name === 'address1');
    const address2Obj = addresses.find(form => form.name === 'address2');
    const cityObj = addresses.find(form => form.name === 'city');
    const stateObj = addresses.find(form => form.name === 'state');
    const zipcodeObj = addresses.find(form => form.name === 'zipcode');

    window.objOSGlobal.requestBodyValues.Address = address1Obj.value + " " + address2Obj.value;
    window.objOSGlobal.requestBodyValues.City = cityObj.value;
    window.objOSGlobal.requestBodyValues.State = stateObj.value;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcodeObj.value;

    //var formRetrieved=JSON.parse(localStorage.getItem('servicenext'));

    let conceptIDGlobal = window.objOSGlobal.requestBodyValues.ConceptCalledId;

    //conceptIDGlobal = zipcodeObj.value  //For testing the conceptId and brand

    if (conceptIDGlobal == "8")
    {  //mr appliance service needs
        var weblocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
        $('.diagnoseFee').removeClass("hidden");
        /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
         start_loader();
        */
        $.ajax({
            url: apiConfig.franchiseWebLocationProfileFees + weblocationId + '?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
            dataType: 'json',
            type: 'GET',
            success: function (data: any)
            {
                // stop_loader();
                var resultArray = data;
                $.each(resultArray, function (index: any, resultArray: any)
                {
                    if (resultArray.name == "Diagnostic Fee")
                        $('#dFee').text(resultArray.defaultFee);
                });

            }, error: function (e: any)
            {
                stop_loader();
                //console.log(e.message);
            }
        });
        $(".personalInfoEntry.addres-service").addClass("hidden").removeClass("active")
        $(".personalInfoEntry.optionalWarranty").removeClass("hidden").addClass("active")
        $("#optionaldetails-os").addClass("hidden").removeClass("active")
        $('#intServices option').remove();
        $('#brand-appliance option').remove();
        $('#problemsList .col-sm-3').empty();
        var getServices = function()
        {
            var addressType = $('select[name= "AddressType"]').val();
            // start_loader();
            $.ajax({
                url: apiConfig.serviceAggregatesWeblocation + weblocationId + '/service-aggregates/search?apikey=' + window.DEFINE_VALUE.JS_API_KEY + '&include_service_sku_id=true'+"&"+getNblyApiHeaders(),
                dataType: 'json',
                type: 'GET',
                success: function (data: any)
                {
                    // stop_loader();
                    var services = [] as any[];
                    var resultArray = data;
                    var filterServices = resultArray.filter((ser: any) => ser.serviceType == addressType);
                    $.each(filterServices, function (index: any, resultArray: any)
                    {
                        services.push('<option data-serviceId="' + resultArray.serviceId + '" value="' + resultArray.serviceSkuId + '">' + resultArray.serviceSkuName + '</option>')
                    });
                    $('#intServices').append(services);

                }, error: function (e: any)
                {
                    // stop_loader();
                    //console.log(e.message);
                }
            });
        }
        getServices();
        const getManufacturerList = function()
        {
            var conceptid = window.objOSGlobal.requestBodyValues.ConceptCalledId;
            // start_loader();
            $.ajax({
                url: apiConfig.makeByConcept + conceptid + '/makes?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                dataType: 'json',
                type: 'GET',
                success: function (data: any)
                {
                    // stop_loader();
                    var manufacturerList = [] as any[];
                    var resultArray = data;
                    $.each(resultArray, function (index: any, resultArray: any)
                    {
                        manufacturerList.push('<option value="' + resultArray.makeId + '">' + resultArray.makeName + '</option>')
                    });
                    $('#brand-appliance').append(manufacturerList);
                    getApplianceProblemList();

                }, error: function (e: any)
                {
                    // stop_loader();
                    //console.log(e.message);
                }
            });
        }
        getManufacturerList();

        const getApplianceProblemList = function()
        {
            var conceptid = window.objOSGlobal.requestBodyValues.ConceptCalledId;
            // start_loader();
            $.ajax({
                url: apiConfig.serviceTypeProblemAggregates + conceptid + '/service-type-problem-aggregates/search?&apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                dataType: 'json',
                type: 'GET',
                success: function (data: any)
                {
                    // stop_loader();
                    MRAproblemlist = data;
                    problemListAppend();
                    $('#opwarrant-next').removeClass('disble-btn');

                }, error: function (e: any)
                {
                    // stop_loader();
                    //console.log(e.message);
                }
            });
        }
        $(document).on('change', '#intServices', function ()
        {
            problemListAppend();
        });

        const problemListAppend = function()
        {
            var skuId = $("#intServices option:selected").val();
            var filteredProblemList = MRAproblemlist.filter(problem => problem.serviceSkuId == skuId);

            var applianceList = [] as any[];
            var resultArray = filteredProblemList;
            $.each(resultArray, function (index: any, resultArray: any)
            {
                applianceList.push('<div class="col-sm-3"><div class="custom-control text-left custom-checkbox mt-4"><input type="checkbox" class="custom-control-input" id="appliance' + index + '" name="' + resultArray.serviceTypeProblemName + '">	<label class="custom-control-label" for="appliance' + index + '">' + resultArray.serviceTypeProblemName + '</label></div> </div>')

            });
            $('#problemsList').empty();

            $('#problemsList').append(applianceList);
        }

        $(document).on('change', '#warranty', function (this: any)
        {
            if (this.checked)
            {
                window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                window.objOSGlobal.requestBodyValues.Warranty = true;
            } else
            {
                window.objOSGlobal.requestBodyValues.IsLeadOnly = false;
                window.objOSGlobal.requestBodyValues.Warranty = false;
            }
        });

        $("#warrantyForm").on("input", function (e: any)
        {
            var data = $('.service-warranty-form').serializeArray();
            if (data.length > 2 || data[0].value || data[1].value)
                $('#opwarrant-next').get(0).firstChild.nodeValue = "Next ";
            else
                $('#opwarrant-next').get(0).firstChild.nodeValue = "Skip ";
        });

    }
    else
    {
        $(".personalInfoEntry.addres-service").addClass("hidden").removeClass("active")
        $("#optionaldetails-os").removeClass("hidden").addClass("active")
    }
});

$('#opwarrant-next').on('click', function (this: any)
{
    var problems = "";
    if ($(this).text() == 'Next ')
    {
        var mrAppliancenext = {
            serviceInterest: "",
            serviceBrand: "",
            serviceProblem: [] as any[]
        };
        var serviceWarrentInfo = $('.service-warranty-form').serializeArray();
        for (var i = 0; i < serviceWarrentInfo.length; i++)
        {
            if (serviceWarrentInfo[i].name == 'services')
                mrAppliancenext.serviceInterest = serviceWarrentInfo[i].value;
            else if (serviceWarrentInfo[i].name == 'brands')
                mrAppliancenext.serviceBrand = serviceWarrentInfo[i].value;
            else
            {
                problems = problems !== "" ? problems + ", " + serviceWarrentInfo[i].name : serviceWarrentInfo[i].name;
                mrAppliancenext.serviceProblem.push(serviceWarrentInfo[i].name);
            }
            if (i == serviceWarrentInfo.length - 1)
                localStorage.setItem('mrAppliancenext', JSON.stringify(mrAppliancenext));
        }
    }
    else
    {
        localStorage.setItem('mrAppliancenext', '');
    }
    $(".personalInfoEntry.optionalWarranty").addClass("hidden").removeClass("active")
    $("#optionaldetails-os").removeClass("hidden").addClass("active")


    window.objOSGlobal.requestBodyValues.ServiceTypeProblem = problems;
    window.objOSGlobal.requestBodyValues.ServiceSkuId = Number($('#intServices option:selected').val());
    window.objOSGlobal.requestBodyValues.ServiceId = Number($('#intServices option:selected').attr('data-serviceId'));
    window.objOSGlobal.requestBodyValues.ServiceSkuIds = [Number($('#intServices option:selected').val())];
    window.objOSGlobal.requestBodyValues.MakeId = Number($('#brand-appliance option:selected').val());

});

$('.inputMobileNumberMobileApp').on('keyup change', function (event: any)
{
    var currentEl = this;
    validatePhoneUsAndCa(currentEl,event);
})

$(document).ready(function ()
{
    msieversion();
    phoneFormatterNei();
    youtubeInit();
    $("#signIn,#joinIn").on("contextmenu", function(e) {
        return false;
      });
});

/* mobile app promo api start*/
$('#inputMobileNumber + .searchEnterIcon').click(function (this,evt){
    evt.preventDefault();
    var currEle = this;
    var mobile = $(this).siblings('input[name="neiPhoneNumber"]').val();
    var url = $(this).siblings('.fallback-link').val();
    mobile = mobile.toString().replace(/[- )(]/g, '');
    var payload = {
        "UserPhone": mobile,
        "MobileAppPromoDownloadUrl":url
    }    
    $(this).siblings('input').val('');
    $(this).removeClass('active-input');
    mobileAppPromo(currEle,payload);
})

// handbuger need help button start
$('#need-help-btn').css("color", "gray");
$('#need-help-btn').addClass('disable-click-link');
$('.postalSearchInputHelpBtn').keyup(function (this: any, e: any)
{
    var pageURL = window.location.href;
    var lastItem = pageURL.split('/')[3];
    var value = $(this).val() as string;
    var zipRegex = new RegExp(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/) ;
    if (lastItem == 'en-us')
    {
        $('.postalSearchInputHelpBtn').keypress(function (this: any, e: any)
        {
            var regex = new RegExp("^[0-9]$");
            var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (!regex.test(key))
            {
                e.preventDefault();
                return false;
            }
            if (this.value === "0000" && e.which == 48)
            {
                return false;
            }
        });
        if (value !== '' && zipRegex.test(value))
        {
            $('#need-help-btn').css("color", "#007bff");
            $('#need-help-btn').removeClass('disable-click-link');
        } else
        {
            $('#need-help-btn').css("color", "gray");
            $('#need-help-btn').addClass('disable-click-link');
        }
    } else
    {
        var zipRegex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
        if (this.value === "00000" && e.which == 48)
        {
            return false;
        }
        if (value !== '' && zipRegex.test(value))
        {
            $('#need-help-btn').css("color", "#007bff");
            $('#need-help-btn').removeClass('disable-click-link');
        } else
        {
            $('#need-help-btn').css("color", "gray");
            $('#need-help-btn').addClass('disable-click-link');
        }
    }
})

$('#need-help-btn').click(function ()
{
    $('.menu_sec').removeClass('mobile_menu');
    var zipCode = $('.postalSearchInputHelpBtn').val();
    store_data(zipCode, 'zipcode');
    $('#js-serviceTypeDialog').css("display", "block");
    $('.servive-form-wrapperBox').removeClass('active');
    $("#service-search-page").addClass("active");
    $('body').addClass('serviceHeaderFixed headerFixed').removeClass('mob_body');
    // getPropTypes(zipCode);
    var serviceModifier = 4;
    getPropTypes(zipCode,serviceModifier);
});

$('.find_sec .locateBtn').click(function ()
{
    $('.notValidZipCode').css("display", "none");
    $('.menu_sec').removeClass('mobile_menu');
    $('#js-serviceTypeDialog').css("display", "block");
    $('.servive-form-wrapperBox').removeClass('active');
    $("#located-page").addClass("active");
    $('body').addClass('serviceHeaderFixed headerFixed').removeClass('mob_body');
})
// handbuger need help button end

// on change label go up

$("input#vin").on('keyup', function (this: any)
{
    var val = $(this).val() as string;
    if (val.length > 0)
    {
        $(this).next('label').addClass('input-focused')
    } else
    {
        $(this).next('label').removeClass('input-focused')
    }
});

$("input#license-plate").on('keyup', function (this: any)
{
    var val = $(this).val() as string;
    if (val.length > 0)
    {
        $(this).next('label').addClass('input-focused')
    } else
    {
        $(this).next('label').removeClass('input-focused')
    }

});

$('#optionaldetails-os .option-info-form textarea').on('keyup', function (this: any)
{
    
    var valg = $(this).val() as string;
    var valselect = $('#optionaldetails-os .option-info-form select').val() as string;
    var abtAttached  = $('.file-chooser__input').val();
    if(valg.length == 0 && valselect === "0" && abtAttached == ""){
      $('#optionaldetails-os .option-info-form .skip-btn').get(0).firstChild.nodeValue = "Skip ";
    }else{
        $('#optionaldetails-os .option-info-form .skip-btn').get(0).firstChild.nodeValue = "Next ";
     }
});

$('#optionaldetails-os .option-info-form select').on('change', function (this: any)
{
    var valselect = $('#optionaldetails-os .option-info-form select').val() as string;
    var valg = $('#optionaldetails-os .option-info-form textarea').val() as string;
    let conceptIDGlobal = window.objOSGlobal.requestBodyValues.ConceptCalledId;
    if(conceptIDGlobal == "4" || conceptIDGlobal == "8"){
        $('#optionaldetails-os .option-info-form .skip-btn').get(0).firstChild.nodeValue = "Next ";
      }else{
         $("#optionaldetails-os .skip-btn").removeClass('disabled-nxt-btn'); 
         if (valg.length == 0 && valselect === "0"){
            $('#optionaldetails-os .option-info-form .skip-btn').get(0).firstChild.nodeValue = "Skip ";
          }else{
            $('#optionaldetails-os .option-info-form .skip-btn').get(0).firstChild.nodeValue = "Next ";
        }
    }
});

$('.location-search input[type="search"]').keypress(function (this: any, e: any){
     var afterCom = window.location.href.split('.com')[1];
     var isWebSite = afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us"; 
     if(isWebSite=='us'){
        var regex = new RegExp("^[0-9]$");
        var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key)){
            e.preventDefault();
            return false;
        }
        if (this.value === "0000" && e.which == 48){
            return false;
        }
    }  
});

/**TopMost blue row closed **/
$('.close_icn').on('click', function ()
{
    $('.notify_sec').hide();
    $('body').addClass('notifyHide').removeClass('notify_remove');
    localStorage.setItem('isRpmViolatorClosed', 'true');
});

$(function() {
 // Is Violator Closed
 if(localStorage.getItem('isRpmViolatorClosed') === 'true') {
    $('.notify_sec').hide();
}

});

$('.custom-error').text('This number already exists')

var number = document.getElementById('square-feet');
if(number != null){
number.onkeydown = function(e) {
    if(!((e.keyCode > 95 && e.keyCode < 106)
      || (e.keyCode > 47 && e.keyCode < 58) 
      || e.keyCode == 8)) {
        return false;
    }
}
}


// Service List display according the modifier


$(document).on('change','.search-services-form .form-check input[name="chkServiceType"]',function (this:any){
    let serviceModifier = $(this).val();
    $('.service-search .js-servicesListDropdown').empty();
    let serviceModifierType =  getServiceTypeString(serviceModifier);
    window.objOSGlobal.requestBodyValues.serviceModifier = serviceModifierType;
    sessionStorage.setItem('ServiceModifierValue',serviceModifierType);
    var zipCode = get_data('zipcode');
    getPropTypes(zipCode,serviceModifier);
});

function getServiceTypeString(serviceType:any){
    switch(serviceType){
        case "1":
        serviceType = "auto" 
        break;
        case "2":
        serviceType = "Emergency";
        break;
        case "3":
        serviceType = "Commercial";
        break;
        default:
        serviceType = "Residential";
    }
   return serviceType;
}

$('.change-zip').on('click',function(){
   $('.tab-pane.fieldset').removeClass('active');
   $('#located-page').addClass('active');
   $('#service-entry-btn').removeClass('prevent-click');
});

//(+)50381


export function getRefDetails(){
    var leadSourceNames: any[] = [];
    var refrenceVal = window.objOSGlobal.requestBodyValues.ConceptCalledId == "1" ? $('#reference-details :selected').val() : $('#sd-reference-details :selected').val();
    if(refrenceVal!=""){
        return;
    }else{
        $('.reference-details').empty();
        start_loader();
        $.ajax({
            url: `${apiConfig.genericLeadSources}?apikey=${window.DEFINE_VALUE.JS_API_KEY}`+"&"+getNblyApiHeaders(),
            type: "GET",
            success: function (data: any)
            {
                stop_loader();
                $.each(data, function (index: any, element: any)
                {
                    leadSourceNames.push(`<option id="${element.syncGenericLeadSourceId}" value="${element.syncGenericLeadSourceId}">${element.leadSourceName}</option>`)
                });
                $('.reference-details').append(leadSourceNames);
                $('.reference-details').prepend("<option value=''>Where did you hear about us?</option>").val('');
            },
            error:function(e: any) {
                stop_loader();
            }
        });
    }
}