export function setServiceContactInfoOsVariables(){
    var personalInfo: any[] = $('.personal-info-form').serializeArray();
    localStorage.setItem('personalInfo', JSON.stringify(personalInfo));

    const firstnameobj = personalInfo.find(form => form.name === 'firstname');
    const lastnameobj = personalInfo.find(form => form.name === 'lastname');
    const emailobj = personalInfo.find(form => form.name === 'email');
    const mobileNumberobj = personalInfo.find(form => form.name === 'phonenumber');
    const methodofcontactObj = personalInfo.find((form) => form.name === "chkmobile");
    window.objOSGlobal.requestBodyValues.FirstName = firstnameobj.value;
    window.objOSGlobal.requestBodyValues.LastName = lastnameobj.value;
    window.objOSGlobal.requestBodyValues.Email = emailobj.value;
    window.objOSGlobal.requestBodyValues.Phone = mobileNumberobj.value;
    window.objOSGlobal.requestBodyValues.PreferredCommunicationType = methodofcontactObj ? methodofcontactObj.value : "";
}