import {getFranchiseWeblocationID} from "./getFranchiseWeblocationId";
import {getFranchiseWebServices} from "./getFranchiseWebServices";
import {store_data,get_data,remove_data} from "../util";
import {schedulerNextButtonFlow} from "./schedulerNextButtonFlow";
import { LeadConfirmation } from "../confirmation-modal";
export function getFranchiseWeblocation(currentElement:any){
    var postalCode =window.objOSGlobal.requestBodyValues.PostalCode;
    var found = false;
    var address = $('#service-address-screen input[name="address"]').val();
    var apartment = $('#service-address-screen input[name="address2"]').val();
    var city = $('#service-address-screen input[name="city"]').val();
    var state = $('#service-address-screen select[name="state"]').val();
    apartment = apartment ? apartment + " ": "";
    let addressParam = address + " " + apartment +city + " " + state + " " + postalCode;
    getFranchiseWeblocationID(addressParam, function(result:any){
        if (result.length == 0){
            $('.progressbar, .progress').hide();
            $('#js-schedulerDialog .modal-header-two').css('display', 'none');
            $('#js-schedulerDialog .modal-header-one').show();
            $('#service-address-screen').removeClass('active');
            $('.scheduler-modal .modal-content').addClass('banner-wizard');
            $('#no-search-result .head-title').html(`We’re sorry. We don’t currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
            $('#no-search-result').addClass('active');
            sessionStorage.removeItem('changeZip');
            return;
        }
        if(window.objOSGlobal.requestBodyValues.WebLocationId ==result[0].franchiseWebLocationId){
            sessionStorage.removeItem('changeZip');
            store_data(true,'franchiseLocation');
            if(get_data('confirmModal')){
                LeadConfirmation();
                remove_data('confirmModal');
            }else{
                schedulerNextButtonFlow(currentElement);
            }
        }else{
        window.objOSGlobal.requestBodyValues.WebLocationId = result[0].franchiseWebLocationId;
        var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
        var serviceName = window.objOSGlobal.requestBodyValues.serviceName;
        getFranchiseWebServices(webLocationId, function (result: any){
            if (result.length == 0){
                $('#service-address-screen').removeClass('active');
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('.progressbar, .progress').hide();
                $('#js-schedulerDialog .modal-header-two').css('display', 'none');
                $('#js-schedulerDialog .modal-header-one').show();
                $('#no-search-result').addClass('active');
                $('#no-search-result .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
                sessionStorage.removeItem('changeZip');
                return;
            }
            var data = JSON.parse(result.serviceMenu);
            console.log('data',data);
            var options = data["country"][0].brand[0].webLocation[0].option;
            var modifierObj = options && options.find((item:any) => item.name === window.objOSGlobal.requestBodyValues.serviceModifier);
            if (modifierObj && modifierObj.category){
                for (var i = 0; i < modifierObj.category.length; i++){
                    var currentMenu = modifierObj.category[i].menu;
                    var serviceFound = false;
                    for (var j = 0; j < currentMenu.length; j++){
                        var serviceObj = currentMenu[j].service && currentMenu[j].service.find((itm:any) => itm.id == window.objOSGlobal.requestBodyValues.ServiceSkuId);
                        if (serviceObj){
                            serviceFound = true;
                            break;
                        }
                    }
                    if (serviceFound){
                        found = true;
                        break;
                    }
                }
            }
            if (found){
                $('.progressbar, .progress').show();
                $('#service-address-screen').addClass('active');
                $('#service-address-screen h2').append("<h5 class='locations' style='color:red;'>This address is not serviced by this location</h5>");
                $('#service-address-screen h2').append("<h5 class='locations'><a href='#' class='click-schedule'>Click here</a> to be redirected to the appropriate location</h5>");
                $('#service-address-screen .next').hide();
                store_data(postalCode,'zipcode');
                sessionStorage.removeItem('changeZip');
            } else{
                $('#service-address-screen').removeClass('active');
                $('.scheduler-modal .modal-content').addClass('banner-wizard');
                $('#js-schedulerDialog .modal-header-two').css('display', 'none');
                $('#js-schedulerDialog .modal-header-one').show();
                $('.progressbar, .progress').hide();
                $('#js-schedulerDialog .modal-header-two').css('display', 'none');
                $('#js-schedulerDialog .modal-header-one').show();
                $('#no-search-result').addClass('active');
                $('#no-search-result .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
                sessionStorage.removeItem('changeZip');
            }
        });
      }
    });
}