import {store_data} from "../util";
export function aboutDataStoreInLocal(){
    let setAboutDataObj:any = {};
    var squareFeet = $('#inputsquarefeet').val();
    var bedrooms = $('#inputbedrooms option:selected').val();
    var inputbathrooms = $('#inputbathrooms option:selected').val();
    var referenceDetails = $('#about-home-screen #reference-details option:selected').val();
    
    setAboutDataObj.squareFeet = squareFeet;
    setAboutDataObj.bedrooms = bedrooms;
    setAboutDataObj.inputbathrooms = inputbathrooms;
    setAboutDataObj.referenceDetails = referenceDetails;
    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = referenceDetails;
    store_data(setAboutDataObj,'aboutYourHome');
}