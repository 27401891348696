import { isValidUsOrCanadaZip, remove_data, store_data } from "../util";
import { $ } from "../third-party-imports"



export function validateInputZip(inputElem: HTMLElement)
{
    $('.js-currentLocationBox').show();

    var divEle = $(inputElem).parent();

    var notValidSpan = $(divEle).find('#notValidZipCode');
    var localHelpBtnEle = $(divEle).find('.findHelpBtnReuseClass');


    var char = $(inputElem).val() as string;
    var charLength = char.length;

    if (charLength == 0)
    {
        $(localHelpBtnEle).removeClass('active-btn');
        $(localHelpBtnEle).addClass('disble-btn');
        $(localHelpBtnEle).prop('disabled', true);
        $(notValidSpan).hide();
        $('.invalid-zip-meg').css('visibility','hidden');
    }
    else if (charLength > 0 && isValidUsOrCanadaZip(char))
    {
        $(localHelpBtnEle).removeClass('disble-btn');
        $(localHelpBtnEle).prop('disabled', false);
        $(localHelpBtnEle).addClass('active-btn');
        $('.invalid-zip-meg').css('visibility','hidden');
        remove_data('zipcode');
        store_data(char, 'zipcode');
    }

    else if (charLength > 0 && !isValidUsOrCanadaZip(char))
    {
        $(localHelpBtnEle).addClass('disble-btn');
        $(localHelpBtnEle).prop('disabled', true);
        $('.invalid-zip-meg').css('visibility','visible');
        $(localHelpBtnEle).removeClass('active-btn');
    }
}
