import {get_data} from '../util';
import { setAboutHomeData } from './setAboutHomeData';
import { refreshCalendarData,schedulerState } from "../calendar";
export function schedulerWizardBackButton(pane:any){
    var code = window.objOSGlobal.requestBodyValues.ConceptCalledId || window.objOSGlobal.requestBodyValues.ConceptCode;
    var isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
    $('.progressbar, .progress').show();        
    let hideModalHeader2: Boolean = false;
    let modifier = sessionStorage.getItem('ServiceModifierValue');
    switch (pane) {
        case "service-slide-4": 
            $('#schedulerWizard #service-category-page').addClass('active');
            $('.progressbar, .progress').hide();
            hideModalHeader2 = true;
            break;
        case "warranty-screen":
            $('.progressbar, .progress').hide();    
            $('#schedulerWizard #service-slide-4').addClass('active');            
            hideModalHeader2 = true;
                     
            break;
        case "frequency-selection-screen":
            $('.progressbar, .progress').hide();
            if (code == "MRA" || code == '8') {
                $('#schedulerWizard #warranty-screen').addClass('active');
                hideModalHeader2 = false;
            }
            else{
                $('#schedulerWizard #service-slide-4').addClass('active');
                hideModalHeader2 = true;
            }            
            break;
        case "calendar-screen":
            $('.progressbar, .progress').hide();
            if (code == "MRA" || code == '8') {
                $('#schedulerWizard #warranty-screen').addClass('active');
                hideModalHeader2 = false;
            }
            else if (code == "MLY" || code == '1') {
                $('#schedulerWizard #frequency-selection-screen').addClass('active');
                hideModalHeader2 = false;
            }
            else {
                $('#schedulerWizard #service-slide-4').addClass('active');
                hideModalHeader2 = true;
            }
            
            break;
        case "about-home-screen":
            if(modifier =='Commercial'){
                $('#schedulerWizard #service-slide-4').addClass('active');
                $('.progressbar, .progress').hide();
                hideModalHeader2 = true;
            }else{
                if(isLeadOnly){
                    $('.tab-pane.fieldset').removeClass('active');
                    $('.progress,.progressbar').hide();
                    $('#frequency-selection-screen').addClass('active'); 
                    hideModalHeader2 = false;
                }else{
                    schedulerState.numberOfSlot = 1;
                    refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
                    $('#schedulerWizard #calendar-screen').addClass('active');
                }
            }
            hideModalHeader2 = false;
            break;
        case "estimate-screen":
            $('#schedulerWizard #about-home-screen').addClass('active');
            hideModalHeader2 = false;
            break;
        case "contact-information-screen":
            if (code == "MLY" || code == '1') {
                if(modifier =='Commercial'){
                    $('#schedulerWizard #about-home-screen').addClass('active');
                }else{
                    if(get_data('selectedAboutHome') == 'onetime'){
                        $('#schedulerWizard #estimate-screen').addClass('active');  
                        $('.services-list-screen').hide();
                        $('.estimation-list-screen').show()

                      //  $('#schedulerWizard #estimate-screen .estimation-list-screen').addClass('active');
                      //  $('#schedulerWizard #estimate-screen .services-list-screen').hide();
                    }
                    else if(get_data('selectedAboutHome') == 'recurring'){
                        $('.services-list-screen').show();
                        $('.estimation-list-screen').hide()
                        $('#schedulerWizard #estimate-screen').addClass('active');  
                      //  $('#schedulerWizard #estimate-screen .services-list-screen').addClass('active');
                      //  $('#schedulerWizard #estimate-screen .estimation-list-screen').hide();
                    }
                }
            }
            else if(code == '8' || code == 'MRA') {
                if(isLeadOnly || window.objOSGlobal.requestBodyValues.Warranty == true){
                    $('#schedulerWizard #warranty-screen').addClass('active');
                    $('.progress,.progressbar').hide();
                }else{
                    $('#schedulerWizard #calendar-screen').addClass('active');
                }
            }
            else {
               if(isLeadOnly){
                    $('.tab-pane.fieldset').removeClass('active');
                    $('#service-slide-4').addClass('active'); 
                    $('.progressbar, .progress').hide();
                    hideModalHeader2 = true;
               }else{
                    schedulerState.numberOfSlot = 1;
                    refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
                    $('#schedulerWizard #calendar-screen').addClass('active');
                    hideModalHeader2 = false; 
               }                
            }
            setAboutHomeData();       
            break;
        case "service-address-screen":
            $('#schedulerWizard #contact-information-screen').addClass('active');
            hideModalHeader2 = false;
            break;
        case "about-service-needs-screen":
            $('#schedulerWizard #service-address-screen').addClass('active');
            hideModalHeader2 = false;
            break;
        case "vehicle-information-screen":
            if (!$('#vehicle-information-1').hasClass('hidden')) {
                $('#vehicle-information-1').removeClass('hidden');
                $('#vehicle-information-2, #vehicle-information-3').addClass('hidden');                
                $('#schedulerWizard #about-service-needs-screen').addClass('active');
            }
            else if(!$('#vehicle-information-2').hasClass('hidden')) {
                $('#vehicle-information-1').removeClass('hidden');
                $('#vehicle-information-2, #vehicle-information-3').addClass('hidden');
                $('#schedulerWizard #vehicle-information-screen').addClass('active');                
            }
            else if(!$('#vehicle-information-3').hasClass('hidden')) {
                $('#vehicle-information-3, #vehicle-information-1').addClass('hidden');
                $('#vehicle-information-2').removeClass('hidden');
                $('#schedulerWizard #vehicle-information-screen').addClass('active');              
            }

            hideModalHeader2 = false;
            break;
        case "service-details-screen":
            if (code == '14' || code == 'PLG'){
                if($('#about-service-needs-screen #Auto').is(':checked')) {
                    $('#schedulerWizard #vehicle-information-screen').addClass('active');
                }
                else {
                    $('#schedulerWizard #about-service-needs-screen').addClass('active');
                }
            }
            else{
                $('#schedulerWizard #service-address-screen').addClass('active');
            }
            hideModalHeader2 = false;
            break;
        default:            
            $('.progressbar, .progress').hide();
            hideModalHeader2 = true;            
            console.log('This is default case');
            break;
    }
    if(hideModalHeader2){
        $('#js-schedulerDialog .modal-header-two').css('display', 'none');
        $('#js-schedulerDialog .modal-content').addClass('banner-wizard');
        $('#js-schedulerDialog .modal-header-one').show();
    }
}