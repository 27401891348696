import {getPropTypes,getState, get_data, isValidUsOrCanadaZip, remove_data, store_data, start_loader, stop_loader} from "../util";
import {$} from "../third-party-imports"
import { uploader } from "../form";
import {validateCurrentLocZip, validateInputZip } from ".";
import { onlineOrderFlowInit } from './onlineOrderFlowInit';
import { servicesOfferedInit } from './servicesOfferedInit';
import { setProgressBar } from "../scheduler/scheduler";
import { aboutHereAboutYourHome } from './aboutHereAboutYourHome';
import { addressStateValidate } from './addressStateValidate';
import { clearAddressBumperValues } from './clearAddressBumperValues';
import { schedulerNextButtonFlow }from './schedulerNextButtonFlow';
import { serviceCategoryByName } from '../util/serviceCategoryByName';
import {defaultFunctionCall } from './defaultFunctionCall';
import {currentLoadationBtn} from './currentLocation';
import {getApplianceProblemList} from './getApplianceProblemList';
import {schedulerWizardBackButton} from './schedulerBackButton';
import {serviceEntryButton} from './serviceEntryButton';
import {serviceSearchTerm} from './serviceSearchTerm';

$(function (){
    var pageURL = window.location.href;
    var lastItem = pageURL.split('/')[3].replace(/[^a-zA-Z ]/g, "");
    var zipCodeFromLocal = localStorage.getItem('zipcode');
    if(zipCodeFromLocal) {
        zipCodeFromLocal = zipCodeFromLocal.replace(/\"/g, "");
    }
    if (zipCodeFromLocal === "null" || zipCodeFromLocal === ""){
        $('.location-services input[type="search"]').val('');
    }else{
         if (lastItem != "ca"){
            var zipRegexUS = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
            if (zipRegexUS.test(zipCodeFromLocal)){
                $('.location-services input[type="search"]').val(zipCodeFromLocal);
                $('#primaryHeroInput').val(zipCodeFromLocal);
            }else{
                localStorage.removeItem('zipcode');
            }
        }
        if (lastItem == "ca"){
            var zipRegexCa = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
            if (zipRegexCa.test(zipCodeFromLocal)){
                $('.location-services input[type="search"]').val(zipCodeFromLocal);
                $('#primaryHeroInput').val(zipCodeFromLocal);
            } else{
                localStorage.removeItem('zipcode');
            }
        }
    }

    if ($('.location-services input[type="search"]').val() !== "") { 
        $('.service-entry-btn').prop('disabled', false).addClass('disble-btn'); 
    }
    uploader({
        MessageAreaText: "No files selected. Please select a file."
    });
    $('.error').each(function (index, elm)
    {
        if ($(elm).hasClass('hidden'))
        {
            $('#personal-next').addClass('disble-btn').prop('disabled', true);
        } else
        {
            $('#personal-next').prop('disabled', false).removeClass('disble-btn');
        }

    })
    var InitWebLocationId = parseInt(localStorage.getItem("WebLocationId")) != 0 && localStorage.getItem("WebLocationId") != null ? parseInt(localStorage.getItem("WebLocationId")) : 0;
    var initConceptID = localStorage.getItem("conceptID") != "" && localStorage.getItem("conceptID") != null ? localStorage.getItem("conceptID") : "0";
    var initDoingBusinessAs = localStorage.getItem("doingBusinessAs") != "" && localStorage.getItem("doingBusinessAs") != null ? localStorage.getItem("doingBusinessAs") : "";
    var initfranchiseLogoPath = localStorage.getItem("franchiseLogoPath") != "" && localStorage.getItem("franchiseLogoPath") != null ? localStorage.getItem("franchiseLogoPath") : "";
    var initbrandPhoneNumber = localStorage.getItem("brandPhoneNumber") != "" && localStorage.getItem("brandPhoneNumber") != null ? localStorage.getItem("brandPhoneNumber") : "";
    var CountryCode = "US";
    if (lastItem == "ca")
    {
        CountryCode = "CA";
    }
    window.objOSGlobal = {
        "paramsUI": { "doingBusinessAs": initDoingBusinessAs, "franchiseLogoPath": initfranchiseLogoPath, "brandPhoneNumber": initbrandPhoneNumber, "serviceneeds": "", "wheredidyouhear": "", "onetime": "", "moveinout": "", },
        "requestBodyValues": { "WebLocationId": InitWebLocationId, "C4ChannelTypeId": 0, "Comments": "", "ConceptCalledId": initConceptID, "ConceptName": "", "ConceptCode": "", "C4LeadsEnabled": true, "VendorId": 0, "IsTest": true, "C4CallCenterId": 0, "Note": "", "FirstName": "", "LastName": "", "Email": "", "Phone": "", "PhoneType": "", "PostalCode": "", "City": "", "State": "", "Country": CountryCode, "Address": "", "Address2": "", "leadOrigin": "Web", "leadSource": "Web", "CampaignName": "N/A", "CallType": "In Bound", "EmailOptOut": false, "Warranty": false, "MakeId": 0,"licenseNumber":"", "LeadSourceId": 0, "NeighborlyLeadSourceId": 0, "NeighborlyAccountId": 0 ,"ServiceSkuId": 0, "IsLeadOnly": true, "ScheduleId": "" }
    }

    if ("signinredirect-os" in localStorage){
    $('.resultStackLogoImg').empty();
    var paramsUIObj = window.objOSGlobal.paramsUI;
        if(paramsUIObj.franchiseLogoPath != 0) {
            $('.resultStackLogoImg').parent().removeClass('hidden');
        }
        $('.resultStackLogoImg').prepend('<img src=' + paramsUIObj.franchiseLogoPath + ' alt=' + paramsUIObj.doingBusinessAs.replace('.', " ") + '>')
        if(paramsUIObj.brandPhoneNumber != "") {
        $('.callUsTextCont').removeClass('hidden');
        $('.callUsTextCont .phone_call_icon').attr("href", "tel:" + paramsUIObj.brandPhoneNumber).text(paramsUIObj.brandPhoneNumber);
        $('.phone-call-cont a').attr("href", "tel:" + paramsUIObj.brandPhoneNumber).text(paramsUIObj.brandPhoneNumber);

        }
        localStorage.removeItem("signinredirect-os");
    }

    $('.service-need-btn').on('click', function (){
        var serviceneeds = $('#service-needs').serializeArray();
        localStorage.setItem('serviceneeds', JSON.stringify(serviceneeds));
        const serviceneedsObj = serviceneeds.find(form => form.name === 'serviceneeds');
        const wheredidyouhearObj = serviceneeds.find(form => form.name === 'wheredidyouhear');
        const onetimeObj = serviceneeds.find(form => form.name === 'onetime');
        const moveinoutObj = serviceneeds.find(form => form.name === 'moveinout-yes');

        window.objOSGlobal.paramsUI.serviceneeds = serviceneedsObj.value;
        window.objOSGlobal.paramsUI.wheredidyouhear = wheredidyouhearObj.value;
        window.objOSGlobal.paramsUI.onetime = onetimeObj.value;
        window.objOSGlobal.paramsUI.moveinout = moveinoutObj.value;
    });

    $(document).on('click', '.services-link-list li a', function() {
        var userSearch = $(this).html();
        window.objOSGlobal.requestBodyValues.serviceName = userSearch;
        $('#warranty-screen #service-appliance').val($('<textarea/>').html(userSearch).text());
    });

    $('.ui-state-default').on('click', function ()
    {
        $('.next-btn.opacity-5').text("Next")
    });
    $('.close').on('click', function (){
        $('#js-onlineScheduleDialog').removeClass('active');
    })

    $('.cuurent-date').on('click', function (){
        var selectedDate = $(".ui-state-active").text();

        var CalendarObj = {
            selectedDate: selectedDate
        }

        localStorage.setItem('currentDate', JSON.stringify(CalendarObj));
    });

    $('.active-slot').on('click', function (){
        var selectedSlot = $(this).text();
        var CalendarSlot = {
            selectedSlot: selectedSlot
        }
        localStorage.setItem('currentSlot', JSON.stringify(CalendarSlot));
    });

    $('#service-address-hr .active-btn').on('click', function (){
        var selectedTime = $(this).text();
        var CalendarTime = {
            selectedTime: selectedTime
        }
        localStorage.setItem('currentTime', JSON.stringify(CalendarTime));
    });

    let scroll_link = $('.js-scroll-section');

    //smooth scrolling -----------------------
    scroll_link.click(function (e){
        e.preventDefault();
        let url = $('body').find($(this).attr('href')).offset().top;
        $('html, body').animate({
            scrollTop: url
        }, 700);
        $(this).parent().addClass('active');
        $(this).parent().siblings().removeClass('active');
        return false;
    });

    $('#js-serviceModal').on('click', function (event: any){
        event.preventDefault();
        $('#js-serviceTypeDialog').show();
        $('.js-home_sec').addClass('home_secPosition');
        $('.mainPageSection').addClass('serviceDialogOpen');
        $('body').addClass('serviceHeaderFixed');
        window.isStopAnimation = true;
        $('.menu_sec').removeClass('mobile_menu');
        $('body').removeClass('mob_body');
        $('.personalInfoEntry.servive-form-wrapper').removeClass('hidden');
    })
    $('.js-closeServiceModal').on('click', function (){
        localStorage.setItem('schedulenow', "");
        localStorage.setItem('personalnext', "");
        localStorage.setItem('optionsmodal', "");
        localStorage.setItem('servicenext', "");
        $("#service-entry-btn").removeClass('prevent-click');
        $('#js-serviceTypeDialog').hide();
        window.isStopAnimation = false;
        $('.js-home_sec').removeClass('home_secPosition');
        $('body').removeClass('serviceHeaderFixed');
        $('.mainPageSection').removeClass('serviceDialogOpen');
        $('.js-neighborlyStampPopover').popover('hide');
        setTimeout(function (){
            $("body").removeClass('stampPopover-open');
        }, 200);
    })

    $('#js-profile').on('click', function (){
        $('#js-popupModalDialog').show();

        $('.js-home_sec').addClass('home_secPosition');
        $('.mainPageSection').addClass('popUpDialogOpen');
        $('body').addClass('serviceHeaderFixed');
        window.isStopAnimation = true;
        $('.menu_sec').removeClass('mobile_menu');
        $('body').removeClass('mob_body');
        localStorage.setItem("ProfileScreen", "true");
    })

    $('.js-closePopUpModal').on('click', function (){
        $('#js-popupModalDialog').hide();
        window.isStopAnimation = false;
        $('.js-home_sec').removeClass('home_secPosition');
        $('body').removeClass('popUpHeaderFixed');
        $('.mainPageSection').removeClass('popUpDialogOpen');
        $('.js-neighborlyStampPopover').popover('hide');
        setTimeout(function ()
        {
            $("body").removeClass('stampPopover-open');
        }, 200);

        $('.form_container_wrapperBox').removeAttr('style').removeClass('active');
    })

    /**Mobile menu show and hide start */
    $('.mobile-trigger').on('click', function (){
        $('.menu_sec').toggleClass('mobile_menu');
        $('body').addClass('mob_body');
    });

    $('.close_icon').on('click', function (){
        $('.menu_sec').removeClass('mobile_menu');
        $('body').removeClass('mob_body');
    });

    if ($('.js-neighborlyStampPopover').length)
    {
        $('.js-neighborlyStampPopover').popover({
            html: true,
            title: function ()
            {
                return $('.popover_title').html();
            },
            content: function ()
            {
                return $('#popover_content_wrapper').html();
            }
        });

        $('.js-neighborlyStampPopover').on('click', function ()
        {
            $("body").addClass('stampPopover-open');
        });
    }

    $(document).on('click', '.js-containerCloseIcon', function ()
    {
        $('.js-neighborlyStampPopover').popover('hide');
        setTimeout(function ()
        {
            $("body").removeClass('stampPopover-open');
        }, 200);
    });

    //service Offer show hide
   
    if ($('.js-serviceListControl').length)
    {
        $(document).on('keyup', '.js-serviceListControl', function (e)
        {
            let listLength = ($(this).val() as string).length;
            if (listLength >= 1)
            {
                $('.js-servicesListDropdown').show();
            }
            else
            {
                $('.js-servicesListDropdown').hide();
            }
        });
    }

    if ($('.js-zipCodeControl').length)
    {
        $(document).on('keyup', '.js-zipCodeControl', function (e)
        {
            let zipLength = ($(this).val() as string).length;
            if (zipLength >= 1)
            {
                $('.js-currentLocationBox').show();
            }
            else
            {
                $('.js-currentLocationBox').hide();
            }
        });
    }

    $('#located-page').removeClass('active');

    $('#primaryHeroInput ,#inputLocalHelp').on('keydown keyup change', function ()
    {
        validateInputZip(this);
    });

    $('.heroPrimaryLocHelp').on('click', async function (){
        await validateCurrentLocZip(this);
    });

    $('.currentLocNeiHelp').on('click', async function (){
        await validateCurrentLocZip(this);
    });

    $('#primaryHerolocalHelpBtn').on('click', function (){
        start_loader();
        let zipcode = $('#primaryHeroInput').val() as string;

        store_data(zipcode, 'zipcode');
        $('.progress, .progressbar').hide();
        $('#js-serviceTypeDialog').css("display", "block");
        $('body').addClass('serviceHeaderFixed');
        $('.zipcodeServiceSearch').text(zipcode);
        var serviceModifier = 4;
        getPropTypes(zipcode,serviceModifier);
        stop_loader();
    });

    $(".zipcode").text(get_data('zipcode'));

    $('#inputLocalHelpp').on('keydown keyup change', function (){
        $('.js-currentLocationBox').show();

        var char = $(this).val() as string;
        var charLength = char.length;

        if (charLength == 0){
            $('#localHelpBtn').removeClass('active-btn');
            $('#localHelpBtn').addClass('disble-btn');
            $('#localHelpBtn').prop('disabled', true);
            $('#notValidZipCodeServiceEntry').hide();
        }
        else if (charLength > 0 && isValidUsOrCanadaZip(char)){
            $('#localHelpBtn').removeClass('disble-btn');
            $('#localHelpBtn').prop('disabled', false);
            $('#localHelpBtn').addClass('active-btn');
            remove_data('zipcode');
            store_data(char, 'zipcode');
        }
        else if (charLength > 0 && !isValidUsOrCanadaZip(char)){
            $('#localHelpBtn').addClass('disble-btn');
            $('#localHelpBtn').prop('disabled', true);

            $('#localHelpBtn').removeClass('active-btn');
        }
    });

    let localHelpBtn = document.getElementById('localHelpBtn');

    if (localHelpBtn){
        localHelpBtn.addEventListener('click', function (){
            let inputZipCode = document.getElementById("inputLocalHelp") as HTMLInputElement;
            store_data(inputZipCode.value, 'zipcode');
            $('#js-serviceTypeDialog').css("display", "block");
            $('.servive-form-wrapperBox').removeClass('active');
            $("#service-search-page").addClass("active");
            $('body').addClass('serviceHeaderFixed');
            let serviceModifier = 4;
            getPropTypes(inputZipCode.value,serviceModifier);
            $('.zipcodeServiceSearch').text(inputZipCode.value);
        });
    }

    $( "#service-list-query", "serviceListQueryFinal" ).on( "click", function() {  
        $(".servicesListDropdown").show();
    });

    //(+)50381
    $('input[name="chkFrequesy"]').on('change', function(){        
        if($(this).attr('id').indexOf('recurring') > 0){
            $('#js-recurring-data').show();
            $('#js-onetime-data').hide();
            store_data('recurring', 'selectedAboutHome');
            sessionStorage.removeItem('MlyCleaning');
        }
        else{
            $('#js-recurring-data').hide();
            $('#js-onetime-data').show();
            sessionStorage.removeItem('MlyFrequency');
            store_data('onetime', 'selectedAboutHome');
        }
    });
    //(-)50381

    store_data($('#about-service-needs-screen input[name="filter"]:checked').siblings('.radio-txt').text(), 'selectedPLGFilter');

    $('#about-service-needs-screen input[name="filter"]').on('change', function(){
        if($(this).is(':checked')){
            store_data($(this).siblings('.radio-txt').text(), 'selectedPLGFilter');
        }
    });

    $('#vehicle-information-3 input[name="servicetype"]').on('change', function(){
        if($('#vehicle-information-3 #mobileservice').is(':checked')){
            $('#vehicle-information-3 #mobile-service-div').removeClass('hidden');
        }
        else{
            $('#vehicle-information-3 #mobile-service-div').addClass('hidden');
        }
    });
    
    $('#vehicle-information-2 .option-info-form').on('change', function (){        
        if($('input[type="checkbox"]').is(':checked')){
            $(this).parent().find('#vehicle-information-2 .next span').text("Next");
        }
        else{
            $(this).parent().find('#vehicle-information-2 .next span').text("Skip");
        }
    });

    var vicheckBoxes = $("#vehicle-information-2 .option-info-form input[type='checkbox']"),
    nextButton = $('#vehicle-information-2 .next span');
    vicheckBoxes.click(function() {
        nextButton.text(!vicheckBoxes.is(":checked") ? "Skip" : "Next");
    });
    
    $('#vehicle-information-3 .option-info-form').on('change click keypress', function ()
    {        
        $('#vehicle-information-3 .next span').text("Submit");
    });
        
    let lang = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";

    $("#vehicle-information-3 input[name='mobileservicezipcode']").on('keypress', function(e){
        let zipValue:any = $(this).val();
        let zipRegex = new RegExp("^[0-9]$");
        let key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if(lang =='ca'){
            zipRegex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
        }else{
            if(zipValue.length>=5){
                return false;
            }
        }
        if (!zipRegex.test(key)){
                e.preventDefault();
                return false;
        }
        if (zipValue === "0000" && e.which == 48){
            return false;
        }
    });

    $("#vehicle-information-3 #policy-number").on('keypress', function(e){
        $('#vehicle-information-3 .next span').text("Submit");
        let regex = new RegExp("[a-zA-Z0-9]");
        let key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key)){
            e.preventDefault();
            return false;
        }
    });
    
    $("#vehicle-information-3 #deductible-amount").keypress(function(e){
        $('#vehicle-information-3 .next span').text("Submit");
        let regex = new RegExp(/^[0-9]*\.?[0-9]*$/);
        let key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (!regex.test(key)){
            e.preventDefault();
            return false;
        }
    });
    $("#vehicle-information-3 #insurance-company").keypress(function(e){
        $('#vehicle-information-3 .next span').text("Submit");
    });

    $("#vehicle-information-3 .option-info-form").validate({
        rules: {
            howdidyouhear: {
                required: true,
            },
        }
    });

    $('#vehicle-information-3 #vehicle-reference-details').on('change', function (){
        var isValid = $("#vehicle-information-3 .option-info-form").valid();
        if(isValid){
            $("#vehicle-information-3 .next").removeClass('disble-btn').prop("disabled", false);
        }else{
            $("#vehicle-information-3 .next").addClass('disble-btn').prop("disabled", true);
        }
        window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#vehicle-reference-details option:selected').val();
    });

});


$("#signInForm2 .validate ").on("keyup", function (){
    $('#signInForm2 .toggle-disabled').removeClass('disble-btn')
});

$('#js-serviceModal , .reqAsstBtn_for_mobile , .reqAsstBtn, .drgScheduleNowBtn, .marketmapbtn, .schedule_btn, .marketservicebtn, .marketservicesearchli, .footerbtn').on('click', function (event: any){
    event.preventDefault();
    $('#located-page').addClass('active');
    $('.progressbar, .progress').hide();
    $('#service-entry-btn').addClass('disble-btn');
    $('#service-entry-btn').prop('disabled', true);
    $('#notValidZipCodeServiceEntry').hide();
    $('.backButton').hide();
    var zipcode = $('#serviceEntryText').val() as string
    if (zipcode){
        if (isValidUsOrCanadaZip(zipcode)){
            $('#service-entry-btn').removeClass('disble-btn');
            $('#service-entry-btn').prop('disabled', false);
            $('#service-entry-btn').addClass('active-btn');
        }
    }
});

$('.reqAsstBtn_for_mobile , .reqAsstBtn, .drgScheduleNowBtn, .marketmapbtn, .schedule_btn, .marketservicebtn, .marketservicesearchli, .footerbtn').on('click', function (event: any){   
    event.preventDefault();
    $('#js-serviceTypeDialog').show();
    $('#located-page').addClass('active');

    $('body').addClass('serviceHeaderFixed');

    window.scroll(0, 0);
});

$('.market-service-link').on('click', '.marketservicesearchli', function (event: any)
{
    event.preventDefault();
    $('#js-serviceTypeDialog').show();
    $('#located-page').addClass('active');
    window.scroll(0, 0);

    $('body').addClass('serviceHeaderFixed');

});

$('.serviceModalbody').on('keydown keyup change mouseover touchstart', function (){
    $('#located-page').css('left', '0px');
    var currentSlide = $('.servive-form-wrapper div.active').attr('id');

    var currentSlide_os = '';

    $(".personalInfoEntry").each(function ()
    {
        if (!$(this).hasClass("hidden")){
            currentSlide_os = $(this).attr('id')
        }
    });

    if (currentSlide !== 'located-page'){
        $('.backButton').show();
    }
    else{
        $('.backButton').hide();
    }

    if (currentSlide_os !== 'personalInfo-os'){
        $('#js-onlineScheduleDialog .backButton').show();
    }
    else{
        $('#js-onlineScheduleDialog .backButton').hide();
    }
});

$('#serviceEntryText').on('keydown keyup change', function (){
    $('.js-currentLocationBox').show();
    var char = $(this).val() as string;
    var charLength = char.length;

    if (charLength == 0){
        $('#service-entry-btn').removeClass('active-btn');
        $('#service-entry-btn').addClass('disble-btn');
        $('#service-entry-btn').prop('disabled', true);
        $('#notValidZipCodeServiceEntry').hide();
        $('.invalid-zip-meg').css('visibility','hidden');
    }

    else if (charLength > 0 && isValidUsOrCanadaZip(char)){
        $('#service-entry-btn').removeClass('disble-btn');
        $('#service-entry-btn').prop('disabled', false);
        $('#service-entry-btn').addClass('active-btn');
        $('.invalid-zip-meg').css('visibility','hidden');
    }

    else if (charLength > 0 && !isValidUsOrCanadaZip(char)){
        $('#service-entry-btn').addClass('disble-btn');
        $('#service-entry-btn').prop('disabled', true);
        $('.invalid-zip-meg').css('visibility','visible');
        $('#service-entry-btn').removeClass('active-btn');
    }
});

$('#CurrentLocationBtn').on('click', async function (){
    if (navigator.geolocation){
        await currentLoadationBtn();
    }
});

if ($('.js-serviceListControl').length){
    $(document).on('keyup', '.js-serviceListControl', function (this: any, e: any){ 
        let listLength = ($(this).val() as string).length;
        if (listLength >= 1){
            var searchTerm = $(this).val();
            $('.js-servicesListDropdown').empty();
            var querParams:any = '';
            if($('#warranty-screen').hasClass('active')){
                querParams =  '/franchise-web-locations/'+window.objOSGlobal.requestBodyValues.WebLocationId
            }
            $('input[name="serviceListQuery"]').autocomplete({
                minLength: 3,
                source: function (request: any, response: any){
                    start_loader();
                    serviceSearchTerm(searchTerm,querParams,response,request)
                },
            });
            $('.js-servicesListDropdown').show();
        }
        else{
            $('.js-servicesListDropdown').hide();
        }
    });
}

//----------------------------------------------  service Prototype Logic ends here--------------------------------


//----------------------------------------------  service Entry Logic starts here--------------------------------
$('#findServiceList').submit(function (e) {
    e.preventDefault(); 
});
$('#service-entry-btn').on('click', function (){
    getState();
    start_loader();
    clearAddressBumperValues();
    sessionStorage.removeItem('ServiceModifierValue');
    $("#inputResidential").prop("checked", true);
    $("input[name='serviceListQuery']").val("");
	$('#service-search-page #service-search-row').remove();
    var searchTerm:any = $('.location-search input[type="search"]').val();
    $('.zipcodeServiceSearch').text(searchTerm);
    remove_data('zipcode');
    remove_data('addressBumper');
    remove_data('aboutYourHome');
    remove_data('confirmModal');
    remove_data('aboutYourHome');
    store_data(searchTerm, 'zipcode');
    $(this).addClass('prevent-click');
    serviceEntryButton(searchTerm);
})


//----------------------------------------------  service Entry Logic ends here--------------------------------

$('.js-closeServiceModal, .back-to-service-btn').on('click', function (){
    $('.service-search .js-servicesListDropdown').empty();
    $("#service-entry-btn").removeClass('prevent-click');
    $('.progress, .progressbar').hide();
    $('.modal-header-two').hide();
    $('.modal-header-one').show();    
    $('#schedulerWizard .tab-content').children().not(':first').removeClass('active');
    $('#located-page').removeClass('active');
    $("input[name='serviceListQuery']").val("");
    remove_data('addressBumper');
    remove_data('confirmModal');
    clearAddressBumperValues();
    $('.schedule_btn .btn').removeClass('active-btn');
    localStorage.removeItem('address');
    localStorage.removeItem('personalInfo');
    localStorage.removeItem('addressmodal');
    $('#js-serviceTypeDialog').css("display", "none");
    $('.footerbtn').removeClass('active-btn');

});


$(function (this: any){
    defaultFunctionCall();
    function alignModal(this: any){
        var modalDialog = $(this).find(".serviceModalbody");
        modalDialog.css("margin-top", Math.max(0, ($(window).height() - modalDialog.height()) / 2));
    }
    $("#js-serviceTypeDialog").on("shown.bs.modal", alignModal);
    $('#js-onetime-data input[type=radio][name=chkcleaning]').change(function() {
        var mlyCleaning = $(this).siblings('.radio-txt').text();
        sessionStorage.setItem('MlyCleaning',mlyCleaning);     
    });
    $('#js-recurring-data input[type=radio][name=chkfrequencymethod]').change(function() {
        var mlyFrequency = $(this).siblings('.radio-txt').text();
        sessionStorage.setItem('MlyFrequency',mlyFrequency);     
    });
});


// $(servicesOfferedInit("nei"));
$(servicesOfferedInit("brand"));
$(servicesOfferedInit("protype"));
$(onlineOrderFlowInit);

$('.serviceModalbody').on('keydown keyup change mouseover touchstart', function (){
    var backButton = $("#js-onlineScheduleDialog.serviceModalDialog.active  .closeDialogCont .backButton");
    $("#js-onlineScheduleDialog.serviceModalDialog.active .personalInfoEntry").each(function (this: any)
    {
        if (!$(this).hasClass("hidden"))
        {
            //do stuff
            var activeSlide = $(this).attr('id');
            if (activeSlide == "personalInfo-os")
            {     //pls change the show or hide according to business requirement
                backButton.hide();
            } else if (activeSlide == "addressmodal-os")
            {
                backButton.show();
            } else if (activeSlide == "optionaldetails-os")
            {
                backButton.show();
            } else if (activeSlide == "schedulecalendar-os")
            {
                backButton.show();

            } else if (activeSlide == "confirmation-os")
            {
                backButton.hide();
            }
        }
    });
});

//(+)50381

$(document).on('click', '#schedulerWizard .js-next-btn', function(e) {
    var currentElement = this;
    schedulerNextButtonFlow(currentElement);
});
    
$('#schedulerWizard .tab-content .tab-pane .back').on('click', function(){
    var pane = $(this).closest('.tab-pane').attr('id');
    $('#' + pane).removeClass('active');
    schedulerWizardBackButton(pane);
    var activepane = $(this).closest('.tab-content').find('.tab-pane.active').attr('id'); 
    setProgressBar(activepane);
    $('.progressbar').children().removeClass('active');
    $('.progressbar').children().addClass('disabled');
    $('.progressbar').children().find('a').removeClass('active');
    $('.progressbar  a[data-step="' + activepane + '"]').addClass('active');
    $('.progressbar  a[data-step="' + activepane + '"]').parent().addClass('active');
});

$('.about-home-screen #inputbedrooms').off('change').on('change', function (){
    let inputbedrooms = $(this).children("option:selected").val()
    if(inputbedrooms !=""){
        $(this).parent().children('.input-bedroom').remove();
    }else{
        $(this).parent().append('<div class="error help-block invalid-feedback input-bedroom" for="refrence-service">This field is required.</div>');
    }
    if(aboutHereAboutYourHome()){
        $(".about-home-screen .next").removeClass("disble-btn").prop("disabled", false);
    } else{
        $(".about-home-screen .next").addClass("disble-btn").prop("disabled", true);
    }
});

$('.about-home-screen #inputbathrooms').off('change').on('change', function (){
    let inputbathrooms = $(this).children("option:selected").val()
    if(inputbathrooms !=""){
        $(this).parent().children('.input-bathroom').remove();
    }else{
        $(this).parent().append('<div class="error help-block invalid-feedback input-bathroom" for="refrence-service">This field is required.</div>');
    }
    if(aboutHereAboutYourHome()){
        $(".about-home-screen .next").removeClass("disble-btn").prop("disabled", false);
    } else{
        $(".about-home-screen .next").addClass("disble-btn").prop("disabled", true);
    }
});

$('.about-home-screen select[name="referencedetails"]').off('change').on('change', function (){
    let refrenceDetails = $(this).children("option:selected").val();
    if(refrenceDetails !=""){
        $(this).parent().children('.here-about-us').remove();
    }else{
        $(this).parent().append('<div class="error help-block invalid-feedback here-about-us" for="refrence-service">This field is required.</div>');
    }
    if(aboutHereAboutYourHome()){
        $(".about-home-screen .next").removeClass("disble-btn").prop("disabled", false);
    }else{
        $(".about-home-screen .next").addClass("disble-btn").prop("disabled", true);
    }
});

$('#service-address-screen select[name="state"]').off('change').on('change',function(){
   let state = $(this).children("option:selected").val();
   if(state !=""){
       $(this).parent().children('.addressState').remove();
   }else{
       $(this).parent().append('<div class="error help-block invalid-feedback addressState" for="input-service-state">This field is required.</div>');
   }
   if(addressStateValidate()){
        $('#service-address-screen .next').removeClass('disble-btn').prop('disabled', false);
   }else{
        $('#service-address-screen .next').addClass('disble-btn').prop('disabled', true);
   }
})

$('#service-details-screen select[name="referencedetails"]').off('change').on('change',function(){
    var selectedItem = $(this).children("option:selected").val()
    if(selectedItem !=''){
        $('#service-details-screen .next').removeClass('disble-btn').prop('disabled', false);
    }else{
        $('#service-details-screen .next').addClass('disble-btn').prop('disabled', true);
    }
});

$('#warranty-screen select[name="brandappliance"]').off('change').on('change',function(){
    var selectedItem = $(this).children("option:selected").val()
    var searchTerm = $('#service-appliance').val();
    window.objOSGlobal.requestBodyValues.MakeId = Number($('#brand-appliance option:selected').val());
    if(selectedItem !='' && searchTerm!=''){
        $('#warranty-screen .next').removeClass('disble-btn').prop('disabled', false);
    }else{
        $('#warranty-screen .next').addClass('disble-btn').prop('disabled', true);
    }
});

export function warrantySearchScreen(serviceSkuId:any){
    getApplianceProblemList(serviceSkuId)
}

$('#service-address-screen input[name="zipcode"]').keyup(function (){
    var value = $(this).val() as string;
    let language = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    var regex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
    if(language == "ca"){
        regex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    }
    if(regex.test(value)){
     window.objOSGlobal.requestBodyValues.PostalCode = value;
     if(get_data('zipcode') != value){
         sessionStorage.setItem('changeZip',value);
         store_data(false,'franchiseLocation');
     }else{
         sessionStorage.removeItem('changeZip');
     }
    }
 });

 $('#service-address-screen').on("click", ".click-schedule", function(){
    let postalCode:String =  window.objOSGlobal.requestBodyValues.PostalCode
    let serviceId:Number = window.objOSGlobal.requestBodyValues.ServiceID;
    let serviceName:String = get_data('serviceName');
    serviceCategoryByName(postalCode,serviceId,serviceName,false);
    $('.progress, .progressbar').hide();
    $('#schedulermodallabel').addClass('hidden');
    $('.modal-header-two').hide();
    $('.modal-header-one').show();  
})