import {stop_loader, errorModal, sortResults} from "../util";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config/apiConfig";
import {storeServiceId} from ".";
import {warrantySearchScreen} from './services';
export function serviceSearchTerm(searchTerm:any,querParams:any,response:any,request:any){
    let ajaxReq: any = 'ToCancelPrevReq'; // you can have its value anything you like
    let serviceModifier = sessionStorage.getItem('ServiceModifierValue') ? sessionStorage.getItem('ServiceModifierValue') : "Residential";
    let postalCode = JSON.parse(localStorage.getItem('zipcode'));
    var country = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") == "ca" ? "2" : "1";
    ajaxReq = $.ajax({
        url: apiConfig.serviceSeacrhTerm + querParams + "/service-search-term-aggregates/search?search_term=" + searchTerm + "&country_id="+country+"&service_modifier="+serviceModifier+"&postal_code="+postalCode+"&apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        type: "GET",
        dataType: 'json',
        beforeSend: function (xhr)
        {
            if (ajaxReq != 'ToCancelPrevReq' && ajaxReq.readyState < 4)
            {
                ajaxReq.abort();
            }
        },
        success: function (data: any)
        {
            stop_loader();
            data = sortResults(data, "rank", true);
            response($.map(data, function (el)
            {
                const dropdown = $("#service-search-page").hasClass("active") ? $(".search-services-form .js-servicesListDropdown") :  $(".warranty-screen .js-servicesListDropdown");
                const dropdownfinal = $(".confirmationRequestCont .js-servicesListDropdown");
                let li = window.document.createElement("li");
                li.id = el.serviceId;

                let anchor = window.document.createElement("a");
                anchor.classList.add("selected");
                anchor.innerHTML = el.searchTerm;
                anchor.setAttribute("role", "button");
                anchor.tabIndex = 0;
                
                anchor.addEventListener("click", (e) => {
                    e.preventDefault();
                    if($('#service-search-page').hasClass('active')){
                        storeServiceId(el.serviceWebCategoryId, el.serviceId);
                        document.getElementById("serviceCategoryBtn").click();
                    }
                    if(window.location.href.indexOf("confirmation") > -1){
                        storeServiceId(el.serviceWebCategoryId, el.serviceId);
                        document.getElementById("serviceCategoryBtnFinal").click();
                    }
                    if($('#warranty-screen').hasClass('active')){
                       warrantySearchScreen(el.serviceSkuId);
                       window.objOSGlobal.requestBodyValues.ServiceSkuId = el.serviceSkuId
                       $('#service-appliance').val(el.name);
                    }
                });
                li.append(anchor);
                var serviceSearchActive = $("#service-search-page").hasClass("active");
                if (dropdown && serviceSearchActive)
                    dropdown.append(li);
                if (dropdownfinal && !serviceSearchActive)
                    dropdownfinal.append(li);    
            }));
        },
        error:function(data, status, error) {
            stop_loader();
           if(status == "error"){
            let errval = "";
            errorModal(errval)
           }
        }
    });
}