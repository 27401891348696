import { get_data, store_data, start_loader, stop_loader, errorModal, hideCommonRow } from "../util";
import { $ } from "../third-party-imports";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { HEADERS } from "../api";
import { setServiceAddressOsVariables} from "../services/setServiceAddressOsVariables";
import { getPropTypes } from "../util/getPropTypes";
import {s3upload} from "../form/file_upload";
import {getFranchiseWeblocation} from "../services/getFranchiseWeblocation";
import {fileUploadState} from "../form/fileUploadState";
import {setVehicleInformationVariables} from "../services/setVehicleInformationVariables";

(function() {
    $('#schedulecalendar-os .next-btn, .request-call').on('click', function() {
        $('#schedulecalendar-os .next-btn, .request-call').addClass("prevent-click");
        LeadConfirmation();
    });
    $('.confirm-btn').on('click', async function() {
        var id = $(this).parents('#service-details-screen').attr('id');
        if(id=='service-details-screen'){
            window.objOSGlobal.requestBodyValues.Note = $('#service-details-form textarea[name="input-service"]').val();
        }
        var code = window.objOSGlobal.requestBodyValues.ConceptCalledId || window.objOSGlobal.requestBodyValues.conceptId;
        if (get_data('zipcode') != window.objOSGlobal.requestBodyValues.PostalCode){
            if(get_data('franchiseLocation') == false){
                store_data(true,'confirmModal');
                var pane =$(this).closest('.tab-pane').attr('id');
                store_data(window.objOSGlobal.requestBodyValues.PostalCode,'zipcode');
                getFranchiseWeblocation(pane);
                return;
            }
        }
        if (code == '1'){
            let estimate = get_data('selectedAboutHome') == "recurring" ? 'Estimate' : 'Clean';
            window.objOSGlobal.requestBodyValues.Note = window.objOSGlobal.requestBodyValues.Note + ' | ' + estimate;
        }
        if (code == '6' || code == '2' || code=='13'){
            s3upload(function(){
                fileUploadState.isUploadedToS3 = true;
                LeadConfirmation();
            });
            
        }else{
            LeadConfirmation();
        }
        
    });
    if (window.location.href.indexOf("confirmation") > -1) {
        var confirmationTitle,confirmationSubText;
        var isLeadOnlyData = sessionStorage.getItem("CLeadOnly");
        var brandJsonData:any = JSON.parse(sessionStorage.getItem("brandObj"));
        var firstName = sessionStorage.getItem("CFirstName");
        var lastName = sessionStorage.getItem("CLastName");
        var jobDate = sessionStorage.getItem("CJobDate");
        var Time = sessionStorage.getItem("CTime");
        var serviceRequest = get_data("serviceName");
        var email = sessionStorage.getItem("CEmail");
        var phone = sessionStorage.getItem("CPhone");
        var street = sessionStorage.getItem("CAddress");
        var street2 = sessionStorage.getItem("CAddress2");
        var city = sessionStorage.getItem("CCity");
        var state = sessionStorage.getItem("CState");
        var pincode = sessionStorage.getItem("CPostalCode");
        // var CCode = sessionStorage.getItem("CConceptCode");
        var addressObj = street2 != "" ? street + ' ' + street2 : street;
        var fullname = firstName + " " + lastName;
        var fulladdress = addressObj + ' ' + city + ', ' + state + ' ' + pincode;
        var dt = $('<dt>'); var dt1 = $('<dt>'); var dt2 = $('<dt>'); var dt3 = $('<dt>'); var dt4 = $('<dt>'); var dt5 = $('<dt>'); var dt6 = $('<dt>'); var dt7 = $('<dt>'); var dt8 = $('<dt>');
		var dd = $('<dd>'); var dd1 = $('<dd>'); var dd2 = $('<dd>'); var dd3 = $('<dd>'); var dd4 = $('<dd>'); var dd5 = $('<dd>'); var dd6 = $('<dd>'); var dd7 = $('<dd>'); var dd8 = $('<dd>');
        
        //setTimeout(() => {
        var selectedAboutHome = get_data("selectedAboutHome");
        var modifier = sessionStorage.getItem('ServiceModifierValue');
        if (sessionStorage.getItem("CConceptCode") == 'MLY'){
            if(modifier =='Commercial'){
                confirmationTitle = sessionStorage.getItem("confirmTitle");
                confirmationSubText = sessionStorage.getItem("confirmSubText");
            }else{
                if(isLeadOnlyData =='true'){
                    if(selectedAboutHome =='onetime'){
                        confirmationTitle = brandJsonData.confirmation_title_lead_onetime;
                        confirmationSubText= brandJsonData.confirmation_subtext_lead_onetime;
                    }else{
                        confirmationTitle = brandJsonData.confirmation_title_lead_recurring;
                        confirmationSubText= brandJsonData.confirmation_subtext_lead_recurring;
                    }
                }else{
                    if(selectedAboutHome =='onetime'){
                        confirmationTitle = brandJsonData.confirmation_title_schedule_onetime;
                        confirmationSubText= brandJsonData.confirmation_subtext_schedule_onetime;
                    }else{
                        confirmationTitle = brandJsonData.confirmation_title_schedule_recurring;
                        confirmationSubText= brandJsonData.confirmation_subtext_schedule_recurring;
                    }
    
                }
            }
        }else{
            confirmationTitle = sessionStorage.getItem("confirmTitle");
            confirmationSubText = sessionStorage.getItem("confirmSubText");
        }
        if (confirmationTitle != null && confirmationTitle != '') {
            $(".confirmation-data .primary-title").text(confirmationTitle);
        } 
        else {
            $(".confirmation-data .primary-title").addClass("d-none");
        }

        if (confirmationSubText != null  && confirmationSubText != '') {
            $(".confirmation-data .sub-title").text(confirmationSubText);
        } 
        else {
            $(".confirmation-data .sub-title").addClass("d-none");
        }
		

        if (sessionStorage.getItem("CLeadOnly") != 'true') {
            $(dt).html('Date:');
            $(dd).html(jobDate);
            $(dt1).html('Time:');
            $(dd1).html(Time);
        } 
		else {
			$('.appointment .primary-theme-clr').html('Service:');
        }
        if (sessionStorage.getItem("CConceptCode") == 'MLY') {
            var MlyHomeDetails = sessionStorage.getItem("MlyHomeDetails");
            if(modifier =='Commercial'){
                $(dt8).html('Office Details:');
                $(dd8).html(MlyHomeDetails);
            }else{
                var MlyCleaning = sessionStorage.getItem("MlyCleaning");
                var MlyFrequency = sessionStorage.getItem("MlyFrequency");
                if(get_data("selectedAboutHome") == "onetime"){
                    $(dt7).html('Move-In/Move-Out Cleaning:');
                    $(dd7).html(MlyCleaning);
                }
                else{
                    $(dt7).html('Frequency:');
                    $(dd7).html(MlyFrequency);
                }
                $(dt8).html('Home Details:');
                $(dd8).html(MlyHomeDetails);
            }
        }else{
            $(dt2).html('Service Request:');
            $(dd2).html(serviceRequest);
        }
        $(dt3).html('Name:');
        $(dd3).html(fullname);
        $(dt4).html('Email:');
        $(dd4).html(email);
        $(dt5).html('Phone:');
        $(dd5).html(phone);
        $(dt6).html('Service Address:');
        $(dd6).html(fulladdress);
        $('.confirmation-data .appointment .text-sm').append(dt).append(dd);
        $('.confirmation-data .appointment .text-sm').append(dt1).append(dd1);
        $('.confirmation-data .appointment .text-sm').append(dt2).append(dd2);
        $('.confirmation-data .appointment .text-sm').append(dt7).append(dd7);
        $('.confirmation-data .appointment .text-sm').append(dt8).append(dd8);
        $('.confirmation-data .contact .text-sm').append(dt3).append(dd3);
        $('.confirmation-data .contact .text-sm').append(dt4).append(dd4);
        $('.confirmation-data .contact .text-sm').append(dt5).append(dd5);
        $('.confirmation-data .contact .text-sm').append(dt6).append(dd6);

		var searchTerm = sessionStorage.getItem("CPostalCode");
		$('.zipcodeServiceSearch').text(searchTerm);
        var serviceModifier = 4;
       getPropTypes(searchTerm,serviceModifier);
    }
})();


export function LeadConfirmation() {

    const objOSGlobal = window.objOSGlobal;
    setServiceAddressOsVariables();
    if(get_data('selectedPLGFilter') == 'Auto' && (objOSGlobal.requestBodyValues.conceptId == '14' || objOSGlobal.requestBodyValues.ConceptCalledId == '14')) {
        setVehicleInformationVariables();
    }
    if ($('#schedulecalendar-os .next-btn').text().toLowerCase().includes("skip")) {
        objOSGlobal.paramsUI.selectedDate = null;
        objOSGlobal.paramsUI.activeSlot = null;
        objOSGlobal.requestBodyValues.ScheduleId = null;
        objOSGlobal.requestBodyValues.IsLeadOnly = true;
    }

    if (window.LiveChatWidget && window.LiveChatWidget.get) {
        try {
            let chatData = window.LiveChatWidget.get('chat_data');
            objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel = "Note";
            objOSGlobal.requestBodyValues.LeadAdditionalNoteText = chatData && chatData.chatId;
        } catch (err) {
            console.warn("LiveChatWidget exists, however, it cannot load the chat data. Likely due to it not being loaded.", err);
        }
    }   

    var fileURL1, fileURL2, fileURL3 = "";

    if (objOSGlobal.requestBodyValues.FileUrls !== undefined) {
        fileURL1 = typeof(objOSGlobal.requestBodyValues.FileUrls[0]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[0] : "";
        fileURL2 = typeof(objOSGlobal.requestBodyValues.FileUrls[1]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[1] : "";
        fileURL3 = typeof(objOSGlobal.requestBodyValues.FileUrls[2]) !== 'undefined' ? objOSGlobal.requestBodyValues.FileUrls[2] : "";
    }

    if (objOSGlobal !== undefined) {
        var options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        } as const;

        if (objOSGlobal.requestBodyValues.IsLeadOnly == true) {
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
        } else if (objOSGlobal.requestBodyValues.Warranty == true) {
            window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date().toLocaleDateString("en-US", options);
        } else {
            objOSGlobal.requestBodyValues.JobPromisedDate = new Date(objOSGlobal.paramsUI.selectedDate).toLocaleDateString("en-US", options);
        }
        if (objOSGlobal.requestBodyValues.conceptId == "17") {
            objOSGlobal.requestBodyValues.ServiceText = "Interested in Property Management";
            objOSGlobal.requestBodyValues.DayTimePhoneNumber = objOSGlobal.requestBodyValues.Phone;
            objOSGlobal.requestBodyValues.ZipCode = objOSGlobal.requestBodyValues.PostalCode;
        }
        var host = window.location.hostname.toLowerCase();
        if (host === "www-nei-prod.nblytest.com" || host === "www.neighborly.com") {
            objOSGlobal.requestBodyValues.IsTest = false;
        } else {
            objOSGlobal.requestBodyValues.IsTest = true;
        }

		objOSGlobal.requestBodyValues.IsEstimate = get_data('selectedAboutHome') == "recurring" ? true : false;

        var payload = {
            "FranchiseId": objOSGlobal.requestBodyValues.FranchiseId ? objOSGlobal.requestBodyValues.FranchiseId : 0,
            "HasSameDayScheduling": objOSGlobal.requestBodyValues.HasSameDayScheduling ? objOSGlobal.requestBodyValues.HasSameDayScheduling : true,
            "IsBetaTester": objOSGlobal.requestBodyValues.IsBetaTester ? objOSGlobal.requestBodyValues.IsBetaTester : false,
            "Comments": objOSGlobal.requestBodyValues.Comments ? objOSGlobal.requestBodyValues.Comments : "",
            "IsLocalized": objOSGlobal.requestBodyValues.IsLocalized ? objOSGlobal.requestBodyValues.IsLocalized : true,
            "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? Number(objOSGlobal.requestBodyValues.ConceptId) : 0,
            "UseFranchiseProfileOptInForPOSScheduling": objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling ? objOSGlobal.requestBodyValues.UseFranchiseProfileOptInForPOSScheduling : true,
            "IsFranchiseOptedIntoPOSScheduling": objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling ? objOSGlobal.requestBodyValues.IsFranchiseOptedIntoPOSScheduling : true,
            "C4ChannelTypeId": objOSGlobal.requestBodyValues.C4ChannelTypeId ? objOSGlobal.requestBodyValues.C4ChannelTypeId : 0,
            "ConceptCalledId": objOSGlobal.requestBodyValues.ConceptCalledId ? Number(objOSGlobal.requestBodyValues.ConceptCalledId) : 0,
            "ConceptName": objOSGlobal.requestBodyValues.ConceptName ? objOSGlobal.requestBodyValues.ConceptName : "",
            "ConceptCode": objOSGlobal.requestBodyValues.ConceptCode ? objOSGlobal.requestBodyValues.ConceptCode : "",
            "C4LeadsEnabled": objOSGlobal.requestBodyValues.C4LeadsEnabled ? objOSGlobal.requestBodyValues.C4LeadsEnabled : true,
            "CanceledJobInformation": objOSGlobal.requestBodyValues.CanceledJobInformation ? objOSGlobal.requestBodyValues.CanceledJobInformation : "",
            "VendorId": objOSGlobal.requestBodyValues.VendorId ? objOSGlobal.requestBodyValues.VendorId : 0,
            "NationalLeadId": objOSGlobal.requestBodyValues.NationalLeadId ? objOSGlobal.requestBodyValues.NationalLeadId : "",
            "IsTest": objOSGlobal.requestBodyValues.IsTest,
            "VendorName": objOSGlobal.requestBodyValues.VendorName ? objOSGlobal.requestBodyValues.VendorName : "",
            "WebLocationId": objOSGlobal.requestBodyValues.WebLocationId ? Number(objOSGlobal.requestBodyValues.WebLocationId) : 0,
            "C4CallCenterId": objOSGlobal.requestBodyValues.C4CallCenterId ? objOSGlobal.requestBodyValues.C4CallCenterId : 0,
            "DayTimePhoneNumber": objOSGlobal.requestBodyValues.DayTimePhoneNumber ? objOSGlobal.requestBodyValues.DayTimePhoneNumber : "",
            "ZipCode": objOSGlobal.requestBodyValues.ZipCode ? objOSGlobal.requestBodyValues.ZipCode : "",
            "Note": objOSGlobal.requestBodyValues.Note ? objOSGlobal.requestBodyValues.Note : "",
            "FirstName": objOSGlobal.requestBodyValues.FirstName ? objOSGlobal.requestBodyValues.FirstName : "",
            "LastName": objOSGlobal.requestBodyValues.LastName ? objOSGlobal.requestBodyValues.LastName : "",
            "Email": objOSGlobal.requestBodyValues.Email ? objOSGlobal.requestBodyValues.Email : "",
            "Phone": objOSGlobal.requestBodyValues.Phone ? objOSGlobal.requestBodyValues.Phone : "",
            "PhoneType": objOSGlobal.requestBodyValues.PhoneType ? objOSGlobal.requestBodyValues.PhoneType : "",
            // "Phone2": objOSGlobal.requestBodyValues.Phone2 ? objOSGlobal.requestBodyValues.Phone2 : "",
            // "PhoneType2": objOSGlobal.requestBodyValues.PhoneType2 ? objOSGlobal.requestBodyValues.PhoneType2 : "",
            "PostalCode": objOSGlobal.requestBodyValues.PostalCode ? objOSGlobal.requestBodyValues.PostalCode : "",
            "City": objOSGlobal.requestBodyValues.City ? objOSGlobal.requestBodyValues.City : "",
            "State": objOSGlobal.requestBodyValues.State ? objOSGlobal.requestBodyValues.State : "",
            "Country": objOSGlobal.requestBodyValues.Country ? objOSGlobal.requestBodyValues.Country : "",
            "Address": objOSGlobal.requestBodyValues.Address ? objOSGlobal.requestBodyValues.Address : "",
            "Address2": objOSGlobal.requestBodyValues.Address2 ? objOSGlobal.requestBodyValues.Address2 : "",
            "LeadSource": objOSGlobal.requestBodyValues.LeadSource ? objOSGlobal.requestBodyValues.LeadSource : "Web",
            "LicenseNumber": objOSGlobal.requestBodyValues.LicenseNumber ? objOSGlobal.requestBodyValues.LicenseNumber : "",
            "WebLocationAccountCode": objOSGlobal.requestBodyValues.WebLocationAccountCode ? objOSGlobal.requestBodyValues.WebLocationAccountCode : "",
            "LeadOrigin": objOSGlobal.requestBodyValues.LeadOrigin ? objOSGlobal.requestBodyValues.LeadOrigin : "Web",
            "CampaignName": objOSGlobal.requestBodyValues.CampaignName ? objOSGlobal.requestBodyValues.CampaignName : "",
            "ReferringURL": objOSGlobal.requestBodyValues.ReferringURL ? objOSGlobal.requestBodyValues.ReferringURL : "",
            "Disposition": objOSGlobal.requestBodyValues.Disposition ? objOSGlobal.requestBodyValues.Disposition : "",
            "CallType": objOSGlobal.requestBodyValues.CallType ? objOSGlobal.requestBodyValues.CallType : "",
            "PreferredCommunicationType": objOSGlobal.requestBodyValues.PreferredCommunicationType ? objOSGlobal.requestBodyValues.PreferredCommunicationType : "",
            "EmailOptOut": objOSGlobal.requestBodyValues.EmailOptOut ? objOSGlobal.requestBodyValues.EmailOptOut : true,
            "TagName": objOSGlobal.requestBodyValues.TagName ? objOSGlobal.requestBodyValues.TagName : "",
            "Latitude": objOSGlobal.requestBodyValues.Latitude ? objOSGlobal.requestBodyValues.Latitude : 0,
            "Longitude": objOSGlobal.requestBodyValues.Longitude ? objOSGlobal.requestBodyValues.Longitude : 0,
            "LocationType": objOSGlobal.requestBodyValues.LocationType ? objOSGlobal.requestBodyValues.LocationType : "",
            "IsGoogleAddress": objOSGlobal.requestBodyValues.IsGoogleAddress ? objOSGlobal.requestBodyValues.IsGoogleAddress : true,
            "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
            "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
            "ServiceText": objOSGlobal.requestBodyValues.ServiceText ? objOSGlobal.requestBodyValues.ServiceText : "",
            "ServiceID": objOSGlobal.requestBodyValues.ServiceID ? objOSGlobal.requestBodyValues.ServiceID : 0,
            "Warranty": objOSGlobal.requestBodyValues.Warranty ? objOSGlobal.requestBodyValues.Warranty : false,
            "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
            "MakeId": objOSGlobal.requestBodyValues.MakeId ? objOSGlobal.requestBodyValues.MakeId : 0,
            "LeadSourceId": objOSGlobal.requestBodyValues.LeadSourceId ? objOSGlobal.requestBodyValues.LeadSourceId : 0,
            "SecondaryLeadSourceId": objOSGlobal.requestBodyValues.SecondaryLeadSourceId ? objOSGlobal.requestBodyValues.SecondaryLeadSourceId : 0,
            "ServiceTypeProblem": objOSGlobal.requestBodyValues.ServiceTypeProblem ? objOSGlobal.requestBodyValues.ServiceTypeProblem : "",
            "OverrideJobPromisedDate": objOSGlobal.requestBodyValues.OverrideJobPromisedDate ? objOSGlobal.requestBodyValues.OverrideJobPromisedDate : "",
            "ServiceSkuId": objOSGlobal.requestBodyValues.ServiceSkuId ? objOSGlobal.requestBodyValues.ServiceSkuId : 0,
            "ServiceSkuIds": objOSGlobal.requestBodyValues.ServiceSkuIds,
            "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
            "EstimateHouseStyle": objOSGlobal.requestBodyValues.EstimateHouseStyle ? objOSGlobal.requestBodyValues.EstimateHouseStyle : "",
            "EstimateSquareFeet": objOSGlobal.requestBodyValues.EstimateSquareFeet ? objOSGlobal.requestBodyValues.EstimateSquareFeet : "",
            "EstimateBedrooms": objOSGlobal.requestBodyValues.EstimateBedrooms ? objOSGlobal.requestBodyValues.EstimateBedrooms : "",
            "EstimateBathrooms": objOSGlobal.requestBodyValues.EstimateBathrooms ? objOSGlobal.requestBodyValues.EstimateBathrooms : "",
            "EstimatePets": objOSGlobal.requestBodyValues.EstimatePets ? objOSGlobal.requestBodyValues.EstimatePets : "",
            "CCFirstName": objOSGlobal.requestBodyValues.CCFirstName ? objOSGlobal.requestBodyValues.CCFirstName : "",
            "CCLastName": objOSGlobal.requestBodyValues.CCLastName ? objOSGlobal.requestBodyValues.CCLastName : "",
            "CCType": objOSGlobal.requestBodyValues.CCType ? objOSGlobal.requestBodyValues.CCType : "",
            "CCSuffix": objOSGlobal.requestBodyValues.CCSuffix ? objOSGlobal.requestBodyValues.CCSuffix : "",
            "CCToken": objOSGlobal.requestBodyValues.CCToken ? objOSGlobal.requestBodyValues.CCToken : "",
            "CCExpirationDate": objOSGlobal.requestBodyValues.CCExpirationDate ? objOSGlobal.requestBodyValues.CCExpirationDate : "",
            "CCComment": objOSGlobal.requestBodyValues.CCComment ? objOSGlobal.requestBodyValues.CCComment : "",
            "IsLeadOnly": objOSGlobal.requestBodyValues.IsLeadOnly,
            //"ScheduleID": objOSGlobal.requestBodyValues.ScheduleID ? objOSGlobal.requestBodyValues.ScheduleID : "",
            "TimeBlock": {
                "ConceptId": objOSGlobal.requestBodyValues.ConceptId ? objOSGlobal.requestBodyValues.ConceptId : 0,
                "GoogleCalendarEventId": objOSGlobal.requestBodyValues.GoogleCalendarEventId ? objOSGlobal.requestBodyValues.GoogleCalendarEventId : "",
                "GoogleCalendarEmail": objOSGlobal.requestBodyValues.GoogleCalendarEmail ? objOSGlobal.requestBodyValues.GoogleCalendarEmail : "",
                "TimeSlotTypeId": objOSGlobal.requestBodyValues.TimeSlotTypeId ? objOSGlobal.requestBodyValues.TimeSlotTypeId : 0,
                "ServiceSkuId": objOSGlobal.requestBodyValues.ServiceSkuId ? objOSGlobal.requestBodyValues.ServiceSkuId : 0,
                "IsEstimate": objOSGlobal.requestBodyValues.IsEstimate,
                "JobPromisedDate": objOSGlobal.requestBodyValues.JobPromisedDate ? objOSGlobal.requestBodyValues.JobPromisedDate : "",
                "IsAvailable": objOSGlobal.requestBodyValues.IsAvailable ? objOSGlobal.requestBodyValues.IsAvailable : true,
                "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
                "DisplayText": objOSGlobal.requestBodyValues.DisplayText ? objOSGlobal.requestBodyValues.DisplayText : ""
            },
            "OverrideCSRName": objOSGlobal.requestBodyValues.OverrideCSRName ? objOSGlobal.requestBodyValues.OverrideCSRName : "",
            "OverrideComment": objOSGlobal.requestBodyValues.OverrideComment ? objOSGlobal.requestBodyValues.OverrideComment : "",
            "SyncTenantName": objOSGlobal.requestBodyValues.SyncTenantName ? objOSGlobal.requestBodyValues.SyncTenantName : "",
            "IsEmergencyCall": objOSGlobal.requestBodyValues.IsEmergencyCall ? objOSGlobal.requestBodyValues.IsEmergencyCall : false,
            "CallId": objOSGlobal.requestBodyValues.CallId ? objOSGlobal.requestBodyValues.CallId : "",
            "IsFranchiseInPOS": objOSGlobal.requestBodyValues.IsFranchiseInPOS ? objOSGlobal.requestBodyValues.IsFranchiseInPOS : true,
            "CustomerType": objOSGlobal.requestBodyValues.CustomerType ? objOSGlobal.requestBodyValues.CustomerType : "",
            "NeighborlyAccountId": objOSGlobal.requestBodyValues.NeighborlyAccountId ? objOSGlobal.requestBodyValues.NeighborlyAccountId : 0,
            "NeighborlyLeadSourceId": objOSGlobal.requestBodyValues.NeighborlyLeadSourceId ? objOSGlobal.requestBodyValues.NeighborlyLeadSourceId : 0,
            "FileUrls": [{
                "FileUrl": fileURL1
            }, {
                "FileUrl": fileURL2
            }, {
                "FileUrl": fileURL3
            }],
            "JobFrequency": objOSGlobal.requestBodyValues.JobFrequency ? objOSGlobal.requestBodyValues.JobFrequency : "",
            "JobFrequencyDetail": objOSGlobal.requestBodyValues.JobFrequencyDetail ? objOSGlobal.requestBodyValues.JobFrequencyDetail : "",
            "Year": objOSGlobal.requestBodyValues.Year ? objOSGlobal.requestBodyValues.Year : 0,
            "Make": objOSGlobal.requestBodyValues.Make ? objOSGlobal.requestBodyValues.Make : "",
            "Model": objOSGlobal.requestBodyValues.Model ? objOSGlobal.requestBodyValues.Model : "",
            "BodyStyle": objOSGlobal.requestBodyValues.BodyStyle ? objOSGlobal.requestBodyValues.BodyStyle : "",
            "VIN": objOSGlobal.requestBodyValues.VIN ? objOSGlobal.requestBodyValues.VIN : "",
            "LicensePlate": objOSGlobal.requestBodyValues.LicensePlate ? objOSGlobal.requestBodyValues.LicensePlate : "",
            "WindshieldRepair": objOSGlobal.requestBodyValues.WindshieldRepair ? objOSGlobal.requestBodyValues.WindshieldRepair : "",
            "HeadlightRestoration": objOSGlobal.requestBodyValues.HeadlightRestoration ? objOSGlobal.requestBodyValues.HeadlightRestoration : "",
            "ServiceRequested": [] as any[],
            "LeadAdditionalNotes": {
                "LeadAdditionalNoteLabel": objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel ? objOSGlobal.requestBodyValues.LeadAdditionalNoteLabel : "",
                "LeadAdditionalNoteText": objOSGlobal.requestBodyValues.LeadAdditionalNoteText ? objOSGlobal.requestBodyValues.LeadAdditionalNoteText : ""
            },
            "FormOfPayment": objOSGlobal.requestBodyValues.FormOfPayment ? objOSGlobal.requestBodyValues.FormOfPayment : "",
            "InsuranceCompany": objOSGlobal.requestBodyValues.InsuranceCompany ? objOSGlobal.requestBodyValues.InsuranceCompany : "",
            "DeductibleAmount": objOSGlobal.requestBodyValues.DeductibleAmount ? objOSGlobal.requestBodyValues.DeductibleAmount : 0,
            "PolicyNumber": objOSGlobal.requestBodyValues.PolicyNumber ? objOSGlobal.requestBodyValues.PolicyNumber : "",
            "ScheduleID": objOSGlobal.requestBodyValues.ScheduleId ? objOSGlobal.requestBodyValues.ScheduleId : "",
        };

        //moved up session storage
        sessionStorage.setItem('CFirstName', payload.FirstName);
        sessionStorage.setItem('CLastName', payload.LastName);
        sessionStorage.setItem('CAddress', payload.Address);
        sessionStorage.setItem('CAddress2', payload.Address2);
        sessionStorage.setItem('CCity', payload.City);
        sessionStorage.setItem('CState', payload.State);
        sessionStorage.setItem('CPostalCode', payload.PostalCode);
        sessionStorage.setItem('CJobDate', payload.TimeBlock.JobPromisedDate);
        sessionStorage.setItem('CEmail', payload.Email);
        sessionStorage.setItem('CPhone', payload.Phone);
        sessionStorage.setItem('CLeadOnly', payload.IsLeadOnly);
        sessionStorage.setItem('CServiceRequest', objOSGlobal.requestBodyValues.serviceName);
        sessionStorage.setItem('CConceptCode', objOSGlobal.requestBodyValues.ConceptCode);

        start_loader();
        $.ajax({
            url: window.DEFINE_VALUE.bookingEndpoint + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY + "&" + getNblyApiHeaders(),
            type: "POST",
            headers: HEADERS,
            dataType: "json",
            data: JSON.stringify(payload),
            success: function(data: any) {
                stop_loader();           
				var afterCom = window.location.href.split('.com')[1];
                var isWebSite = afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "/ca" : "";
                window.location.replace(window.location.origin + isWebSite + "/confirmation");

            },
            error: function(data, status, error) {
                stop_loader();
                console.log(data)
                if(status == "error"){
                    let errval = "";
                    errorModal(errval)
                }
                hideCommonRow();
            }
        });
    }
}