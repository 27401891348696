import { neiLocalStorage } from '../config/NeiLocalStorage';

export const setSessionRememberMe = () =>
{
    let decodeData = neiLocalStorage.decodedAccessToken;
    if (decodeData.isRememberMe == 'False')
    {
        sessionStorage.setItem('isRememberMeNotSelected', decodeData.isRememberMe);
    } else
    {
        localStorage.setItem('isRememberMeNotSelected', decodeData.isRememberMe);
    }
}
