import { $ } from "../third-party-imports"
import { getNblyMicroServiceApiHeaders } from "../api";



export function callAddEDitApi(payload: any, url: any, successHandler: any, type: any)
{
    $.ajax({
        url: url,
        data: JSON.stringify(payload),
        headers: getNblyMicroServiceApiHeaders(),
        type: type || "POST",
        contentType: "application/json",
        success: successHandler,
        error: function (xhr, status, error)
        {
            console.log(error);
        }
    });
}
