import { start_loader, stop_loader} from "../util";
import { apiConfig } from "../config/apiConfig";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
let mraProblemlist: any[] = [];

function generateProblemListRow(listArray:any){
	return '<div class="appliance-row">'+listArray.join('')+'</div>'
}

export const getApplianceProblemList = function(serviceSkuId:any){
    var conceptid = window.objOSGlobal.requestBodyValues.ConceptCalledId;
    start_loader();
    $.ajax({
        url: apiConfig.serviceTypeProblemAggregates + conceptid + '/service-type-problem-aggregates/search?&apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        dataType: 'json',
        type: 'GET',
        success: function (data: any)
        {
            stop_loader();
            mraProblemlist = data;
            problemListAppend(serviceSkuId);
            $('#opwarrant-next').removeClass('disble-btn');

        }, error: function (e: any)
        {
            //console.log(e.message);
        }
    }); 
}

const problemListAppend = function(skuId:any){
    var filteredProblemList = mraProblemlist.filter(problem => problem.serviceSkuId == skuId);

    var applianceList = [] as any[];
    var rows = [] as any[];
    var rowsWrap = [] as any[];
    var resultArray = filteredProblemList;
    $.each(resultArray, function (index: any, result: any)
    {
        applianceList.push('<div class="appliance-col"><div class="custom-checkbox"><input class="prolems-checkBox" value="'+result.serviceTypeProblemName+'" id="appliance'+index+'" type="checkbox"> <label for="appliance'+index+'">'+result.serviceTypeProblemName+'</label> </div> </div>');

    });
    if(applianceList.length){
        for(let i=0; i<applianceList.length/4; i++){
            var startingIndex = i*4;
            rows.push(applianceList.slice(startingIndex,(startingIndex+4)));
        }

        for(let i=0; i<rows.length; i++){
            rowsWrap.push(generateProblemListRow(rows[i]))
        }

        $('.appliance-problem-list-wrap').empty();
        $('.appliance-problem-list-wrap').append(rowsWrap);

    }
}