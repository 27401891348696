import { titleCase } from "./titleCase";
import { $ } from "../third-party-imports"


export function updateBlogResultText(totalCount: any, category: any, tag: any, content: any)
{
    category = titleCase(category);
    tag = titleCase(tag);
    const blogSearchResultText = $('.search-result');

    if ((category.toLowerCase() === 'none' && !content && !tag))
    {
        blogSearchResultText.html('');
        return;
    }

    let updatedText = '';

    if (category.toLowerCase() !== 'none')
    {
        updatedText = `${totalCount} Results for Category: ${category}`;
    }

    if (content)
    {
        updatedText = `${totalCount} Results for Search: ${content}`;
    }

    if (tag)
    {
        updatedText = `${totalCount} Results for Tag: ${tag}`;
    }

    if (totalCount === 0)
    {
        updatedText = updatedText + '<br />Try using other keywords that are related to your search.';
    }

    blogSearchResultText.html(updatedText);
}
