export function get_data(key: any){
    if (!window.localStorage || !window.JSON || !key){
        return;
    }
    var item = localStorage.getItem(key);

    if (!item){
        return;
    }
    if (typeof item == 'string'){
        return JSON.parse(item);
    }else{
        return item;
    }
}
