
import { $ } from "../third-party-imports";
import { domLoaded } from '@nbly/crownpeak';

domLoaded(function ()
{
    $('.popup-youtube').magnificPopup({
        disableOn: 320,
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: false,
        fixedContentPos: true
    });
    
    $('.owl-carousel-videos .item').magnificPopup({
        delegate: 'a',
    });
});
