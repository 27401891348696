import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { $ } from "../third-party-imports";

export function getState()
{
    // var token = 'Bearer ' + JSON.parse(localStorage.getItem('bearer_token')).access_token
    var baseUrl = apiConfig.stateApi;
    var country = window.location.pathname.split('/')[1].replace(/[^a-zA-Z ]/g, "");
    country = country == "ca" ? "CA" : "US";
    $('#input-service-state').empty();
    $('#state-bumper').empty();
    $('#input-service-state').append('<option value="">Select</option>');
    // start_loader();
    $.ajax({
        url: baseUrl + country + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        dataType: 'json',
        type: 'GET',
        success: function (data, status)
        {
            // stop_loader();
            var states = [] as any[];
            $.each(data, function (index, element)
            {
                states.push('<option id="' + element.stateCode + '" value="' + element.stateCode + '">'+element.stateName+'</option>');
            });
            
            $('#stateList').append(states);
            $('#stateaddressos').append(states);
            $('#input-service-state').append(states);
            $('#state-bumper').append(states);

             if(country == "CA"){
                $.ajax({
                    url: baseUrl + "US?apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                    dataType: 'json',
                    type: 'GET',
                    success: function (data, status)
                    {
                        var states = [] as any[];
                        $.each(data, function (index, element)
                        {
                            states.push('<option id="' + element.stateCode + '" value="' + element.stateCode + '">'+element.stateName+'</option>');
                        });
                        $('#stateList').append(states);
                        $('#stateaddressos').append(states);
                        $('#input-service-state').append(states);
                        $('#state-bumper').append(states);
                    }
                });
             }            
        },
        error: function (error)
        {
            // stop_loader();
            console.log(error);
        }
    });
}
