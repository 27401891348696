import { $ } from "../third-party-imports"
import { fileUploadState } from ".";

export function uploader(options: any) {
    let removeImageButtonClicked: boolean;
    let settings = $.extend({
            MessageAreaText: "No files selected.",
            MessageAreaTextWithFiles: "File List:",
            DefaultErrorMessage: "Unable to open this file.",
            BadTypeErrorMessage: "We cannot accept this file type at this time.",
            // acceptedFileTypes: [
            //     "pdf",
            //     "jpg",
            //     "gif",
            //     "jpeg",
            //     "bmp",
            //     "tif",
            //     "tiff",
            //     "png",
            //     "xps",
            //     "doc",
            //     "docx",
            //     "fax",
            //     "wmp",
            //     "ico",
            //     "txt",
            //     "cs",
            //     "rtf",
            //     "xls",
            //     "xlsx"
            // ]
            acceptedFileTypes: [
                "jpg",
                "gif",
                "jpeg",
                "bmp",
                "tif",
                "tiff",
                "png",
            ]
        },
        options
    );

    let uploadId = 0;
    //update the messaging
    $(".file-uploader__message-area p").text(
        options.MessageAreaText || settings.MessageAreaText
    );

    //create and add the file list and the hidden input list
    let fileList = $('<ul class="file-list"></ul>');
    let hiddenInputs = $('<div class="hidden-inputs hidden"></div>');
    $(".file-uploader__message-area").after(fileList);
    $(".file-list").after(hiddenInputs);

    //when choosing a file, add the name to the list and copy the file input into the hidden inputs
    $(".file-chooser__input").on("change", function() {
        let $filesUploadedCount = $('.file-list li');
        if ($filesUploadedCount.length == 3)
            return;
            
        var file = $(".file-chooser__input").val() as string;
        var fileName = file.match(/([^\\\/]+)$/)[0];
        $('label').removeClass('hide-label');
        $('.prefilled-path').hide();

        //clear any error condition
        $(".file-chooser").removeClass("error");
        $(".error-message").remove();

        //validate the file
        var check = checkFile(this,fileName);
        if (check === "valid") {
            // move the 'real' one to hidden list
            $(".hidden-inputs").append($(".file-chooser__input"));

            //insert a clone after the hiddens (copy the event handlers too)
            $(".file-chooser").append(
                $(".file-chooser__input").clone(true)
            );

            //add the name and a remove button to the file-list
            $(".file-list").append(
                '<li style="display: none;"><span class="file-list__name">' +
                fileName +
                '</span><button class="removal-button" data-uploadid="' +
                uploadId +
                '"></button></li>'
            );
            $(".file-list").find("li:last").show(800);
            $(".file-list").addClass('list-border')

            //removal button handler
            $(".removal-button").on("click", function(e) {
                if(removeImageButtonClicked !== $(this).data("uploadid")) {
                    e.preventDefault();
                    $(".file-list").removeClass('list-border');
                    $('.v-hide').addClass('hide-label');
                    // $('.prefilled-path').show();

                    //remove the corresponding hidden input
                    $(
                        '.hidden-inputs input[data-uploadid="' +
                        $(this).data("uploadid") +
                        '"]'
                    ).remove();

                    //remove the name from file-list that corresponds to the button clicked
                    $(this)
                        .parent()
                        .hide("puff")
                        .delay(10)
                        .queue(function() {
                            $(this).remove();
                        });

                    //if the list is now empty, change the text back
                    if ($(".file-list li").length === 0) {
                        $(".file-uploader__message-area").text(
                            options.MessageAreaText || settings.MessageAreaText
                        );
                    }

                    $($('#fileupload').children()[1]).css('display', 'block');
                    $($('#fileupload').children()[2]).css('display', 'block');
                    uploadId--;
                    fileUploadState.isUploadedToS3 = false;
                    removeImageButtonClicked = $(this).data("uploadid");
                }
            });

            //so the event handler works on the new "real" one
            $(".hidden-inputs .file-chooser__input")
                .removeClass("file-chooser__input")
                .attr("data-uploadId", uploadId);

            //update the message area
            $(".file-uploader__message-area").text(
                options.MessageAreaTextWithFiles || settings.MessageAreaTextWithFiles
            );

            uploadId++;
        } else {
            //indicate that the file is not ok
            $(".file-chooser").addClass("error");
            var errorText =
                options.DefaultErrorMessage || settings.DefaultErrorMessage;

            if (check === "badFileName") {
                errorText =
                    options.BadTypeErrorMessage || settings.BadTypeErrorMessage;
            }

            $(".file-chooser__input").after(
                '<p class="error-message">' + errorText + "</p>"
            );
        }
        
        if (uploadId >= 3){
            $($('#fileupload').children()[1]).css('display', 'none');
            $($('#fileupload').children()[2]).css('display', 'none');
            $('#fileupload .file-list').removeClass('list-border');
        } else {
            $($('#fileupload').children()[1]).css('display', 'block');
            $($('#fileupload').children()[2]).css('display', 'block');
        }
    });

    var checkFile = function(element: any, fileName: string) {
        var accepted = "invalid",
            acceptedFileTypes =
            element.acceptedFileTypes || settings.acceptedFileTypes,
            regex;

        for (var i = 0; i < acceptedFileTypes.length; i++) 
        {
            regex = new RegExp("\\." + acceptedFileTypes[i] + "$", "i");

            if (regex.test(fileName)) {
                accepted = "valid";
                break;
            } else {
                accepted = "badFileName";
            }
        }

        return accepted;
    };
};