import { apiConfig } from "../config";
import { getNblyMicroServiceApiHeaders } from '../api';
import { $ } from "../third-party-imports"
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { start_loader, stop_loader } from "../util";

//Get All Notification 
export function getAllNotification()
{
    // start_loader();
    $.ajax({
        url: apiConfig.notificationApi + 'WebInAppNotifications'+"?"+getNblyApiHeaders(),
        type: 'GET',
        headers: getNblyMicroServiceApiHeaders(),
        success: function (data, status)
        {
            // stop_loader();
            var profileNotification: any[] = [];
            var resultArray = data.data;
            if (data.count == 0)
            {
                $('.nbly_notification').append('<li class="no-notify"><span>You don’t have any new notifications!</span></li>');
                return;
            } else
            {
                let notificationClass: string;
                $.each(resultArray, function (index, resultArray)
                {
                    if (resultArray.notificationTypeId === 1)
                    {
                        notificationClass = 'jobstatus';
                    } else if (resultArray.notificationTypeId === 2)
                    {
                        notificationClass = 'offer';
                    } else if (resultArray.notificationTypeId === 3)
                    {
                        notificationClass = 'track';
                    }
                    profileNotification.push('<li data-notificationId="' + resultArray.neighborlyNotificationId + '" class="' + notificationClass + '"><span>' + resultArray.notificationTitle + '</span></li>');
                });
                $('.nbly_notification').append(profileNotification);
            }
        },
        error: function (error)
        {
            // stop_loader();
            console.log(error);
        }
    });
}
