import { apiConfig, getMSALInstance } from "../config";
import { clearStorage } from "./clearStorage";
import { logout, passTokenToApi, signIn, signUp } from "../auth-redirect";
import { updateLogout } from "../api/updateLogout";
import { mobileSupport } from "..";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { $ } from "../third-party-imports";

    $(function (){   
    // UI elements to work with
    const signInBtn = document.getElementById("signIn");
    const signOutBtn = document.getElementById("signOut");
    const joinIn = document.getElementById('joinIn');

    if (signInBtn){
        signInBtn.addEventListener("click", function (){
            signIn();
        })
    }
    if (joinIn){
        joinIn.addEventListener("click", function (){
            signUp();
        })
    };

    if (signOutBtn){
        signOutBtn.addEventListener("click", function (){
            const updateLogoutKey = {
                endpoint: apiConfig.updateLoginApi+"&"+getNblyApiHeaders(),
                socialProfileDomain: 1,
                neighborlyUserId: 0,
                method: "post",
             };
            updateLogout(updateLogoutKey);
            logout();
        })
    }
    const myMSALObj = getMSALInstance();
    if (myMSALObj.getAccount()){
        let join_col = document.getElementById('join_col');
        join_col.classList.add('d-none');
            passTokenToApi();
    }
    clearStorage();
    mobileSupport();
});