import { getCountAllNotification } from "./getCountAllNotification";
import { $ } from "../third-party-imports"

export function ProfileDropdownClick()
{
    $('.neighbourly_Box .dropdown-nbr').click(function ()
    {
        getCountAllNotification();
    });
}
