/* mobile app promo api end */
export function sortResults(result: any, prop: any, asc: any)
{
    result.sort(function (a: any, b: any)
    {
        if (asc)
        {
            return (a[prop] > b[prop]) ? 1 : ((a[prop] < b[prop]) ? -1 : 0);
        }
        else
        {
            return (b[prop] > a[prop]) ? 1 : ((b[prop] < a[prop]) ? -1 : 0);
        }
    });
    return result;
}
