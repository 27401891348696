
export function getBlogRow(blog: any, index: any)
{
    return `<div class="search-result-item">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-8 flex-sm-bottom">
                    <article>
                        <h3 id="articleTitle${index}" class="blog-head-search">${blog.custom_s_heading}</h3>
                        <p class="blog-summery" id="articleSummary${index}"><span class="blue-bold-regular-text">${blog.custom_t_content_summary_Desc} […] </span></p>
                        <div class="learn-more">
                            <a id="articleHref${index}" href="${blog.custom_s_local_url}" class="whiteTextLink linkText">
                                <span id="articleCTA${index}" class="whiteTextLinkCarrot">Learn more</span>
                            </a>
                        </div>
                    </article>
                </div>
                <div class="col-md-4 paddin-left-0-lg flex-sm-top">
                    <figure class="blog-image">
                        <img id="articleSummaryImage${index}" src="${blog.custom_s_article_summary_Image_url}" alt="${blog.custom_t_content_summary_Image_url_alt}" loading="lazy" width="414px" height="193px">
                    </figure>
                </div>
                
            </div>
        </div>
    </div>`;
}
