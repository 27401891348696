import { $ } from "../third-party-imports";
import { profileVars } from "../profile";
import { signIn } from '../auth-redirect/signIn';
import { chunkArray, start_loader, stop_loader,getRefDetails } from '../util';
import { getState } from "../util/getState";
import { apiConfig } from "../config";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import {setProgressBarDisplay} from "../services/setProgressBarDisplay";
import {aboutHereAboutYourHome} from "../services/aboutHereAboutYourHome";
import {addressStateValidate} from "../services/addressStateValidate";

$(function (){

    $.validator.setDefaults({
        errorElement: "div",
        errorPlacement: function (error, element){
            // Add the `help-block` class to the error element
            error.addClass("help-block invalid-feedback");
            // error.insertAfter(element);
            if(element.attr('type') == "search"){
                error.insertAfter(element.parent());
            }
            else{
                error.insertAfter(element);
            }
        },
        highlight: function (element, errorClass, validClass){
            $(element)
                .parents(".form-group")
                .addClass("has-error")
                .removeClass("has-success");
        },
        unhighlight: function (element, errorClass, validClass){
            $(element)
                .parents(".form-group")
                .addClass("has-success")
                .removeClass("has-error");
        },
    });

    $.validator.addMethod(
        "password",
        function (this: any, value, element){
            var result =
                this.optional(element) ||
                (value.length >= 6 && /\d/.test(value) && /[a-z]/i.test(value));
            if (!result)
            {
                //var validator = this;
            }
            return result;
        },
        "Your password must be at least 8 characters"
    );
    $.validator.addMethod(
        "defaultInvalid",
        function (value, element)
        {
            return value != element["defaultValue"];
        },
        ""
    );

    $(".personal-info-form").validate({
        onkeyup:function(this:any,element){
         if($(element).val() !=""){
            $(element).valid();
          }
          var checkeForm = this.checkForm();
          if(checkeForm){
            $("#contact-information-screen .next").removeClass("disble-btn");
            $("#contact-information-screen .next").prop("disabled", false);
          }else{
            $("#contact-information-screen .next").addClass("disble-btn");
            $("#contact-information-screen .next").prop("disabled", true);
          }
        },
        rules: {
            firstname : {
                required: true,
                noSpace: true
            },
            lastname: {
                required: true,
                noSpace: true
            },
            email: {
                required: true,
                email: true,
            },
            phonenumber: {
                required: true,
                minlength: 14,
                maxlength: 14,
            },
        },
        messages: {
            phonenumber: "Please enter a valid phone number",
        },
    });

    $("#service-address").validate({
        onkeyup:function(this:any,element){
            if($(element).val() !=""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm && addressStateValidate()){
              $("#service-address-screen .next").removeClass("disble-btn");
              $("#service-address-screen .next").prop("disabled", false);
            }else{
              $("#service-address-screen .next").addClass("disble-btn");
              $("#service-address-screen .next").prop("disabled", true);
            }
        },
        rules: {
            address: {
                required: true,
                noSpace: true
            },
            address2: {
                noSpace: true
            },
            city: {
                required: true,
                noSpace: true
            },
            zipcode: {
                required: true,
                addressZip: true,
            },
        },
    });

    $("#about-home").validate({
        onkeyup:function(this:any,element){
            $(element).valid();
            var checkeForm = this.checkForm();
            if(checkeForm && aboutHereAboutYourHome()){
                $('.about-home-screen .next').removeClass('disble-btn').prop('disabled', false);
            }else{
                $('.about-home-screen .next').addClass('disble-btn').prop('disabled', true);
            }
        },
        rules: {
            squarefeet: {
                required: true,
            }
        }
    });

    $("#service-details-form").validate({
        onfocusout:function(this:any,element){
            $(element).valid();
        },
        rules: {
            referencedetails: {
                required: true,
            }
        }
    });

    $(".location-services").validate({
        onkeyup:function(this:any,element) {
            if($(element).val() !=""){
                $(element).valid();
            }
        },
        rules: {
            locationSearch: {
                required: true,
                addressZip:true
            }
        }
    });

    $("#warrantyDetails").validate({
        onfocusout:function(this:any,element){
            var wsa = $(element).attr('id');
            if(wsa =="service-appliance"){
                if($(element).val() !=""){
                    $(element).valid();
                }
            }else{
                $(element).valid();
            }
            
        },
        rules: {
            serviceListQuery: {
                required: true,
            },
            brandappliance: {
                required: true,
            },
        }
    });

    $("#vehicle-information").validate({
        onkeyup:function(this:any,element){
            if($(element).val() != ""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
              $("#vehicle-information-1 .next").removeClass("disble-btn");
              $("#vehicle-information-1 .next").prop("disabled", false);
            }else{
              $("#vehicle-information-1 .next").addClass("disble-btn");
              $("#vehicle-information-1 .next").prop("disabled", true);
            }
        },
        rules: {
            year: {
                required: true,
                min: 1,
                minlength:4,
                maxlength: 4,
                number: "Please enter valid year"
            },
            bodystyle: {
                required: true,
            },
            make: {
                required: true,
            },
            model: {
                required: true,
            },
        },
        messages: {
            year: {
                min: "Please enter valid year"
            }
         }
    });
     

    $("#addressForm").validate({
        onkeyup:function(this:any,element) {
            if($(element).val() !==""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
              $("#service-address-bumper .next").removeClass("disble-btn");
              $("#service-address-bumper .next").prop("disabled", false);
            }else{
              $("#service-address-bumper .next").addClass("disble-btn");
              $("#service-address-bumper .next").prop("disabled", true);
            }
        },
        rules: {
            address: {
                required: true,
            },
            city: {
                required: true,
            },
            state: {
                required: true,
            },
            zipcode: {
                required: true,
                addressZip: true,
            },
        },
    });

    $("#signInForm2").validate({
        onkeyup:function(this:any,element) {
            if($(element).val() !==""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
                $("#profileNameBtn").removeClass("disble-btn");
                $("#profileNameBtn").prop("disabled", false);
            }else{
                $("#profileNameBtn").addClass("disble-btn");
                $("#profileNameBtn").prop("disabled", true);
            }
        },
        rules: {
            profileFName: {
                required: true,
                noSpace: true,
            },
            profileLName: {
                required: true,
                noSpace: true,
            },
        },
        messages: {
            profileFName: "Please enter first name",
            profileLName: "Please enter last name",
        },
    });

    $("#serviceType-personal").validate({
        rules: {
            inputEmail: {
                required: true,
                email: true,
            },
            inputPassword: {
                required: true,
            },
        },
        messages: {
            inputEmail: "That doesn’t look like a valid email",
            inputPassword: "That doesn’t look like a valid password",
        },
    });

    $("#VehicleInfo1 .option-info-form").validate({
        rules: {
            year: {
                required: true,
            },
            make: {
                required: true,
            },
            model: {
                required: true,
            },
            bodystyle: {
                required: true,
            },
        },
    });

    $("#profileAddressForm").validate({
        onkeyup:function(this:any,element) {
            if($(element).val() !==""){
                $(element).valid();
            }
            var checkeForm = this.checkForm();
            if(checkeForm){
                $("#profileAddressBtn").removeClass("disble-btn");
                $("#profileAddressBtn").prop("disabled", false);
            }else{
                $("#profileAddressBtn").addClass("disble-btn");
                $("#profileAddressBtn").prop("disabled", true);
            }
        },
        rules: {
          profileadd: {
            required: true,
            noSpace: true,
          },
          profilecty: {
            required: true,
            noSpace: true,
          },
          profilest:{
              required: true,
          },
          profilezip: {
            required: true,
            profileZipMethod: true,
          }
        },
        messages: {
            profileadd: {
                required:"Please enter address"
            },
            profilecty:{
                required:"Please enter city"
            },
            profilest: {
                required:"Please select a state"
            },
            profilezip: {
                required:"Please enter ZIP/Postal code"
            }
          },
      });

    //   $('#vehicle-information-3 .option-info-form').validate({
    //     rules: {
    //         mobileservicezipcode: {
    //             addressZip: true
    //         }
    //     }
    //   });

});

    // function getStateOs()
    // {
    //     $('#state option').empty();
    //     $.ajax({
    //         url: 'https://api.nblytest.com/profileservice-dev/v1/public/IdentityExperience/States/USA?apikey=' + window.DEFINE_VALUE.JS_API_KEY,
    //         dataType: 'json',
    //         type: 'GET',
    //         success: function (data)
    //         {
    //             var states: any[] = [];
    //             var resultArray = data;
    //             $.each(resultArray, function (index, resultArray)
    //             {
    //                 states.push('<option value="' + resultArray.stateCode + '">' + resultArray.stateName + '</option>')
    //             });
    //             $('#stateaddressos').append(states);


    //         }, error: function (e: any)
    //         {
    //             console.log(e.message);
    //         }
    //     });
    // }

// function setAddressForm(address1: string, address2: string, city: string, state: string, zipcode: string, addresstype?: string){
//         $(".service-address-form #Address").val(address1);
//         $(".service-address-form #Address2").val(address2);
//         $(".service-address-form #City").val(city);
//         $('#stateaddressos option[value='+state+']').attr('selected','selected');
//         $(".service-address-form #Zip-Code").val(zipcode);
//         if (addresstype)
//             $('.service-address-form #AddressType').val(addresstype).trigger('change');


//         $("#service-address-next").removeClass("disble-btn");
//         $("#service-address-next").prop("disabled", false);
//     }

$(document).on("click", ".service-sign-in", function (){
    if ($(this).attr("id") == "signinredirect-os"){
        localStorage.setItem("contactInfo", "personalinfo");
        sessionStorage.setItem('objectGlobal',JSON.stringify(window.objOSGlobal.requestBodyValues));
        sessionStorage.setItem('paramUIValue',JSON.stringify(window.objOSGlobal.paramsUI));
        signIn();
    }
});
function setPersonalInfo(profileObj:any){
    let phoneNumber = profileObj.phone[0].contactDetail.toString().match(/(\d{3})(\d{3})(\d{4})/);
    $("#contact-information-screen input[name='firstname']").val(profileObj.firstName);
    $("#contact-information-screen input[name='lastname']").val(profileObj.lastName);
    $("#contact-information-screen input[name='email']").val(profileObj.email);
    if (phoneNumber){
        phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
    }
    if (profileObj.phone){
        $("#contact-information-screen input[name='phonenumber']").val(phoneNumber);
    }
    $("#contact-information-screen  .next").removeClass("disble-btn").prop("disabled", false);
}

function checkPersonalInfoData(){
    var fistName = $("#contact-information-screen input[name='firstname']").val();
    var lastName =  $("#contact-information-screen input[name='lastname']").val();
    var email = $("#contact-information-screen input[name='email']").val();
    var phoneNumber = $("#contact-information-screen input[name='phonenumber']").val();
    if(fistName!='' && lastName!=''&& email!='' && phoneNumber !=''){
       $("#contact-information-screen .next").removeClass("disble-btn").prop("disabled", false);
    }else{
       $("#contact-information-screen .next").addClass("disble-btn").prop("disabled", true);
    }
 }

export function waitForProfile(){
    let profileObj = JSON.parse(localStorage.getItem("profile"));
        if ("profile" in localStorage){
            $("#contact-information-screen .sub-heading.grey-clr").addClass("hidden");
            if ("contactInfo" in localStorage){
                let modifier = sessionStorage.getItem('ServiceModifierValue');
                if(modifier = 'Commercial'){
                    $('.progressbar a[data-step="about-home-screen"]').html('About <span>Your Office</span>');
                    $('#about-home-screen').children('h2').text('About Your Office');
                    $('#about-home label[for="inputbedrooms"]').text('Offices *')
                }else{
                    $('.progressbar a[data-step="about-home-screen"]').html('About <span>Your Home</span>');
                    $('#about-home-screen').children('h2').text('About Your Home');
                    $('#about-home label[for="inputbedrooms"]').text('Bedrooms *')
                }
                getState();
                $('#js-schedulerDialog').modal('show');
                $('.modal-header-one').hide();
                $('.progressbar, .progress').show();
                $('.scheduler-modal .modal-content').removeClass('banner-wizard');
                $('#contact-information-screen').addClass('active');
                setPersonalInfo(profileObj);
                var globalObjectVal = sessionStorage.getItem('objectGlobal');
                var paramUIValue = sessionStorage.getItem('paramUIValue');
                if(globalObjectVal){
                    window.objOSGlobal.requestBodyValues = JSON.parse(globalObjectVal);
                    window.objOSGlobal.paramsUI= JSON.parse(paramUIValue);
                    var code  = window.objOSGlobal.requestBodyValues.ConceptCalledId || window.objOSGlobal.requestBodyValues.conceptId
                    setProgressBarDisplay(code, window.objOSGlobal.requestBodyValues.IsLeadOnly);
                    $('.result-stack-logo').append('<img src="' + window.objOSGlobal.paramsUI.franchiseLogoPath + '" alt="" width="164" height="76" />');
                    $('#contact-information-screen').addClass('active');
                }
                sessionStorage.removeItem('objectGlobal');
                sessionStorage.removeItem('paramUIValue');
                localStorage.removeItem("contactInfo");
                getRefDetails();
            }
            else{
                setPersonalInfo(profileObj);
                checkPersonalInfoData();
            }
        }
}
$(document).ready(function ()
{

    $(document).on("click", ".request-call", function ()
    {
        window.objOSGlobal.requestBodyValues.Comments = window.objOSGlobal.requestBodyValues.Comments + "/n" + "The Customer has requested a callback";
        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
    });
});

(function ()
{
    $('#service-search-row').remove();

    var searchTerm = localStorage.getItem('zipcode');

    localStorage.removeItem('zipcode');
    localStorage.setItem('zipcode', searchTerm);

    var requestBody = {
        address: searchTerm
    };

    var marketID = $('.market-service-link').siblings().find('[name="MarketID"]').val();

    if ($(".market-service-link").length !== 0)
    {
        start_loader();
        {
            $.ajax({
                type: "GET",
                url: apiConfig.serviceMarketing +  marketID + '/service-pro-type-aggregates/search?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                data: JSON.stringify(requestBody),
                // headers: {
                // Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer_token')).access_token
                // },
                dataType: 'json',
                success: function (result, status, xhr)
                {
                    stop_loader();
                    if (result.length == 0)
                    {
                        $('#service-search-page').removeClass('active');
                        $('#no-search-result').addClass('active');
                        $('.noResultCatSpan').hide();
                        $('.noResultZipSpan').text(searchTerm);

                        $(".servicesLinksCont").hide();
                        $('.market-service-link').closest('.container').css('display', 'none');
                    }
                    else
                    {
                        var splitCount;
                        if (result.length < 3) splitCount = 1;
                        else splitCount = Math.round(result.length / 3);
                        // Split in group of 4 items

                        result = chunkArray(result, splitCount);
                        // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]

                        var colmd4Elements = $.map(result, function (el)
                        {
                            var sub_ul = $('<ul class="list-unstyled services-link-list"></ul>');
                            $.each(el, function (index, service)
                            {
                                var sub_li = $('<li class="marketservicesearchli"><a href="#">' + service.proTypeName + '</a>  </li>');
                                sub_ul.append(sub_li);
                            });
                            var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
                            return colmd4;
                        })

                        var rowClass = $('<div class="row service-search-row" id="service-search-row"><div> ').append(colmd4Elements);

                        $(".market-service-link").append(rowClass);

                    }

                },
                error: function (xhr, status, error)
                {
                    stop_loader();
                    if (xhr.responseText.includes('Market not found '))
                        $('.market-service-link').closest('.container').css('display', 'none');

                }
            });
        }

    }
})();

$.validator.addMethod("profileZipMethod", function(this:any,value, element) {
    if(profileVars.country == 'USA'){
        return this.optional( element ) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    }
    if(profileVars.country == 'CANADA'){
        return this.optional( element ) || /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value);
    }
}, 'Invalid ZIP/Postal Code');

$.validator.addMethod("addressZip", function(this:any,value, element) {
   
    var afterCom =window.location.href.split('.com')[1];
    var isWebSite =  afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    
    if (isWebSite == 'ca'){
        return this.optional( element ) || /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(value);
    }
    else{
        return this.optional( element ) || /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);
    }

}, 'Invalid ZIP/Postal Code');



$.validator.addMethod("email", function (this: any, value, element)
{
    return this.optional(element) || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
}, 'Please enter a valid email address');

$.validator.addMethod("noSpace", function(value, element) { 
    return value == '' || value.trim().length != 0;  
}, "Space not allowed");

$('.addressbumper #stateList').change(function ()
{
    //alert('sds')
    //set the Selected variable
    var selectedItem = $(this).children("option:selected").val()
    var address = $("#Address1").val();
    var city = $("#City").val();
    //list of IFs
    if (selectedItem != '' && address != '' && city != '')
    {
        //hide whole box
        $('.addressbumper #service-next').prop("disabled", false);
        $(".addressbumper #service-next").removeClass("disble-btn");
    } else
    {
        $('.addressbumper #service-next').prop("disabled", true);
        $(".addressbumper #service-next").addClass("disble-btn")
        $(this).prev().addClass('v-hidden');
    }
    if (selectedItem !== '')
    {
        //hide whole box
        $(this).prev().removeClass('v-hidden');
    } else
    {
        $(this).prev().addClass('v-hidden');
    }
});
$('.service-address-form #stateaddressos').off('change').on('change', function (){
    var selectedItem = $(this).children("option:selected").val()
    var address = $("#Address").val();
    var city = $("input[name='city']").val();
    if (selectedItem != '' && address != '' && city != ''){
        $('.service-address-form #service-address-next').prop("disabled", false);
        $(".service-address-form #service-address-next").removeClass("disble-btn");
    } else{
        $('.service-address-form #service-address-next').prop("disabled", true);
        $(".service-address-form #service-address-next").addClass("disble-btn")
    }
});