import {get_data} from "../util";
import {setAddressFormValue} from "./setAddressFormValue";
import {setAddressPrimaryForm} from "./setAddressPrimaryForm";
export function checkAddressFormValue(){
    let profileObj = JSON.parse(localStorage.getItem("profile"));
    var addresses= JSON.parse(localStorage.getItem("addressBumper")); 
    var postal = get_data('zipcode');
    if(postal){
      $('#service-address-screen #input-service-zipcode').val(postal);
    }
    if(profileObj){
        var primaryAddressFiltered = profileObj.accountAddresses.find((x:any) => x.primaryAddressID === 1); 
        if(addresses){
            setAddressFormValue(addresses);
        }else{
            setAddressPrimaryForm(primaryAddressFiltered);
        }
    }else{
        if(addresses){
            setAddressFormValue(addresses);
        }
    }
}