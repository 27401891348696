import { chunkArray, start_loader, stop_loader } from '../util';
import { $ } from "../third-party-imports"
import { apiConfig } from "../config/apiConfig";
import { getNblyApiHeaders } from '../api/getNblyApiHeaders';

export function servicesOfferedInit(serviceType: string): string {
    //const serviceType = $("[name='ServiceType']").val();
    var country = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") == "ca" ? "2" : "1";
    var urlString = "";
    if (serviceType == "brand") {
        var conceptId = $("[name='ServiceType'][value=brand]").next('input').val();
        if (conceptId)//check if accordion is present
            urlString = apiConfig.serviceAggregates + conceptId + `/service-web-category-aggregates/services-aggregates?apikey=${window.DEFINE_VALUE.JS_API_KEY}&countryId=${country}&iscore=true` + "&" + getNblyApiHeaders();
        //https://api.nblytest.com/serviceorchestrations-api-develop/v1/public/service-aggregates/search?apikey=tneiweb-L0WLDH5bGqSGWEy&concept_id=8
        else return;
    }
    else if (serviceType == "protype") {
        var protypeId = $("[name='ServiceType'][value=protype]").next('input').val();
        if (protypeId)//check if accordion is present
            urlString = apiConfig.serviceProtype + protypeId + `/service-web-category-aggregates/services-aggregates?apikey=${window.DEFINE_VALUE.JS_API_KEY}&countryId=${country}&iscore=true` + "&" + getNblyApiHeaders();
        else return;
    }
    // else if (serviceType == "nei") {
    //     var serviceId = $("[name='ServiceType'][value=nei]").next('input').val();
    //     if (serviceId) //check if accordion is present
    //         urlString = `${apiConfig.serviceByNEI}?apikey=${window.DEFINE_VALUE.JS_API_KEY}&iscore=true` + "&" + getNblyApiHeaders();
    //     else return;
    // }
    else return;
    //start_loader();
    $.ajax({
        type: "GET",
        url: urlString + "&" + getNblyApiHeaders(),
        dataType: 'json',
        success: function (result, status, xhr) {
            // stop_loader();
            // if (serviceType == "nei") {
            //     var obj = {};
            //     for (let item of result) {
            //         obj[item.serviceName] = item.serviceWebCategories
            //     }
            //     result = obj;
            // }
            if (serviceType == "brand") {
                var afterCom = window.location.href.split('.com')[1];
                var isWebSite = afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
                var obj = {};
                var serviceWebCategories = result[0].serviceWebCategories

                for (let item of serviceWebCategories) {
                    if (isWebSite == "ca" && item.countryID == "2")
                        obj[item.categoryName] = item?.services;
                    else if (isWebSite == "us" && item.countryID == "1")
                        obj[item.categoryName] = item?.services;

                    if (item.countryID == "All Countries" || item.countryID == null)
                        obj[item.categoryName] = item?.services;

                }
                result = obj;
            }
            else {
                var obj = {};
                result.forEach((element: any) => {
                    obj[element.serviceWebCategoryName] = element?.services;
                });
                result = obj;
                console.log('result', result);
            }

            var $ul = $("<ul></ul>");

            function getList(item: any, $ul: any) {
                $.each(item, function (key: any, value: any) {
                    var $li = $("<li />");
                    $li.append($('<a class="cspAnchorIssue">' + key + "</a>"));

                    var $subul = $("<ul class='sub_service'></ul>");

                    $.each(value, function (key, value) {
                        var category = serviceType == "brand" ? value.serviceName : value.serviceWebCategoryName;

                        if (serviceType == "protype")
                            category = value.serviceName;
                        $("<li/>")
                            .append(
                                $('<a class="cspAnchorIssue" >' + category + "</a>")
                            )
                            .appendTo($subul); // you need to append it to the $subul not the $subli
                    });

                    $li.append($subul).appendTo($ul); // you need to append the $li to the $list
                });
            }
            getList(result, $ul);

            $(`[name='ServiceType'][value=${serviceType}]`).closest("section").find('.serviceBox');

            // Split in group of 4 items 'var result = chunkArray(result, 4); '   // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
            var splitCount;
            if ($ul.children().length < 3) splitCount = 1;
            //else splitCount = Math.round($ul.children().length / 3);
            else splitCount = Math.ceil($ul.children().length / 3);
            // Split in group of 4 items

            result = chunkArray($ul.children(), splitCount);

            for (let i = 0; i <= 2; i++) {
                var liString = '';
                $.each(result[i], function () {
                    liString += this.outerHTML;
                });
                const servicesRow = document.createElement("div");
                servicesRow.classList.add("col-lg-4", "col-md-4");
                servicesRow.innerHTML = `<div class="serviceBox"><ul>${liString}</ul></div>`;

                const allLinks = Array.from(servicesRow.querySelectorAll(".serviceBox > ul > li > a"));
                // const allSubServices = Array.from(servicesRow.querySelectorAll('.sub_service'));

                allLinks.forEach(link => {
                    link.addEventListener("click", (e) => {
                        $('.serviceBox > ul > li > a').removeClass('active');
                        $('.serviceBox > ul > li').removeClass('active-item');
                        $('.sub_service').slideUp();

                        if ($(link).next('.sub_service').css('display') == 'block') {
                            $('.sub_service').slideUp();
                        }
                        else {
                            $(link).next('.sub_service').slideDown();
                            $(link).addClass('active');
                            $(link).parent().addClass('active-item');
                        }
                    })
                })
                const allSubServices = Array.from(servicesRow.querySelectorAll('.serviceBox > ul > li ul li a'));
                allSubServices.forEach(link => {
                    link.addEventListener("click", (e) => {
                        e.preventDefault();
                    })
                })
                $(`[name='ServiceType'][value=${serviceType}]`)
                    .closest("section")
                    .find('.row:nth-child(2) ')
                    .append(servicesRow);
            }
        },
        error: function (xhr, status, error) {
            //stop_loader();
            console.log(xhr, status, error);
        }
    });
}