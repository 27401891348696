import { apiConfig } from "../config";
import { getAllNotification } from "./getAllNotification";
import { getCountAllNotification } from "./getCountAllNotification";
import { getNblyMicroServiceApiHeaders } from '../api/getNblyMicroServiceApiHeaders';
import { $ } from "../third-party-imports"
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { start_loader, stop_loader } from "../util";

// delete notification 
export function deleteNotification(nblyNotificationId: any)
{
    if (nblyNotificationId)
    {
        // start_loader();
        $.ajax({
            url: apiConfig.notificationApi + 'Notification/' + nblyNotificationId+"?"+getNblyApiHeaders(),
            type: 'DELETE',
            headers: getNblyMicroServiceApiHeaders(),
            success: function (data, status)
            {
                // stop_loader();
                if (data == "success")
                {
                    $('.nbly_notification li').remove();
                    getAllNotification();
                    getCountAllNotification();
                }
            },
            error: function (error)
            {
                // stop_loader();
                console.log(error);
            }
        });
    }
}
