import { remove_data, serviceCategoryByName, get_data,chunkArray,store_data } from "../util";
import { $ } from "../third-party-imports"


export function serviceCategoryId(zipcode: any, serviceId: any, event: any)
{
    if(event) {
    event.preventDefault();
    }
    $('.bumperAddress2').addClass('label-active');

    store_data('servicecategorypage', 'servicecategorypage');
    remove_data('serviceID');
    if(serviceId.serviceWebCategoryId !=0){
        store_data(serviceId.serviceWebCategoryId, 'serviceID');
        serviceListDisplay(serviceId.categoryName);
    }else{
        store_data(serviceId?.services[0]?.serviceId, 'serviceID');
        serviceCategoryByName(zipcode, serviceId?.services[0]?.serviceId, serviceId?.services[0]?.serviceName,false);
    }
    // serviceCategoryByName(zipcode, serviceId, liText);
}

function serviceListDisplay(categoryName:any){
    var serviceResult = get_data('seriveCategoryList');
    var categoryId = get_data('serviceID');
    if(serviceResult){
        var serviceResultById = serviceResult.find((cat:any)=>cat.serviceWebCategoryId ==categoryId);
        serviceResult = serviceResultById?.services;
    }
    $("#service-cat-link-list #category-search-row").remove();
    $("#serviceProtypeLinkListFinal #category-search-row").remove();
    $('.protypeServiceCatSearch').text(categoryName);
     var serviceArr = serviceResult || [];
      var splitCount;
            if (serviceResult.length < 3)
                splitCount = 1;
            else
                splitCount = Math.round(serviceResult.length / 3);
            // Split in group of 4 items
            serviceResult = chunkArray(serviceArr, splitCount);
            // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
            var colmd4Elements = $.map(serviceResult, function (el)
            {
                var sub_ul = $('<ul class="list-unstyled services-link-list"></ul>  ');
                var zipcode = get_data('zipcode');
                $.each(el, function (index, service)
                {
                    let liEl = window.document.createElement("li");
                    liEl.id = `${service.serviceId}`;                    
                    liEl.addEventListener("click", (event: any) => {
                        // return serviceCategoryId(zipcode, service.serviceId, event);
                        return serviceCategoryByName(zipcode,  service.serviceId, service.serviceName,false);
                    });
                    liEl.innerHTML = `<a href="#" data-id="${service.serviceId}">${service.serviceName}</a>`;
                    var sub_li = $(liEl);
                    sub_ul.append(sub_li);
                });
                var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
                return colmd4;
            });

            var rowClass = $('<div class="row" id="category-search-row"> <div> ').append(colmd4Elements);
            $("#service-cat-link-list").append(rowClass);
           //$("#serviceProtypeLinkListFinal").append(rowClass);
}