import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { get_data } from "./get_data";
import { start_loader } from "./start_loader";
import { stop_loader } from "./stop_loader";
import { store_data } from "./store_data";
export function setFranchiseGlobalVariables(franchiseData: string, franchiseImg: string, callbackHandler:any){
    var conceptCalledID = franchiseData.split('##')[2];
    var serviceskuid = 0;
    var serviceid = get_data('serviceID');
    window.objOSGlobal.requestBodyValues.ConceptCalledId = conceptCalledID;
    window.objOSGlobal.requestBodyValues.conceptId = conceptCalledID;
    store_data(conceptCalledID, 'conceptID');

    window.objOSGlobal.requestBodyValues.WebLocationId = franchiseData.split('##')[0];
    store_data(franchiseData.split('##')[0], 'WebLocationId');

    window.objOSGlobal.requestBodyValues.FranchiseId = franchiseData.split('##')[3];
    
    window.objOSGlobal.paramsUI.franchiseLogoPath = franchiseImg;
    $('.result-stack-logo').empty();
    $('.result-stack-logo').append('<img src="' + franchiseImg + '" alt="" width="164" height="76" />');
    //$('.result-stack-logo img').attr('src', franchiseImg);
    store_data(franchiseImg, 'franchiseLogoPath');
     start_loader();
    $.ajax({
        url: apiConfig.fullAttribute + "?franchiseWeblocationId=" + window.objOSGlobal.requestBodyValues.WebLocationId + "&apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        type: "GET",
        success: function (data){
             stop_loader();
            var phoneNumber = data.mainPhoneNumber;
            if(phoneNumber){
                if(!(/^(\([0-9]{3}\)(\s)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phoneNumber))){
                    phoneNumber = phoneNumber.toString().match(/(\d{3})(\d{3})(\d{4})/);
                    phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
                    }
                    
                    $('.result-contact').attr('href', 'tel:' + phoneNumber);
                    $('.result-contact .results-phone-num').text(phoneNumber);
                    window.objOSGlobal.paramsUI.brandPhoneNumber = phoneNumber;
                    store_data(phoneNumber, 'brandPhoneNumber');
                    
                    $('#modal-header-contact').removeClass("hidden");
                }
                else{  
                    $('#modal-header-contact').addClass("hidden");
                }
            //}
        
            window.objOSGlobal.requestBodyValues.licenseNumber = data.contractNumber;
            if(conceptCalledID =='1') {
                window.objOSGlobal.requestBodyValues.optInForPOSScheduling = data.options.optInForPOSScheduling;
                window.objOSGlobal.requestBodyValues.optInForPOSScheduling2 = data.options.optInForPOSScheduling2;
            }
            var onlineScheduling = data.onlineScheduling;                
            window.objOSGlobal.requestBodyValues.IsLeadOnly = onlineScheduling == true ? false : true;
            

            window.objOSGlobal.paramsUI.doingBusinessAs = data.locationDoingBusinessAs;
            $('#schedulermodallabel').text(data.locationDoingBusinessAs);   
            store_data(data.locationDoingBusinessAs, 'doingBusinessAs');         
            
            window.objOSGlobal.requestBodyValues.ServiceID = serviceid;
            window.objOSGlobal.requestBodyValues.ConceptCode = data.brandCode;

            if(serviceskuid == 0){
                var services = data?.services?.filter((ser: any) => ser.serviceDescription == get_data('serviceName'));
                services.length > 0 && services.forEach((s:any) => {
                    if(s.serviceDescription == get_data('serviceName')){
                        serviceskuid = services?.serviceSkuId;
                        window.objOSGlobal.requestBodyValues.ServiceSkuId = services?.serviceSkuId;
                        // console.log('2. serviceskuid = ' + serviceskuid);
                    }
                });
            }
            $.ajax({
                    url: apiConfig.serviceAggregatesWeblocation + window.objOSGlobal.requestBodyValues.WebLocationId + '/service-aggregates/search?apikey=' + window.DEFINE_VALUE.JS_API_KEY + '&include_service_sku_id=true'+"&"+getNblyApiHeaders(),
                    dataType: 'json',
                    type: 'GET',
                    success: function (data: any)
                    {
                        // stop_loader();
                        var resultArray = data;
                        var filterServices = resultArray.filter((ser: any) => ser.serviceType == window.objOSGlobal.requestBodyValues.serviceModifier);
                        $.each(filterServices, function (index: any, resultArray: any)
                        {
                            if(resultArray.serviceId == serviceid){
                                serviceskuid = resultArray.serviceSkuId;     
                                window.objOSGlobal.requestBodyValues.ServiceSkuId = resultArray.serviceSkuId;                   
                                window.objOSGlobal.requestBodyValues.ConceptCode = resultArray.conceptCode;
                            }
                        });
                        callbackHandler();
                    }, error: function (e: any){
                        // stop_loader();
                        callbackHandler();
                        //console.log(e.message);
                    }
            });    
        },error:function(error){
            stop_loader();
        }
    });
    // start_loader();
  
    if(conceptCalledID == '8') {
        getBrandApplianceList();
    }

    if(franchiseImg.length || window.objOSGlobal.paramsUI.brandPhoneNumber.length){
        $('#js-schedulerDialog .modal-header-two').css('display', 'flex');
        $('#js-schedulerDialog .modal-header-one').hide();
        $('#js-schedulerDialog .modal-content').removeClass('banner-wizard');
    }
    else{
        $('#js-schedulerDialog .modal-header-two').css('display', 'none');
        $('#js-schedulerDialog .modal-content').addClass('banner-wizard');
        $('#js-schedulerDialog .modal-header-one').show();        
    }
}


function getBrandApplianceList(){
    var conceptid = window.objOSGlobal.requestBodyValues.ConceptCalledId;
    // start_loader();
    $.ajax({
        url: apiConfig.makeByConcept + conceptid + '/makes?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        dataType: 'json',
        type: 'GET',
        success: function (data: any)
        {
            // stop_loader();
            var manufacturerList = [] as any[];
            var resultArray = data;
            $.each(resultArray, function (index: any, resultArray: any)
            {
                manufacturerList.push('<option value="' + resultArray.makeId + '">' + resultArray.makeName + '</option>')
            });
            $('#brand-appliance').append(manufacturerList);            

        }, error: function (e: any)
        {
            // stop_loader();
            //console.log(e.message);
        }
    });
}