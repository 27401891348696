import { $ } from "../third-party-imports"
import { unlinkSocial } from "../auth-redirect";

export function unLinkingPopUp(email: any, type: any, decodeData: any)
{
    // if(decodeData.idp =='google.com' || decodeData.idp=='facebook.com' || decodeData.idp=='apple.com'){
    //     localStorage.setItem("loginWith" ,decodeData.idp);
    // }
    $('.linkCnt').click(function ()
    {
        localStorage.setItem('isPassUpdatedForLocalAcc', decodeData.isPassUpdatedForLocalAcc);
        $('.linkingPopWindow').hide();
        unlinkSocial(email, type, decodeData.sub);
    });
    $('.linkCancel').click(function ()
    {
        $('.linkingPopWindow').hide();
    });
}
