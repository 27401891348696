import type { LoaderOptions } from "@googlemaps/js-api-loader";

let mapsPromise: Promise<typeof google.maps>;   

export async function googleMaps(): Promise<typeof google.maps>
{
    if (mapsPromise)
        return mapsPromise;

    const loadFn = async () => {
        const loaderOptions: LoaderOptions = { apiKey: window["DEFINE_VALUE"].googleMapsApiKey };
        const { Loader } = await import ("@googlemaps/js-api-loader");
        const loader = new Loader(loaderOptions);
        await loader.load();
        return google.maps;
    }    

    mapsPromise = loadFn();
    return mapsPromise;
}