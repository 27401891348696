import { $ } from "../third-party-imports";
// import { getNblyLegacyApiHeaders } from "../api";
import { apiConfig } from "../config";
import { profileVars } from  ".";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";

export function getProfileState()
{
    var baseUrl = apiConfig.stateApi;
    var country = window.location.pathname.split('/')[1].replace(/[^a-zA-Z ]/g, "");
    country = country == "ca" ? "CA" : "US";
    //var countryCode= baseUrl.indexOf("nblytest.com") != -1 ? "USA" : "US";
    $.ajax({
        url:baseUrl + country +"?apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        dataType: 'json',
        type: 'GET',
        //headers: getNblyLegacyApiHeaders(),
        success: function (data, status)
        {
            var states: any[] = [];
            $.each(data, function( index, element ) {
              states.push('<option id="'+element.stateCode+'" value="'+element.stateName+'">'+element.stateName+'</option>')
              if(country == "CA"){
                profileVars.caStates.push(element.stateName)
              }
              else{
                profileVars.usStates.push(element.stateName)
              }
            });
            $('#profileState').append(states);
            // if(country == "CA"){
                country = country == "CA" ? "US" : "CA";
            $.ajax({
                url:baseUrl + country +"?apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                dataType: 'json',
                type: 'GET',
                //headers: getNblyLegacyApiHeaders(),
                success:function(data, status) {
                  states = [];
                    $.each(data, function( index, element ) {
                        states.push('<option id="'+element.stateCode+'" value="'+element.stateName+'">'+element.stateName+'</option>')
                        if(country == "CA"){
                            profileVars.caStates.push(element.stateName)
                          }
                          else{
                            profileVars.usStates.push(element.stateName)
                          }
                    });
                    $('#profileState').append(states);                    
                  },
                    error: function(error) {
                    console.log(error);
                }
            });
        // }
          },
          error: function(error) {
            console.log(error);
        }
    });
}

