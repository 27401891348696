import { phoneVerification } from "../profile";
import{waitForProfile} from "../form/formValidation";

export const userProfile = (data: any) =>
{
    const userNameElement = document.getElementById('userName');
    localStorage.setItem('profile', data);
    const obj = JSON.parse(data);
    const userName = obj.firstName;
    userNameElement.innerText = userName;
    phoneVerification();
    waitForProfile();
}
