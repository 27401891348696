import { isValidUsOrCanadaZip } from "../../util";

globalThis.document.addEventListener("scheduleNowCta-click", () => {
    const document = globalThis.document;
    document.body.classList.add("serviceHeaderFixed");
    // document.body.classList.add("modal-open");
    // document.body.style.paddingRight = "17px";
    // document.body.innerHTML += '<div class="modal-backdrop fade show"></div';

    const locatedPage = document.getElementById("located-page");
    locatedPage.classList.add("active");

    // const notValidZipCodeServiceEntry = document.getElementById("locationSearch-error");
    // notValidZipCodeServiceEntry.style.display = "none";

    const backButtons = document.querySelectorAll<HTMLElement>(".backButton");
    backButtons.forEach((b) => b.style.display = "none");

    const serviceEntryBtn = document.getElementById("service-entry-btn") as HTMLButtonElement;
    serviceEntryBtn.classList.add("disble-btn");
    serviceEntryBtn.disabled = true;

    const serviceEntryText = document.getElementById("serviceEntryText") as HTMLInputElement;
    let zipcode = serviceEntryText.value;
    
    if (zipcode && isValidUsOrCanadaZip(zipcode)) {
        serviceEntryBtn.classList.remove('disble-btn');
        serviceEntryBtn.disabled = false;
        serviceEntryBtn.classList.add('active-btn');
    }
    // const jsServiceTypeDialog = document.getElementById("js-schedulerDialog");
    // jsServiceTypeDialog.style.display = "block";
    // jsServiceTypeDialog.style.paddingRight = "17px";
    // jsServiceTypeDialog.classList.add("show");

    $('#js-schedulerDialog').modal('show');
    $("#located-page").addClass('active');

    
    window.scroll(0, 0);
})