$(function ()
{
	function createSessionID(){
	var sGuid = "";
	for (var i=0; i<32; i++){
		sGuid += Math.floor(Math.random()*0xF).toString(0xF);
	}
	return sGuid;
	}
	
	function createCorelationID() {
		try {
	let bytes = window.crypto.getRandomValues(new Uint8Array(32));
	const randomBytes = () => (bytes = bytes.slice(1)) && bytes[0];
	return (1e7 + -1e3 + -4e3 + -8e3 + -1e11).toString().replace(/[018]/g, (c:any) =>
		    (c ^ randomBytes() & 15 >> c / 4).toString(16)
		);
		}catch(e){
			//console.log(e);
		}
	}
	
	var sessionID = sessionStorage.getItem('sessionID');
	var corelationID = sessionStorage.getItem('corelationID');
	
	if(!sessionID){
	var createSession =  createSessionID();
	sessionStorage.setItem('sessionID',createSession);
	}
	
	if(!corelationID){
	var createCorelation =  createCorelationID();
	sessionStorage.setItem('corelationID',createCorelation);
	}
});

export {}