import { $ } from "../third-party-imports"
import { fileUploadState } from ".";
import { apiConfig } from "../config/apiConfig";
import { start_loader, stop_loader} from "../util";

$('.prefilled-path').hide();

    const generatePresignedURL = function (filename: any){
        return new Promise((resolve,reject)=>{
            $.ajax({
                url:apiConfig.digitalOrchestrationFileUploadApi+'?filename='+filename+'&apikey='+window.DEFINE_VALUE.JS_API_KEY,
                dataType: 'json',
                type: 'GET',
                success:function(data, status) {
                    resolve(data.presignedURL);
                },
                error: function(error) {
                    reject(error);
                }
            });
        })
            
    }


   function CreateGuid(){
        function _p8(s: any)
        {
            var p = (Math.random().toString(16) + "000000000").substr(2, 8);
            return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
        }
        return _p8(false) + _p8(true) + _p8(true) + _p8(false);
    }
    
    function makeS3Request(presignedURL:any, file:any) {
        return new Promise(function (resolve, reject) {
          let xhr = new XMLHttpRequest();
          xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
              resolve(xhr);
            } else {
              reject({
                status: this.status,
                statusText: xhr.statusText
              });
            }
          };
          let reader = new FileReader();
          xhr.open("PUT", presignedURL);
          reader.onload = function(evt) {
               xhr.send(evt.target.result);
          };
          reader.readAsArrayBuffer(file);
        });
      }

    export function s3upload(successHandler:any){
        window.objOSGlobal.requestBodyValues.FileUrls = [];
        if (!fileUploadState.isUploadedToS3){
            let imagesUploaded = $('[id=file-chooser-input]');
            let promiseAllArray:any=[];
            if (imagesUploaded){
                start_loader();
                    for (let i = 0; i < (imagesUploaded.length - 1); i++){
                        const guid =  CreateGuid();
                        let file = (imagesUploaded[i] as any).files[0];
                        let fileName = guid + '.' + file.name.split('.').pop();
                        window.objOSGlobal.requestBodyValues.FileUrls.push(fileName);
                        let presignedUrls = generatePresignedURL(fileName).then(
                            function(presignedurl:any){
                            if (presignedurl != null){
                                let blob = file.slice(0, file.size, file.type);
                                let newFile = new File([blob], fileName, {type: file.type});
                                return makeS3Request(presignedurl,newFile);
                            }
                        }).catch((error)=>{
                            console.log(error)
                        })
                        promiseAllArray.push(presignedUrls);
                    }
                    Promise.all(promiseAllArray).then(()=>{
                        stop_loader();
                        console.log("File upload Successfull");
                        successHandler();
                    },()=>{
                        stop_loader();
                        console.log("File upload Error");
                    });
            }
        }
    }