import { $ } from "../third-party-imports"
import { getNblyMicroServiceApiHeaders, getNblyApiHeaders } from "../api";
import { apiConfig } from "../config";
import { start_loader, stop_loader} from "../util";
export function preferredContactFrequency(profile: any)
{
    switch (profile.communicationModeId)
    {
        case 3:
            $(".prefered-option #phone").attr('checked', "true");
            break;
        case 1:
            $(".prefered-option #standardContact").attr('checked', "true");
            break;
        case 2:
            $(".prefered-option #EmailContact").attr('checked', "true");
            break;
    }
    $('.prefered-option :radio[name="filter"]').on("change", function ()
    {
        const radioVal = $(this).val();
        const payload = {
            "NeighborlyAccountId": 0, //profileVars.neighborlyAccountId,
            "IsOptInForText": true,
            "IsOptInForEmail": false,
            "isOptInForNotification": true,
            "CommunicationModeId": radioVal
        };
        /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
        $.ajax({
            url: apiConfig.profileApi + 'OptInPreferences'+"?"+getNblyApiHeaders(),
            data: JSON.stringify(payload),
            headers: getNblyMicroServiceApiHeaders(),
            type: "PUT",
            contentType: "application/json",
            success: function (data, success)
            {
                // stop_loader();
            },
            error: function (xhr, status, error)
            {
                // stop_loader();
                console.log(error);
            }
        });
    });
}
