class JwtParser {
    decodeToken(token: string)
    {
        if(token != null){
            let base64Url = token.split('.')[1];
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let jsonPayload = decodeURIComponent(
                atob(base64).split('').map((c) =>
                {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                }).join('')
            );

            return JSON.parse(jsonPayload);
        }
        else{
            return "";
        }
    }
}

export const jwtParser = new JwtParser();