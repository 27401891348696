//import { window_Size } from "./window_Size";
import { $ } from "../third-party-imports"

export const animationFunction = function ()
{
    var lastScrollTop = 0;
    var isScrolling = false;
    var totalPageSlide = 7;
    var maxHeight = ($(document).height() - $(window).height());
    var scrollDiff = Math.round(maxHeight / totalPageSlide) - 1;
    var homeSecClasses = ["slide0", "slide1", "slide2", "slide3", "slide4", "slide5", "slide6"];
    var homeSecClassesNoAn = ["noAn_slide0", "noAn_slide1", "noAn_slide2", "noAn_slide3", "noAn_slide4", "noAn_slide5", "noAn_slide6", "noAn_slide7"];
    var animTime = 1500;
    var currentPage = 1;
    var moveScrollDirection = 0;
    var hillRemoveTimeOut = 500;
    var isSkippedCalled = false;
    var isMainSectionVisible = false;
    //var secondLastPage = totalPageSlide - 1;
    var lastScrollFooter = 0;
    var isMobileTablet = false;
    var IsScrollDown = false;
    var isTopCalled = false;
    var userAgent = navigator.userAgent.toLowerCase();
    var isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);


    if (isTablet)
    {
        isMobileTablet = true;
        animTime = 1500;
    }

    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)))
    {
        isMobileTablet = true;
        animTime = 1500;
    }

    var defaultScrollHandler = function (this: any, e: any)
    {
        if (window.isStopAnimation)
        {
            return;
        }

        headerHide_on_animation();
        //media_css();

        moveScrollDirection = Math.round(currentPage * scrollDiff);

        if (isSkippedCalled)
        {
            isSkippedCalled = false;
            return;
        }
        var scrollTop = $(window).scrollTop();

        if (scrollTop > lastScrollTop)
        {
            IsScrollDown = true;
        } else
        {
            IsScrollDown = false;
            moveScrollDirection = Math.round((currentPage - 1) * scrollDiff);
            if (currentPage == 2 && isScrolling == false)
            {
                moveScrollDirection = 0;
            }
            //moveScrollDirection = ((currentPage-1) === 1) ? 0 : moveScrollDirection;
        }
        if (isTopCalled)
        {
            isTopCalled = false;
            return;
        }

        if (currentPage == totalPageSlide && !IsScrollDown && ((lastScrollFooter + scrollDiff) > $(this).scrollTop()))
        {
            currentPage = totalPageSlide
            isScrolling = false;
        }
        lastScrollTop = $(this).scrollTop();

        if (isScrolling)
        {
            if (isMainSectionVisible == false)
            {
                $(window).scrollTop(moveScrollDirection);
            }
            return;
        }

        isScrolling = true;

        // Page handle
        if (IsScrollDown)
        {
            hillRemoveTimeOut = 500;
            currentPage = currentPage + 1;
            if (totalPageSlide <= currentPage)
            {
                currentPage = totalPageSlide;
            }
        } else
        {
            hillRemoveTimeOut = 0
            currentPage = currentPage - 1;
            if (currentPage <= 0)
            {
                currentPage = 1;
            }
        }

        UIHandle(currentPage);

        $(window).off("scroll", defaultScrollHandler);
        $(window).on("scroll", tmpScrollHandler);

        if (currentPage == totalPageSlide)
        {
            isScrolling = true;
            return;
        }

        setTimeout(function ()
        {
            isScrolling = false;

            if (!isMobileTablet)
            {
                isReachedMaxLevel(currentPage);
            }
        }, animTime);

    };

    function headerHide_on_animation()
    {
        $('header').show();
        const homeSec = $(".home_sec");

        if (homeSec.length)
        {
            var top_of_element = homeSec.offset().top;
            var bottom_of_element = homeSec.offset().top + homeSec.outerHeight() + 36;
            var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
            var top_of_screen = $(window).scrollTop();
    
            if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element))
            {
                $('body').addClass('animation_hide')
            } else
            {
                $('body').removeClass('animation_hide')
            }
        }
    }

    var tmpScrollHandler = function (this: any)
    {
        lastScrollTop = $(this).scrollTop();
        // alert("in");
        $(window).off("scroll", tmpScrollHandler);
        $(window).on("scroll", defaultScrollHandler);
    };


    function homeSecAddClasses(currentPage: any)
    {
        if (isSkippedCalled)
        {
            let newClassToAdd = homeSecClassesNoAn[currentPage];
            if (newClassToAdd)
            {
                $('.home_sec').addClass(newClassToAdd);
            }
        }
        if (isTopCalled)
        {
            //isTopCalled = false;
            let newClassToAdd = homeSecClassesNoAn[0];
            if (newClassToAdd)
            {
                $('.home_sec').addClass(newClassToAdd);
            }
        }
        for (let i = 0; i < currentPage; i++)
        {
            let classToAdd = homeSecClasses[i];
            if (classToAdd)
            {
                $('.home_sec').addClass(classToAdd);
            }
        }
    };

    function homeSecRemoveClasses(currentPage: any)
    {
        let newClassToAdd = homeSecClassesNoAn[0];
        if (newClassToAdd)
        {
            $('.home_sec').removeClass(newClassToAdd);
        }
        for (let i = currentPage; i < homeSecClasses.length; i++)
        {
            let classToRemove = homeSecClasses[i];
            if (classToRemove)
            {
                $('.home_sec').removeClass(classToRemove);
            }
        }
        for (let i = 1; i < homeSecClassesNoAn.length; i++)
        {
            let classToRemove = homeSecClassesNoAn[i];
            if (classToRemove)
            {
                $('.home_sec').removeClass(classToRemove);
            }
        }
    };

    function UIHandle(currentPage: any)
    {
        homeSecRemoveClasses(currentPage);
        homeSecAddClasses(currentPage);

        isMainSectionVisible = false;
        switch (currentPage)
        {
            case 1:

                $('.home_sec').removeClass('slide1');
                $('.home_sec').removeClass('hill_remove');
                $('.home_sec').removeClass('neighLogo');
                $('header').addClass('xx');
                $('header').removeClass('yy');

                break;
            case 2:
                $('.slideNav ul li').removeClass('active');
                $('.slideNav ul li:eq(0)').addClass('active');
                $('.home_sec').removeClass('hill_remove');
                $('.home_sec').removeClass('neighLogo');
                $('header').addClass('yy');
                $('header').removeClass('xx');
                $('body').addClass('headerHide');
                break;
            case 3:
                $('.slideNav ul li').removeClass('active');
                $('.slideNav ul li:eq(1)').addClass('active');
                $('.home_sec').removeClass('hill_remove');
                $('.home_sec').removeClass('neighLogo');
                $('header').addClass('yy');
                $('header').removeClass('xx');
                break;
            case 4:
                $('.slideNav ul li').removeClass('active');
                $('.slideNav ul li:eq(2)').addClass('active');
                $('.home_sec').removeClass('neighLogo');
                $('header').addClass('yy');
                $('header').removeClass('xx');
                break;
            case 5:
                $('.slideNav ul li').removeClass('active');
                $('.slideNav ul li:eq(3)').addClass('active');
                setTimeout(function () { $('.home_sec').addClass('hill_remove'); }, hillRemoveTimeOut);
                $('header').addClass('yy');
                $('header').removeClass('xx');
                break;
            case 6:
                $('.slideNav ul li').removeClass('active');
                $('.slideNav ul li:eq(4)').addClass('active');
                $('.home_sec').addClass('neighLogo');
                $('header').addClass('yy');
                $('header').removeClass('xx');
                break;
            case 7:
                isMainSectionVisible = true;
                $('header').addClass('yy');
                $('header').removeClass('xx');
                break;
            default:
                $('.home_sec').removeClass('hill_remove');
                isMainSectionVisible = true;
        }
        if (!isTablet && !isMobileTablet)
        {
            if (currentPage === 1 && isTopCalled)
            {
                lastScrollTop = 0;
                isScrolling = false;
                isSkippedCalled = false;
                $(window).scrollTop(0);
            }
            else
            {
                moveScrollDirection = Math.round(currentPage * scrollDiff);
                lastScrollTop = moveScrollDirection;
                if (scrollDiff == moveScrollDirection)
                {
                    lastScrollTop = 0;
                    moveScrollDirection = 0;
                }
                $(window).scrollTop(moveScrollDirection);
            }
        }
    }

    function isReachedMaxLevel(this: any, currentPageTemp: any)
    {
        var maxRange = (maxHeight - scrollDiff);
        var minRange = scrollDiff;

        let tmpCurrentScrollPos = Math.round($(this).scrollTop());
        if (tmpCurrentScrollPos > maxRange)
        {
            currentPage = totalPageSlide;
            UIHandle(totalPageSlide);
            isScrolling = true;
            isMainSectionVisible = true;
        } 
        else if (tmpCurrentScrollPos < minRange)
        {
            if (currentPage > 1)
            {
                //UIHandle(currentPage);
            }
            else
            {
                currentPage = 1;
                currentPageTemp = 1;
                UIHandle(currentPage);
            }
        }
        return currentPageTemp;
    }


    $(window).on("scroll resize", defaultScrollHandler);

    $('#js-skipAnimation').on('click', function ()
    {
        GotoSlide(7);
        return false;
    });

    $('#js-topAnimation').on('click', function ()
    {
        isTopCalled = true;
        $('body').removeClass('animation_hide');
        $('body').removeClass('headerHide');
        GotoSlide(1);
        isScrolling = false;
        isSkippedCalled = false;
        return false;
    });

    $('#slide2Nav').on('click', function ()
    {
        GotoSlide(2);
        return false;
    });

    $('#slide3Nav').on('click', function ()
    {
        GotoSlide(3);
        return false;
    });

    $('#slide4Nav').on('click', function ()
    {
        GotoSlide(4);
        return false;
    });

    $('#slide5Nav').on('click', function ()
    {
        GotoSlide(5);
        return false;
    });

    $('#slide6Nav').on('click', function ()
    {
        GotoSlide(6);
        return false;
    });

    // function GotoNextSlide()
    // {
    //     isSkippedCalled = false;
    //     let skipSildeAnimation = currentPage + 1;
    //     currentPage = skipSildeAnimation;
    //     isScrolling = false;
    //     isSkippedCalled = true;
    //     UIHandle(skipSildeAnimation);
    //     setTimeout(function ()
    //     {
    //         isSkippedCalled = false;
    //     }, animTime);
    // }

    function GotoSlide(slideNumber: any)
    {
        currentPage = slideNumber;
        isSkippedCalled = true;
        UIHandle(slideNumber);
        setTimeout(function ()
        {
            isSkippedCalled = false;
        }, animTime);
    }
};
//Reenable if actually used in the future
//$(animationFunction);

$(window).on('load resize', function ()
{
    //window_Size();
    if(window.location.href.indexOf("dev") >= 0){
    $('.loader').hide();
    }
});

