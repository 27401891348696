import { $ } from "../third-party-imports"


export function clearVerifyOtpVerification()
{
    $('#vdigit-1').val('');
    $('#vdigit-2').val('');
    $('#vdigit-3').val('');
    $('#vdigit-4').val('');
    $('#vdigit-5').val('');
    $('#vdigit-6').val('');
}
