import { get_data, store_data, start_loader, stop_loader } from ".";
import { storeProTypeID } from "../services";
import { chunkArray } from "./chunkArray";
import { $ } from "../third-party-imports"
import { apiConfig } from "../config/apiConfig";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";


export function getPropTypes(searchTerm: any,serviceModifier:any)
{
    // var requestBody = {
    //     address: searchTerm
    // };
    //$('.service-search-row').remove();
    $('#service-search-page #service-search-row').remove(); 
    var country = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") == "ca" ? "2" : "1";
    start_loader();
    $.ajax({
        type: "GET",
        // url: apiConfig.serviceProtypeAggregates + '?address="'+searchTerm+'"&apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        url: apiConfig.serviceProtypeAggregates + '?ZipCode='+searchTerm+"&countryid="+country+'&ServiceModifier='+serviceModifier+'&take=12&apikey=' + window.DEFINE_VALUE.JS_API_KEY+'&'+getNblyApiHeaders(),
        
        dataType: 'json',
        success: function (result, status, xhr)
        {
            stop_loader();
            if (result.length == 0){
                 if(sessionStorage.getItem('ServiceModifierValue')){
                    return;
                 }
                 $('#service-search-page').removeClass('active');
				 $('#no-search-result').addClass('active');
				 $('.noResultCatSpan').hide();
				 $('.noResultZipSpan').text(searchTerm);
            }
            else
            {
                if (window.location.href.indexOf("confirmation") > -1) {
                    if($('.scheduler-modal').is(':visible')){
                        $('#service-search-page').addClass('active');
                    }
                }else{
                $('#service-search-page').addClass('active');
                }
                var splitCount;
                if (result.length < 3)
                    splitCount = 1;
                else
                    splitCount = Math.round(result.length / 3);
                result = chunkArray(result, splitCount);
                var colmd4Elements = $.map(result, function (el)
                {
                    var sub_ul = $('<ul class="list-unstyled services-link-list"></ul>  ');
                    $.each(el, function (index, service)
                    {
                        let liEl = window.document.createElement("li");
                        liEl.id = `serviceProLi${service.proTypeId}`;
                        liEl.addEventListener("click", (event: any) => {
                            return storeProTypeID(service.proTypeId, event)
                        });
                        liEl.innerHTML = `<a href="#">${service.proTypeName}</a>`;
                        var sub_li = $(liEl);
                        sub_ul.append(sub_li);
                    });
                    var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
                    return colmd4;
                });

                var rowClass = $('<div class="row service-search-row" id="service-search-row"> <div> ').append(colmd4Elements);
                var serviceSearchActive = $("#service-search-page").hasClass("active");
                if(serviceSearchActive)
                    $("#service-protype-link-list").append(rowClass);
                else
                    $("#serviceProtypeLinkListFinal").append(rowClass);
            }
        },
        error: function (data, status, error)
        {
            stop_loader();
            if (data.status === 404)
            {
                $('#service-search-page').removeClass('active');
                $('#no-search-result').addClass('active');
                $('.noResultZipSpan').text(get_data('zipcode'));
                $('.noResultCatSpan').show();
                store_data('located-page', 'locatedPage');
            }
        }
    });
}
