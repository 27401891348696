export function setServiceAddressOsVariables(){
    
    var address1 = $('#service-address-screen input[name="address"]').val();
    var apartment = $('#service-address-screen input[name="address2"]').val();
    var city = $('#service-address-screen input[name="city"]').val();
    var zipcode = $('#service-address-screen input[name="zipcode"]').val();
    var state = $('#service-address-screen select[name="state"]').children("option:selected").val();
    //apartment = apartment ? " "+ apartment : "";

    window.objOSGlobal.requestBodyValues.Address = address1;
    window.objOSGlobal.requestBodyValues.Address2 = apartment;
    window.objOSGlobal.requestBodyValues.City = city;
    window.objOSGlobal.requestBodyValues.State = state;
    window.objOSGlobal.requestBodyValues.PostalCode = zipcode;
}