import { $ } from "../third-party-imports"



export function profileZipCodeValidate()
{
    $("#profileZipCode").on('input', function ()
    {
        var profileZipCode = $('#editAddress #profileZipCode').val() as string;
        var zipRegex = new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/);
        if (profileZipCode !== '' && zipRegex.test(profileZipCode))
        {
            $('#profileAddressBtn').removeClass('disble-btn');
            $('#profileAddressBtn').prop("disabled", false);
        } else if (profileZipCode === '')
        {
            $('#profileAddressBtn').addClass('disble-btn');
            $('#profileAddressBtn').prop("disabled", true);
        } else if (!zipRegex.test(profileZipCode))
        {
            $('#profileAddressBtn').addClass('disble-btn');
            $('#profileAddressBtn').prop("disabled", true);
        }
    });
}
