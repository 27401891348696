import { jwtParser } from '../services';

class NeiLocalStorage 
{
    get accessToken() { return sessionStorage.getItem('msal.idtoken'); }
    set accessToken(val: string) { sessionStorage.setItem('msal.idtoken', val); }

    get decodedAccessToken() { return jwtParser.decodeToken(this.accessToken); }

    // get bearerToken() { return JSON.parse(localStorage.getItem('bearer_token')).access_token; }
}

export const neiLocalStorage = new NeiLocalStorage();