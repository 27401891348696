export function getFranchiseWebServices(webLocationId:any, serviceHandler:any){
    var serviceName = window.objOSGlobal.requestBodyValues.serviceName;
    var url = 'https://api.nblytest.com/serviceorchestrations-api-develop/v1/public/service/config/menu/franchise_web_location_id/' + webLocationId + '?apikey='+window.DEFINE_VALUE.JS_API_KEY;
    $.ajax({
        type: "GET",
        url: url,
        contentType: "application/json",
        dataType: 'json',
        success: serviceHandler,
        error: function (error)
        {
            $('.progressbar, .progress').hide();
            $('#js-schedulerDialog .modal-header-two').css('display', 'none');
            $('#js-schedulerDialog .modal-header-one').show();
            $('#no-search-result .head-title').text(`We're sorry. We don't currently provide ${serviceName} at this location`);
            $('#service-address-screen').removeClass('active');
            $('#no-search-result').addClass('active');
        }
    });
}
