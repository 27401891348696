import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { start_loader } from "./start_loader";
import { stop_loader } from "./stop_loader";

export function diagnoseFeeMRA(){
    var weblocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
        // $('.diagnoseFee').removeClass("hidden");        
         start_loader();
        $.ajax({
            url: apiConfig.franchiseWebLocationProfileFees + weblocationId + '?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
            dataType: 'json',
            type: 'GET',
            success: function (data: any)
            {
                stop_loader();
                var resultArray = data;
                $.each(resultArray, function (index: any, resultArray: any)
                {
                    if (resultArray.name == "Diagnostic Fee")
                        $('.dfee').text(resultArray.defaultFee);
                });

            }, error: function (e: any)
            {
                stop_loader();
                //console.log(e.message);
            }
        });
}