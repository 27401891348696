export function setVehicleInformationVariables() {
    window.objOSGlobal.requestBodyValues.Year = Number($("#vehicle-information #year").val());
    window.objOSGlobal.requestBodyValues.Make = $("#vehicle-information #make").val();
    window.objOSGlobal.requestBodyValues.Model = $("#vehicle-information #model").val();
    window.objOSGlobal.requestBodyValues.BodyStyle = $("#vehicle-information #body-style").val();
    window.objOSGlobal.requestBodyValues.VIN = $("#vehicle-information #vin").val();
    window.objOSGlobal.requestBodyValues.LicensePlate = $("#vehicle-information #licenseplate").val();

    window.objOSGlobal.requestBodyValues.WindshieldRepair = $('#vehicle-information-2 #windshieldrepair').is(':checked') ? "Yes" : "No";
    window.objOSGlobal.requestBodyValues.HeadlightRestoration = $('#headlightrestoration').is(':checked') ? "Yes" : "No";
    var selectedReplaces = "";
    $('.replace-needs input:checked').each(function ()
    {
        selectedReplaces = selectedReplaces != "" ? selectedReplaces + ", " + $(this).attr('name') : $(this).attr('name');
    });
    window.objOSGlobal.requestBodyValues.ServiceRequested = selectedReplaces;


    window.objOSGlobal.requestBodyValues.Comments = window.objOSGlobal.requestBodyValues.Comments + "\n" +
    $('#vi-type-of-service').text() + ":" + $('input[name="servicetype"]:checked').val();

    if ($('input[name="servicetype"]:checked').attr("id") == "mobileservice")
    {
        window.objOSGlobal.requestBodyValues.Comments = window.objOSGlobal.requestBodyValues.Comments + "\n" +
            "Street Address : " + $('#street-address').val() + "\n" +
            "ZIP Code : " + + $('#mobileservicezipcode').val();
    }
    window.objOSGlobal.requestBodyValues.InsuranceCompany = $('#insurance-company').val();
    window.objOSGlobal.requestBodyValues.DeductibleAmount = Number($('#deductible-amount').val());
    window.objOSGlobal.requestBodyValues.PolicyNumber = $('#policy-number').val();
    window.objOSGlobal.requestBodyValues.FormOfPayment = $('input[name="paymentmethod"]').val();

    window.objOSGlobal.requestBodyValues.NeighborlyLeadSourceId = $('#vehicle-information-3 select[name="howdidyouhear"] option:selected').val();
}