// import { printDiv } from "./printDiv";
import { $ } from "../third-party-imports"

$(function ()
{
     $(".print-btn").click(function (this:any, e:any)
    {
        var contents = $('<div>').append($(this).parent().closest("section").clone()).html();
    var frame1 = $<HTMLIFrameElement>('<iframe />');
    frame1[0].name = "frame1";
    frame1.css({ "position": "absolute", "width": "100%", "size": "A4 landscape", "margin": "10%" });

    $("body").append(frame1);
    var frameDoc = frame1[0].contentWindow ? frame1[0].contentWindow : frame1[0].contentDocument["document"] ? frame1[0].contentDocument["document"] : frame1[0].contentDocument;
    frame1[0].contentDocument;
    frameDoc.document.open();
    //Create a new HTML document.
    frameDoc.document.write('<html><head><title></title>');
    frameDoc.document.write(`
        <link rel="preload" href="/us/en-us/_assets/css/nei-v1.css" as="style">
        <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" type="text/css" />
        <link href="/us/en-us/_assets/css/nei-v1.css" rel="stylesheet" type="text/css" />
        <style>
        .print-btn {
            display: none !important;
        }
        /*--------------------For Print Default Setting--------------------*/
        @page {
            margin:0mm;
          }
          * {
            -webkit-print-color-adjust: exact !important;   /* Chrome, Safari */
            color-adjust: exact !important;                 /*Firefox*/
        }
        @media print { 
            @-moz-document url-prefix() {
        
             .print-btn {
                display: none !important;
             }
                }
        }
        </style>
        `);
    frameDoc.document.write('</head><body>');
    //Append the external CSS file.
    // frameDoc.document.write('<link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" rel="stylesheet" type="text/css" />');
    // frameDoc.document.write('<link href="/us/en-us/_assets/css/printContent.css" rel="stylesheet" type="text/css" />');
    //Append the DIV contents.
    contents = contents.replace(/loading="lazy"/g, "");
    frameDoc.document.write(contents);
    frameDoc.document.write('</body></html>');
    setTimeout(function ()
    {
       frameDoc.document.close(); // necessary for IE >= 10
                frameDoc.focus(); // necessary for IE >= 10
                frameDoc.print();
                frame1.remove();
                frameDoc.document.close();
    }, 250);
    });
});