import { remove_data, store_data } from "../util";
import { $ } from "../third-party-imports"

export function storeServiceId(serviceWebCategoryID: number, serviceid: number)
{
    var liText = document.getElementById(`${serviceid}`).innerText;
    $('input[name="serviceListQuery"]').val(liText);

    remove_data('serviceID');

    store_data(serviceid, 'serviceID');

    remove_data('serviceWebCategoryID');
    store_data(serviceWebCategoryID, 'serviceWebCategoryID');

    $(".serviceCategoryBtn").prop('disabled', false);
}
