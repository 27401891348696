import { headerFixed } from "./headerFixed";
import { media_css } from "./media_css";
import { windSize } from "./windSize";
import { $ } from "../third-party-imports"

//User-profile-slide
$(document).ready(function ()
{
    $('.editLink').on('click', function ()
    {
        $(this).closest('.form_container_wrapperBox').removeClass('active');
        var dataAttr = $(this).attr('data-attr');

        $('#' + dataAttr).addClass('active');


        $(this).closest('.form_container_wrapperBox').animate({ left: '-100%' }, 100);


        setTimeout(function ()
        {
            $('html, body').animate({ scrollTop: $('#' + dataAttr).offset().top - 250 }, 200);
        }, 200)
    })
    $('.backButton').on('click', function ()
    {
        $(this).closest('.form_container_wrapperBox').removeClass('active');
        $('.form_container_wrapperBox').removeAttr('style');
    })

    /**TopMost blue row closed **/
    // $('.close_icn').on('click', function ()
    // {
    //     $('.notify_sec').hide();
    //     $('body').removeClass('notify_remove');
    // })

    /*covid-banner-Bug Fix-47969*/ 
  var checkCookie = sessionStorage.getItem('covidBanner');
  if (checkCookie != "1") 
  { if ($(".notify_sec").length > 0) {
    $(".notify_sec").show();
  }   }

  $('.notify_sec .close_icn').click(function() {
    sessionStorage.setItem('covidBanner', "1");
  $(this).closest('.notify_sec').hide();
  });
    /*covid-banner*/

});

$(window).on('load resize scroll', function ()
{
    windSize();

    var currentSlide = $('.servive-form-wrapper div.active').attr('id');

    var footers = document.getElementsByTagName('footer');
    if (footers.length && !currentSlide)
    {
        headerFixed();
    }
    media_css();
    $('.loader').hide();
});



// $(window).on('scroll', function(){
// 	windSize();
// 	headerFixed();
// })
/**Topmenu code copy in mobile--end*/

//Onscroll header hide


// $('input').on('keyup change', function (this: any)
// {

//     var char = $(this).val() as string;
//     var charLength = char.length;
//     $('a').removeClass('disble-btn')

//     if (charLength == 0)
//     {
//         $('.btn').removeClass('active-btn')
//         $('.searchEnterIcon').removeClass('active-input');
//         $('.next-btn').removeClass('opacity-5');
//         $('.next-btn').prop("disabled", true);

//     }
//     else if (charLength > 0)
//     {
//         $('.btn').addClass('active-btn')
//         $('.searchEnterIcon').addClass('active-input');
//         $('.next-btn').addClass('opacity-5');
//         $('.next-btn').prop("disabled", false);
//     }
//     //var len = $('#password').val().length
//     // if(len >= 8){
//     // 	$('.error').addClass('hidden2')
//     // }else{
//     // 	$('.error').removeClass('hidden2')
//     // }
//     //var type = $(this).attr('type');


// });

// dropdown js start here
$(document).on('click', function (event)
{
    if (!$(event.target).closest('.dropdown-menu, .neighbor-dropdown').length)
    {
        //alert('Hi')
        $('.dropdown-menu').hide();
        $('.signout').hide();
        $('.dropdown-nbr').removeClass('rotate-arrow');
        $('.neighbor-dropdown').hide();
        $('body').removeClass("scrollHide");
        // ... clicked on the 'body', but not inside of #menutop
    }
    if((event.target as any).id == "service-list-query")
       $('.js-servicesListDropdown').show();
    else if((event.target as any).id !== "service-list-query")
       $('.js-servicesListDropdown').hide();

    $('.js-currentLocationBox').hide();


});

$(document).ready(function ()
{
    $('.dropdown-nbr').on('click', function ()
    {
        $(this).toggleClass('rotate-arrow');
        $('body').toggleClass("scrollHide");
        $('.user-dropdwon').toggleClass('hidden');
        if (!$(this).hasClass('rotate-arrow'))
        {
            $('.neighbor-dropdown.user-dropdwon').hide();
            $('.signout').hide();
            $('.neighbor-dropdown').hide()
        } else
        {
            $('.neighbor-dropdown.user-dropdwon').show();
        }
    });
    $('.notify').on('click', function ()
    {
        $('body').addClass("scrollHide");
        $('.neighbor-dropdown').show();
        $('.neighbor-dropdown.user-dropdwon').hide();
    });

    $('.back-btn').on('click', function ()
    {
        $('.neighbor-dropdown').hide();
        $('.neighbor-dropdown.user-dropdwon').show();
    });

    $('.logout').on('click', function ()
    {
        $('.signout').show();
        $('.neighbor-dropdown.user-dropdwon').hide();
    });

    $('.eye').on('click', function ()
    {
        $(this).toggleClass('visible-eye');
        var x = document.getElementById("password") as HTMLInputElement;
        if (x.type === "password")
        {
            x.type = "text";
        } else
        {
            x.type = "password";
        }
    });
});

$(document).ready(function ()
{
    $('.toggle-disabled').addClass('disble-btn')
})

$(document).on('change keyup', '.required', function (e)
{


    //console.log(EmailVal)
    let Disabled = true;
    $(".required").each(function (this: any)
    {
        let value = this.value
        if ((value) && (value.trim() != ''))
        {
            Disabled = false
        } else
        {
            Disabled = true
            return false
        }
    });


    if (Disabled)
    {
        //$('.toggle-disabled').prop("disabled", true);
        //$('.toggle-disabled').addClass('disble-btn')

    } else
    {
        $('.toggle-disabled').prop("disabled", false);
        $('.toggle-disabled').removeClass('disble-btn')
    }
    var EmailVal = $('#inputEmail').val() as string
    if (EmailVal)
    {
        if (EmailVal.length > 0)
        {
            $('#inputEmail-error').next().addClass('label-top')
        } else
        {
            $('#inputEmail-error').next().removeClass('label-top')
        }
    }
})