import { $ } from "../third-party-imports"

export function setFieldsValue(value: any)
{
    var state = $("#profileState").children("option#" + value.state)[0];
    $('#profileAddress').val(value.addressLine1);
    $("#profileAddress2").val(value.addressLine2);
    $("#profileCity").val(value.city);
    $("#profileZipCode").val(value.zip);
    $("#profileState").val($(state).attr('value'));
    $("#profileType").val(value.accountAddressTypeID);
    $("#customCheck-nbAddress").prop("checked", value.primaryAddressID == 0 ? false : true);
    $('#profileAddressBtn').attr('data-edit', value.neighborlyAccountAddressId);

}
