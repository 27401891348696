import {aboutDataStoreInLocal} from './aboutDataStoreInLocal';
import {setVehicleInformationVariables} from './setVehicleInformationVariables';
import {getRefDetails} from "../util/util";
import {getAboutHomeValues} from './getAboutHomeValues';
import { refreshCalendarData,schedulerState } from "../calendar";
import {warrantySearchScreen} from './services';
import {setProgressBarDisplay} from './setProgressBarDisplay';
import {store_data,get_data} from '../util';
import {phoneFormatterScheduler} from './phoneFormatterScheduler';
import {mollyMaidEstimate} from './mollyMaidEstimate';
import {checkAddressFormValue} from './checkAddressFormValue';
import {getFranchiseWeblocation} from './getFranchiseWeblocation';
import { setServiceAddressOsVariables} from './setServiceAddressOsVariables';
import {setServiceContactInfoOsVariables} from './setServiceContactInfoOsVariables'
import {setFranchiseGlobalVariables} from '../util/setFranchiseGlobalVariables'
import {diagnoseFeeMRA} from '../util/diagnoseFeeMRA';
import {setDynamicMessages} from '../util/setDynamicMessages';

export function schedulerNextButtonFlow(currentElement:any){
    window.objOSGlobal.requestBodyValues.PostalCode = window.objOSGlobal.requestBodyValues.PostalCode != "" ? window.objOSGlobal.requestBodyValues.PostalCode : get_data('zipcode');
    var pane = $(currentElement).closest('.tab-pane').attr('id');
    window.objOSGlobal.requestBodyValues.ConceptCalledId = window.objOSGlobal.requestBodyValues.ConceptCalledId.indexOf('"') >= 0 ? JSON.parse(window.objOSGlobal.requestBodyValues.ConceptCalledId) : window.objOSGlobal.requestBodyValues.ConceptCalledId; 
    window.objOSGlobal.requestBodyValues.ConceptCode = window.objOSGlobal.requestBodyValues.ConceptCode.indexOf('"') >= 0 ? JSON.parse(window.objOSGlobal.requestBodyValues.ConceptCode) : window.objOSGlobal.requestBodyValues.ConceptCode;
    var code = window.objOSGlobal.requestBodyValues.ConceptCalledId || window.objOSGlobal.requestBodyValues.ConceptCode;
    var concepId = $(currentElement).parent().attr('data-concept');
    var isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
    let serviceModifier = sessionStorage.getItem('ServiceModifierValue');    
    $('#' + pane).removeClass('active');
    $('.progressbar').removeClass('warranty-checkbox-flow');
    if(concepId == "MLY"  || concepId == '1'){
        $('.progressbar, .progress').hide();
    }else{
        $('.progressbar, .progress').show();
    }
    $('#service-address-screen input[name="zipcode"]').val(window.objOSGlobal.requestBodyValues.PostalCode);
    $('#js-schedulerDialog .modal-header-two .result-stack-logo, #js-schedulerDialog .modal-header-two #modal-header-contact').removeClass('hidden');
    switch (pane) {
        case "no-search-result":
            $('#js-schedulerDialog').modal('hide');
            $('.tab-pane.fieldset').removeClass('active');
            $('.reqAsstBtn').removeClass('prevent-click');
            $("input[name='serviceListQuery']").val("");
            break;
        case "service-slide-4":
            if(concepId =='MLY'){
                let brandData = window.brandList.find(itm => itm.code == 'MLY');
                sessionStorage.setItem('brandObj',JSON.stringify(brandData));
            }
            $("#schedulermodallabel").removeClass('hidden');
            window.objOSGlobal.requestBodyValues.Note = '';
            var franchiseData = $(currentElement).attr('data-franchise');
            var franchiseImg = $(currentElement).parents('.result-stack-display').find('.result-info-logo-img img').attr('src');     
            setFranchiseGlobalVariables(franchiseData, franchiseImg, function() {
                code = window.objOSGlobal.requestBodyValues.ConceptCalledId; //window.objOSGlobal.requestBodyValues.ConceptCode;
                isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
                if (code == "MRA" || code == '8') {
                    $('#schedulerWizard #warranty-screen').addClass('active');
                    $('.progressbar, .progress').hide();
                    warrantySearchScreen(window.objOSGlobal.requestBodyValues.ServiceSkuId);
                }
                else if (code == "MLY"  || code == '1') {
                    if(serviceModifier =='Commercial'){
                        $('#schedulerWizard #about-home-screen').addClass('active');
                        $('.progressbar a[data-step="about-home-screen"]').html('About <span>Your Office</span>');
                        $('#about-home-screen').children('h2').text('About Your Office');
                        $('#about-home label[for="inputbedrooms"]').text('Offices *');
                        $('.progressbar, .progress').show();
                        window.objOSGlobal.requestBodyValues.IsLeadOnly = true;
                        isLeadOnly = true;
                        getRefDetails();
                    }else{
                        $('.progressbar a[data-step="about-home-screen"]').html('About <span>Your Home</span>');
                        $('#about-home-screen').children('h2').text('About Your Home');
                        $('#about-home label[for="inputbedrooms"]').text('Bedrooms *');
                        $('#schedulerWizard #frequency-selection-screen').addClass('active');
                    }
                }
                else if(isLeadOnly && code != "MLY"){
                    $('.progressbar, .progress').show();
                    $('.progressbar a[data-step="calendar-screen"]').parent().hide();
                    $('#schedulerWizard #contact-information-screen').addClass('active');
                    $('#js-schedulerDialog .modal-header-two .result-stack-logo, #js-schedulerDialog .modal-header-two #modal-header-contact').removeClass('hidden');
                }
                else{
                    $('.progressbar, .progress').show();
                    schedulerState.numberOfSlot = 1;
                    refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
                    $('.progressbar a[data-step="calendar-screen"]').parent().show();
                    $('#schedulerWizard #calendar-screen').addClass('active');
                    $('#js-schedulerDialog .modal-header-two .result-stack-logo, #js-schedulerDialog .modal-header-two #modal-header-contact').removeClass('hidden');
                }
                setProgressBarDisplay(code, isLeadOnly);
                setDynamicMessages(isLeadOnly);
            });
            break;
        case "warranty-screen":
            if($('#warranty').prop('checked')){
                $('.progressbar, .progress').show();
                $('#schedulerWizard #contact-information-screen').addClass('active');
                phoneFormatterScheduler('[name="phonenumber"]');                
                window.objOSGlobal.requestBodyValues.Warranty = true;
                isLeadOnly = true;
                $('.progressbar').addClass('warranty-checkbox-flow');
            }
            else{
                if(isLeadOnly){
                    $('#schedulerWizard #contact-information-screen').addClass('active');
                    $('.progressbar a[data-step="calendar-screen"]').parent().hide();
                }else{
                    schedulerState.numberOfSlot = 1;
                    refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
                    $('#schedulerWizard #calendar-screen').addClass('active');
                    window.objOSGlobal.requestBodyValues.Warranty = false;
                }
            }
            setDynamicMessages(isLeadOnly);
            break;
        case "frequency-selection-screen":
        if($('#inputonetime').prop('checked')){
            $('.result-stack-logo').removeClass('hidden');
            store_data('onetime', 'selectedAboutHome');
            window.objOSGlobal.requestBodyValues.IsLeadOnly = !window.objOSGlobal.requestBodyValues.optInForPOSScheduling2;   
            sessionStorage.setItem('MlyCleaning',$('input[name="chkcleaning"]:checked').siblings('.radio-txt').text());             
        }else{
            store_data('recurring', 'selectedAboutHome');
            window.objOSGlobal.requestBodyValues.IsLeadOnly = !window.objOSGlobal.requestBodyValues.optInForPOSScheduling;
            sessionStorage.setItem('MlyFrequency',$('input[name="chkfrequencymethod"]:checked').siblings('.radio-txt').text());            
        }
        isLeadOnly = window.objOSGlobal.requestBodyValues.IsLeadOnly;
                    
        if(isLeadOnly){
            $('.progressbar, .progress').show();
            $('#schedulerWizard #about-home-screen').addClass('active');
            getRefDetails();
        }else{
            var brandJsonData:any = JSON.parse(sessionStorage.getItem("brandObj"));
            var selectedAboutHome = get_data("selectedAboutHome");
            $('.progressbar, .progress').show();
            schedulerState.numberOfSlot = 1;
            refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
            if(selectedAboutHome =='onetime'){
                $('#calendar-screen > h2.fieldset-title').text(brandJsonData.schedule_title_onetime);
                $('#calendar-screen > p.sub-heading').text(brandJsonData.schedule_subtitle_onetime);
            }else{
                $('#calendar-screen > h2.fieldset-title').text(brandJsonData.schedule_title_recurring);
                $('#calendar-screen > p.sub-heading').text(brandJsonData.schedule_subtitle_recurring);
            }
            $('#schedulerWizard #calendar-screen').addClass('active');
        }
        var selectAbtHome = get_data('selectedAboutHome');
        var mlyCleaning = sessionStorage.getItem('MlyCleaning');
        if(selectAbtHome =="onetime"){
            if(mlyCleaning =="Yes"){
                window.objOSGlobal.requestBodyValues.Note ='MLY Frequency: One Time, Move in or Move out clean'
            }else{
                window.objOSGlobal.requestBodyValues.Note ='MLY Frequency: One Time, Not a Move in or Move out clean'
            }
        }
        if(selectAbtHome =="recurring"){
            var mlyFrequency = sessionStorage.getItem('MlyFrequency');
            window.objOSGlobal.requestBodyValues.Note = 'MLY Frequency: Recurring, '+mlyFrequency; 
        }
        break;
        case "calendar-screen": 
            $('.progressbar, .progress').show();                
            if (code == "MLY" || code == '1') {
                $('#schedulerWizard #about-home-screen').addClass('active');
                getRefDetails();
            }
            else {
                $('#schedulerWizard #contact-information-screen').addClass('active');
            }
            break;
        case "about-home-screen":
             $('.progressbar, .progress').show();
            if(serviceModifier !="Commercial"){
                getAboutHomeValues();
                aboutDataStoreInLocal();
                if(get_data('selectedAboutHome') == 'onetime') {
                    mollyMaidEstimate();
                    $('#schedulerWizard #estimate-screen').addClass('active');
                   $('.services-list-screen').hide();
                   $('.estimation-list-screen').show();                                 
                }                
                else if(get_data('selectedAboutHome') == 'recurring'){
                  $('#schedulerWizard #estimate-screen').addClass('active');  
                    $('.services-list-screen').show();
                    $('.estimation-list-screen').hide()
                }
            }else{
                $('#schedulerWizard #contact-information-screen').addClass('active');
                getAboutHomeValues();
                aboutDataStoreInLocal();
            }
            break;
        case "estimate-screen":
            $('.progressbar, .progress').show();
            $('#schedulerWizard #contact-information-screen').addClass('active');
            break;
        case "contact-information-screen":
            $('.progressbar, .progress').show();
            $('#service-address-screen .locations').hide();
            $('#service-address-screen .next').show();
            if (code == "MLY" || code == '1'){
                $('#service-address-screen .js-next-btn').addClass('hidden');
                $('#service-address-screen .confirm-btn').removeClass('hidden');
            }else{
                $('#service-address-screen .js-next-btn').removeClass('hidden');
                $('#service-address-screen .confirm-btn').addClass('hidden');
            }         
            $('#schedulerWizard #service-address-screen').addClass('active');
            checkAddressFormValue();
            setServiceContactInfoOsVariables();
            window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? $('#input-service-state').siblings('label').text('Province*') : $('#input-service-state').siblings('label').text('State*');
            break;
        case "service-address-screen":
            $('.progressbar, .progress').show();
            if (get_data('zipcode') != window.objOSGlobal.requestBodyValues.PostalCode){
                if(get_data('franchiseLocation') == false){
                    store_data(window.objOSGlobal.requestBodyValues.PostalCode,'zipcode');
                    getFranchiseWeblocation(currentElement);
                    return;
                }
            }
            if (code != "MLY" && code != '1' && code != "14" && code != "PLG"){
                $('#schedulerWizard #service-details-screen').addClass('active');            
                getRefDetails();
            }
                        
            if (code == "MLY" && code == '1'){
                $('#sd-disclaimer-text').addClass('hidden');
                $('#service-details-screen .attachmentInput').parent().addClass('hidden');
            }
            else if(code == "MRA" || code == '8'){
                $('#sd-disclaimer-text').removeClass('hidden');
                $('#service-details-screen .attachmentInput').parent().addClass('hidden');
                diagnoseFeeMRA();
            }
            else if (code == '6' || code == 'GUY' || code == '2' || code == '13') {
                $('#sd-disclaimer-text').addClass('hidden');
                $('#service-details-screen .attachmentInput').parent().removeClass('hidden');
            }
            else if (code == "14" || code == "PLG") {
                $('#schedulerWizard #about-service-needs-screen').addClass('active');
            }
            else {
                $('#sd-disclaimer-text').addClass('hidden');
                $('#service-details-screen .attachmentInput').parent().addClass('hidden');
            }
            
            setServiceAddressOsVariables();
            break;
        case "about-service-needs-screen":
            $('.progressbar, .progress').show();
            store_data($('#about-service-needs-screen input[name="filter"]:checked').siblings('.radio-txt').text(), 'selectedPLGFilter');
            if($('#about-service-needs-screen #Auto').is(':checked')) {
                $('#schedulerWizard #vehicle-information-screen').addClass('active');
            }
            else {
                $('#schedulerWizard #service-details-screen').addClass('active');
                $('#sd-disclaimer-text').addClass('hidden');
                $('#service-details-screen .attachmentInput').parent().addClass('hidden');
            }
            getRefDetails();
            break;
        case "vehicle-information-screen":
            $('.progressbar, .progress').show();
            if(!$('#vehicle-information-1').hasClass('hidden')) {
                $('#vehicle-information-1, #vehicle-information-3').addClass('hidden');
                $('#vehicle-information-2').removeClass('hidden');                
            }
            else if(!$('#vehicle-information-2').hasClass('hidden')) {
                $('#vehicle-information-1, #vehicle-information-2').addClass('hidden');
                $('#vehicle-information-3').removeClass('hidden');
            }
            $('#schedulerWizard #vehicle-information-screen').addClass('active');
            setVehicleInformationVariables();
            break;
        default:         
            $('.progressbar, .progress').hide();   
            console.log('This is default case');
            break;
    }
    setProgressBarDisplay(code, isLeadOnly);
}