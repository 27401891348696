import { setProgressBar } from "../scheduler/scheduler";
import {get_data} from "../util";
export function setProgressBarDisplay(code: string, isLeadOnly: Boolean) {
    let serviceModifier = sessionStorage.getItem('ServiceModifierValue');  
    if(code == '1' && serviceModifier !='Commercial'){
        $('.progressbar').removeClass('molly-commercial');
        var selectAboutHome  = get_data('selectedAboutHome');
        if(isLeadOnly){
            $('.progressbar').removeClass('mly-onetime-flow');
            $('.progressbar').removeClass('mly-recurring-flow');
            if(selectAboutHome == "onetime"){
                $('.progressbar').removeClass('mly-recurring-lead-flow');
                $('.progressbar').addClass('mly-onetime-lead-flow');
            }
            else{
                $('.progressbar').removeClass('mly-onetime-lead-flow');
                $('.progressbar').addClass('mly-recurring-lead-flow');
            }
        }
        else{
            if(selectAboutHome == "onetime"){
                $('.progressbar').removeClass('mly-recurring-flow');
                $('.progressbar').removeClass('mly-onetime-lead-flow');
                $('.progressbar').removeClass('mly-recurring-lead-flow');
                $('.progressbar').addClass('mly-onetime-flow');
            }
            else{
                $('.progressbar').removeClass('mly-onetime-lead-flow');
                $('.progressbar').removeClass('mly-recurring-lead-flow');
                $('.progressbar').removeClass('mly-onetime-flow');
                $('.progressbar').addClass('mly-recurring-flow');
            }
        }
        $('.progressbar').removeClass('lead-flow');
    }
    else{
        $('.progressbar').removeClass('mly-onetime-lead-flow');
        $('.progressbar').removeClass('mly-recurring-lead-flow');
        $('.progressbar').removeClass('mly-recurring-flow');
        $('.progressbar').removeClass('mly-onetime-flow');
        $('.progressbar').removeClass('lead-flow');
        $('.progressbar').removeClass('molly-commercial');
    }
 
    if (code == "MLY" || code == '1'){
        if(serviceModifier =='Commercial'){
            $('.progressbar a[data-step="estimate-screen"]').parent().hide();
            $('.progressbar a[data-step="services-list-screen"]').parent().hide();
            $('.progressbar a[data-step="about-home-screen"]').parent().show();
            $('.progressbar li').css('width', 'calc(100%/4)');
            $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/4)');
            $('.progressbar a[data-step="calendar-screen"]').parent().hide();
            $('.progressbar').addClass('molly-commercial');
        }else{
            var frequence =  get_data('selectedAboutHome');
            if(frequence && frequence == 'recurring'){                
                $('.progressbar a[data-step="estimate-screen"]').parent().hide();
                $('.progressbar a[data-step="about-home-screen"]').parent().show();
                $('.progressbar a[data-step="services-list-screen"]').parent().show();
                if(isLeadOnly){
                    $('.progressbar li').css('width', 'calc(100%/5)');
                    $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/5)');
                    $('.progressbar a[data-step="calendar-screen"]').parent().hide();
                }else{
                    $('.progressbar li').css('width', 'calc(100%/6)');
                    $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/6)');
                    $('.progressbar li:last-child a').css('margin-left', 'calc((100%/6) * -1)');
                    $('.progressbar a[data-step="calendar-screen"]').parent().show();
                }
            }
            else{
                $('.progressbar a[data-step="about-home-screen"], .progressbar a[data-step="estimate-screen"]').parent().show();
                $('.progressbar a[data-step="services-list-screen"]').parent().hide();
                if(isLeadOnly){
                    $('.progressbar li').css('width', 'calc(100%/5)');
                    $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/5)');
                    $('.progressbar li:last-child a').css('margin-left', 'calc((100%/5) * -1)');
                    $('.progressbar a[data-step="calendar-screen"]').parent().hide();
                }else{
                    $('.progressbar li').css('width', 'calc(100%/6)');
                    $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/6)');
                    $('.progressbar li:last-child a').css('margin-left', 'calc((100%/6) * -1)');
                    $('.progressbar a[data-step="calendar-screen"]').parent().show();
                }
            }
        }
        $('.progressbar a[data-step="service-details-screen"]').parent().hide();  
        $('.progressbar').removeClass('portland-glass');          
    }
    else if(code == '8' && window.objOSGlobal.requestBodyValues.Warranty == true){
        $('.progressbar a[data-step="calendar-screen"]').parent().hide();      
        $('.progressbar li').css('width', 'calc(100%/4)');
        $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/4)');
        //$('.progressbar li:last-child a').css('margin-left', 'calc((100%/4) * -1)');
        $('.progressbar').removeClass('portland-glass');
        // $('.progress').css('max-width', '517px');
    }
    else if (code == '14' || code == 'PLG') {   
        $('.progressbar').addClass('portland-glass');     
        var filter = get_data('selectedPLGFilter');
        var marginpercent = filter == 'Auto' ? isLeadOnly ? 5 : 6 : isLeadOnly ? 5 : 6;
        //if($('#about-service-needs-screen #Auto').is(':checked')) {
        if(filter == 'Auto') {   
            $('.progressbar a[data-step="about-service-needs-screen"], .progressbar a[data-step="vehicle-information-screen"]').parent().show();            
            $('.progressbar a[data-step="service-details-screen"]').parent().hide();            
        }
        else {
            $('.progressbar a[data-step="vehicle-information-screen"]').parent().hide();
            $('.progressbar a[data-step="service-details-screen"]').parent().show();
        }
        
        if(isLeadOnly) {
            $('.progressbar a[data-step="calendar-screen"]').parent().hide();
            $('.progressbar a[data-step="contact-information-screen"]').parent().addClass('isLeadContact');
            $('.progressbar').removeClass('mly-onetime-flow');
        }
        else {
            $('.progressbar a[data-step="calendar-screen"]').parent().show();
            $('.progressbar a[data-step="contact-information-screen"]').parent().removeClass('isLeadContact');
            $('.progressbar').addClass('mly-onetime-flow');
        }
        
        $('.progressbar li').css('width', 'calc(100%/'+marginpercent+')');
        $('.progressbar li:last-child a').css('margin-left', 'calc((100%/'+marginpercent+')* -1)');
        $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/'+marginpercent+')');
        
    }
    else if(isLeadOnly){
        if(code == '8'){
            $('.progressbar').addClass('mra-progress-bar');
        }
        $('.progressbar a[data-step="calendar-screen"]').parent().hide();
        $('.progressbar a[data-step="about-home-screen"], .progressbar a[data-step="estimate-screen"]').parent().hide();
        $('.progressbar a[data-step="service-details-screen"]').parent().show();  
        $('.progressbar').addClass('lead-flow');
        $('.progressbar a[data-step="contact-information-screen"]').parent().addClass('isLeadContact');
        $('.progressbar li').css('width', 'calc(100%/4)');
        $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/4)');
        //$('.progressbar li:last-child a').css('margin-left', 'calc((100%/4) * -1)');
        $('.progressbar').removeClass('portland-glass');
    }
    else{
        $('.progressbar').removeClass('mra-progress-bar');
        $('.progressbar a[data-step="about-home-screen"], .progressbar a[data-step="estimate-screen"]').parent().hide();
        $('.progressbar a[data-step="about-service-needs-screen"], .progressbar a[data-step="vehicle-information-screen"]').parent().hide();
        $('.progressbar a[data-step="service-details-screen"]').parent().show();
        $('.progressbar a[data-step="calendar-screen"]').parent().show();
        $('.progressbar a[data-step="contact-information-screen"]').parent().removeClass('isLeadContact');
        $('.progressbar li').css('width', 'calc(100%/5)');
        $('.progressbar li:nth-child(4) a').css('margin-left', 'calc(100%/5)');
        $('.progressbar li:last-child a').css('margin-left', 'calc((100%/5) * -1)');
        $('.progressbar').removeClass('lead-flow'); 
        $('.progressbar').removeClass('portland-glass');
    }
    
    var activepane = $('.wam-scheduler .tab-content .tab-pane.active').attr('id'); 
    setProgressBar(activepane);        
    $('.progressbar').children().removeClass('active');
    $('.progressbar').children().addClass('disabled');
    $('.progressbar').children().find('a').removeClass('active');
    $('.progressbar  a[data-step="' + activepane + '"]').addClass('active');
    $('.progressbar  a[data-step="' + activepane + '"]').parent().removeClass('disabled');
    $('.progressbar  a[data-step="' + activepane + '"]').parent().addClass('active');
}