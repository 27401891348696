import { $ } from "../third-party-imports"

let scroll_header = 0;
export function headerFixed()
{
    var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
     if(!is_safari){
    let scroll_header_pos = $(window).scrollTop();

    if (scroll_header > scroll_header_pos)
    {
        $('body').addClass('headerFixed');
    }
    else
    {
        $('body').removeClass('headerFixed');
        //$('body.homePage').addClass('headerHide');
    }
    scroll_header = scroll_header_pos;

    if (scroll_header_pos == 0)
    {
        $('body.homePage').addClass('headerFixed').removeClass('headerHide');
        $('body').removeClass('headerFixed');

    }
    //if (($('body').find('.notify_sec')) && (scroll == 0))
    //{
    //    $('body').addClass('notify_remove');
    //}
}
}
