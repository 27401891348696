

export function phoneFormatter()
{
    var inputs = document.querySelectorAll('[name="profilePhone"]');
    if (inputs)
    {
        for (var i = 0; i < inputs.length; i++)
        {
            var element = inputs[i];
            element.addEventListener('input', function (e: any)
            {
                var x = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                e.target.value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            });
        }
    }
}
