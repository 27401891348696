import { getAllBlogs } from "./getAllBlogs";

/**
 * Build Search G2 Query Params
 */

export function searchG2QueryParams(key: any, value: any)
{
    var g2Collection = "https://searchg2.crownpeak.net/"+window.DEFINE_VALUE.Search_G2_URL+"/select/";
    let linkHref = window.location.href.toLowerCase();
    var langauge = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    let brandQuery = "";
    const localDBA = linkHref.split("/")[4];
    
    if (linkHref.split("/")[5] != undefined) {
        if (linkHref.split("/")[5].indexOf("expert-tips") != -1) {
            brandQuery = `&fq=custom_s_local_dba:(${​localDBA} OR none)`;
        }
    }
    else{
        brandQuery = `&fq=custom_s_local_dba:none`;
    }
    var g2Brand = `?q=custom_s_brand:nei${brandQuery}`;
    var start = 0;
    //var prevArticleIndex = parseInt(localStorage.getItem('blog_article_prev_index') || 0);
    //var totalArticleCounts = parseInt(localStorage.getItem('blog_total_count') || 0);

    var otherParams = `&start=${start}&rows=1000&wt=json&indent=true&sort=custom_dt_created desc`;
    var filteredParams = "&fl=custom_s_heading,custom_s_article_summary_Image_url,custom_t_content_summary_Image_url_alt,custom_t_content_summary_Desc,custom_s_local_url,custom_s_context_url";
    var g2CollectionURL;
    if (key && value)
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=' + key + ':' + value + '&fq=custom_s_status:Active&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
    } else
    {
        g2CollectionURL = g2Collection + g2Brand + '&fq=*&fq=custom_s_status:Active&fq=custom_s_country:'+langauge+'' + otherParams + filteredParams;
    }

    // Get All Blogs
    if(window.location.href.indexOf("expert-tips") >= 0)
        getAllBlogs(g2CollectionURL);
}
