import { $ } from "../third-party-imports"
import { getState } from "../util/getState";

export function onlineOrderFlowInit()
{
    $(document).on("click", ".result-stack-contact .btn-schedule-now", function ()
    {   
        var state = $('#service-address-screen select[name="state"]').children("option:selected").val();
        if(state ==""){
            getState();
        }

        $('#addressmodal-os').addClass('hidden');
        $('#personalInfo-os').removeClass('hidden');

        if ("profile" in localStorage)
        {
            $('.para-faster').addClass('hidden');

            let profileObj = //  {"neighborlyUserId":639,"neighborlyAccountId":650,"firstName":"jatin","lastName":"thripola","email":"ram@gmail.com","phone":[{"neighborlyAccountContactId":881,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(704) 243-6665","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":892,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(865) 002-2391","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":1198,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"9037315821","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false}],"accountAddresses":[{"neighborlyAccountAddressId":340,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Rampur road 123456789","addressLine2":"Haldwani","city":"Haldwani","state":"PW","country":"USA","zip":"10005","primaryAddressID":1},{"neighborlyAccountAddressId":345,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Ganna center rampur road Dev","addressLine2":"Address2","city":"Dehradun","state":"Alaska","country":"USA","zip":"60005","primaryAddressID":0},{"neighborlyAccountAddressId":392,"neighborlyAccountId":650,"accountAddressTypeID":2,"addressLine1":"4321 Main St","addressLine2":"","city":"Dallas","state":"IL","country":"USA","zip":"76102","primaryAddressID":0}],"userSocialProfiles":[],"isOptInForText":false,"isOptInForEmail":false,"isOptInForNotification":true,"communicationModeId":2};
                JSON.parse(localStorage.getItem("profile"));
            window.objOSGlobal.requestBodyValues.NeighborlyAccountId = profileObj && profileObj?.neighborlyAccountId;
            $("#PersonalInfoForm #f-name").val(profileObj.firstName);
            $("#PersonalInfoForm #l-name").val(profileObj.lastName);
            $("#PersonalInfoForm #inputEmail").val(profileObj.email);
            let phoneNumberMatch: RegExpMatchArray;
            let phoneNumber = "";

            if (profileObj.phone)
            {
                let contactDetail = profileObj.phone[0].contactDetail as string;
                phoneNumberMatch = contactDetail.match(/(\d{3})(\d{3})(\d{4})/);
                if (phoneNumberMatch)
                    phoneNumber = "(" + phoneNumberMatch[1] + ") " + phoneNumberMatch[2] + "-" + phoneNumberMatch[3];
                else
                    phoneNumber = contactDetail;
            }
            $("#PersonalInfoForm #name").val(phoneNumber);
            if (profileObj.communicationModeId == 3)
                $("#PersonalInfoForm #Phone2").prop("checked", true);
            if (profileObj.communicationModeId == 1)
                $("#PersonalInfoForm #standard3").prop("checked", true);
            if (profileObj.communicationModeId == 2)
                $("#PersonalInfoForm #Email2").prop("checked", true);

            $('#personal-next').removeClass('disble-btn');
            $('#personal-next').prop('disabled', false);

        }
    });

    function setAddressForm(address1: any, address2: any, city: any, state: any, zipcode: any)
    {
        $('.service-address-form #Address').val(address1);
        $('.service-address-form #Address2').val(address2);
        $('.service-address-form #City').val(city);
        // $('.service-address-form  select[name="state"]').val(state);
        //$("#stateaddressos").val(state).trigger('change');
        // $('#stateaddressos').selectmenu('refresh', true);
        $('#stateaddressos option[value='+state+']').attr('selected','selected');
        $('.service-address-form #Zip-Code').val(zipcode);

        $('#service-address-next').removeClass('disble-btn');
        $('#service-address-next').prop('disabled', false);
    }
    $(document).on("click", "#personal-next", function ()
    {
        if($('.Address2os').val()=='')
            $('.address2Label').addClass('label-active');

      

        var addresses = //"4321 Main St, ghghg, dublin, michigna, 76102";
            JSON.parse(localStorage.getItem("address")) as any[];

        if (addresses)
        {
            var address1Obj = addresses.find(form => form.name === 'Address1');
            var address2Obj = addresses.find(form => form.name === 'Address2');
            var cityObj = addresses.find(form => form.name === 'City');
            var stateObj = addresses.find(form => form.name === 'state');
            var zipcodeObj = addresses.find(form => form.name === 'zipcode');
            setAddressForm(address1Obj.value, address2Obj.value, cityObj.value, stateObj.value, zipcodeObj.value);
            if(address2Obj.value){
                $("#addressmodal-os input#Address2").next('label').removeClass('label-active');
            }
        }

        if ("profile" in localStorage)
        {
            //  $(".para-faster").addClass("hidden");

            let profileObj = JSON.parse(localStorage.getItem("profile")); // {"neighborlyUserId":639,"neighborlyAccountId":650,"firstName":"jatin","lastName":"thripola","email":"ram@gmail.com","phone":[{"neighborlyAccountContactId":881,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(704) 243-6665","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":892,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(865) 002-2391","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":1198,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"9037315821","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false}],"accountAddresses":[{"neighborlyAccountAddressId":340,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Rampur road 123456789","addressLine2":"Haldwani","city":"Haldwani","state":"PW","country":"USA","zip":"10005","primaryAddressID":1},{"neighborlyAccountAddressId":345,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Ganna center rampur road Dev","addressLine2":"Address2","city":"Dehradun","state":"Alaska","country":"USA","zip":"60005","primaryAddressID":0},{"neighborlyAccountAddressId":392,"neighborlyAccountId":650,"accountAddressTypeID":2,"addressLine1":"4321 Main St","addressLine2":"","city":"Dallas","state":"IL","country":"USA","zip":"76102","primaryAddressID":0}],"userSocialProfiles":[],"isOptInForText":false,"isOptInForEmail":false,"isOptInForNotification":true,"communicationModeId":2};

            let address = JSON.parse(localStorage.getItem("address")); //getting address bumper values
            if (address)
            {
                var address1Obj = addresses.find((form) => form.name === "Address");
                var address2Obj = addresses.find((form) => form.name === "Address2");
                var cityObj = addresses.find((form) => form.name === "City");
                var stateObj = addresses.find((form) => form.name === "state");
                var zipcodeObj = addresses.find((form) => form.name === "zipcode");
                setAddressForm(
                    address1Obj.value,
                    address2Obj.value,
                    cityObj.value,
                    stateObj.value,
                    zipcodeObj.value
                );
            }
            else
            {
                var zipcode = localStorage.getItem("zipcode");
                if(zipcode) {
                zipcode = zipcode.replace(/"/g, "");
                }
                var primaryAddressFiltered = profileObj.accountAddresses.find((x: any) => x.primaryAddressID === 1);
                 var primaryAddress = primaryAddressFiltered ? primaryAddressFiltered : { zip: "" };

                 var isPrimaryAddress = true ? primaryAddressFiltered : false;

                var secondaryAddresses = profileObj.accountAddresses.filter(
                    (x: any) => x.primaryAddressID === 0
                );

                var secondaryAddress1 = secondaryAddresses[0]
                    ? secondaryAddresses[0]
                    : { zip: "" };
                var secondaryAddress2 = secondaryAddresses[1]
                    ? secondaryAddresses[1]
                    : { zip: "" };

                if (zipcode === primaryAddress.zip)
                {
                    setAddressForm(
                        primaryAddress.addressLine1,
                        primaryAddress.addressLine2,
                        primaryAddress.city,
                        primaryAddress.state,
                        primaryAddress.zip
                    );
                } else if (zipcode === secondaryAddress1.zip)
                    setAddressForm(
                        secondaryAddress1.addressLine1,
                        secondaryAddress1.addressLine2,
                        secondaryAddress1.city,
                        secondaryAddress1.state,
                        secondaryAddress1.zip
                    );
                else if (zipcode === secondaryAddress2.zip)
                    setAddressForm(
                        secondaryAddress2.addressLine1,
                        secondaryAddress2.addressLine2,
                        secondaryAddress2.city,
                        secondaryAddress2.state,
                        secondaryAddress2.zip
                    );
                else if(isPrimaryAddress)
                    setAddressForm(
                        primaryAddress.addressLine1,
                        primaryAddress.addressLine2,
                        primaryAddress.city,
                        primaryAddress.state,
                        primaryAddress.zip
                    );
            }
        }
        if (!$('.service-address-form #Zip-Code').val())
        {
            let zipcode = localStorage.getItem("zipcode");
            if(zipcode) {
            zipcode = zipcode.replace(/"/g, "");
            }
            $('.service-address-form #Zip-Code').val(zipcode);
        }
    });
}