import { initBlogArticlePage } from "./initBlogArticlePage";
import { $ } from "../third-party-imports"

$(document).ready(function () {
    initBlogArticlePage();
    $('.return-blog a').click(function () {
       var returnlink = localStorage.getItem('current_context_url');
		if(returnlink === "" || returnlink === null){
               var afterCom =window.location.href.split('.com')[1];
               var isWebSite =  afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
               if (isWebSite == "us"){
                    window.location.replace(`${window.location.origin}/expert-tips`);
               }
               else{
                    window.location.replace(`${window.location.origin}/${window.location.href.split('/')[3]}/expert-tips`);
               }
           }
	     else{
           window.location.replace(returnlink);
		 }
    });
});