import { $ } from "../third-party-imports"
import { changePassword, linkingSocial, unlinkSocial } from "../auth-redirect";
import { apiConfig } from "../config";
import { addProfilePoneNumber } from "./addProfilePoneNumber";
import { callAddEDitApi } from "./callAddEDitApi";
import { clearAddressFormData } from "./clearAddressFormData";
import { clearOtpVerification } from "./clearOtpVerification";
import { generateOtpNumber } from "./generateOtpNumber";
import { getProfileState } from "./getProfileState";
import { linkAndUnlinkSocial } from "./linkAndUnlinkSocial";
import { phoneFormatter } from "./phoneFormatter";
import { phoneVerification } from "./phoneVerification";
import { preferredContactFrequency } from "./preferredContactFrequency";
import { profileZipCodeValidate } from "./profileZipCodeValidate";
import { resendCodeFun } from "./resendCodeFun";
import { setMultipleaddress } from "./setMultipleaddress";
import { setMultipleContact } from "./setMultipleContact";
import { setProfileEmail } from "./setProfileEmail";
import { setProfileName } from "./setProfileName";
import { unLinkingPopUp } from "./unLinkingPopUp";
import { updateProfile } from "./updateProfile";
import { validatePhoneNumber } from "./validatePhoneNumber";
import { getNblyMicroServiceApiHeaders } from '../api/getNblyMicroServiceApiHeaders';
import { profileVars } from ".";
import { neiLocalStorage } from '../config/NeiLocalStorage';
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";

$('.profileDetails').click(function ()
{
    var profile = JSON.parse(localStorage.getItem('profile'));
    profileVars.neighborlyAccountId = profile && profile?.neighborlyAccountId;
    profileVars.neighborlyUserId = profile.neighborlyUserId;
    if (profile)
    {
        $('.text-withedit').remove();
        $('#profileState option').remove();
        $("#profileState").append('<option value="" >State</option>');
        profileZipCodeValidate();
        phoneFormatter();
        getProfileState();
        setProfileName(profile);
        setProfileEmail(profile);
        setMultipleContact(profile);
        setMultipleaddress(profile);
        preferredContactFrequency(profile);
        linkAndUnlinkSocial(profile);
        $('.addlink').click(function ()
        {
            profileVars.allowSubmit = true;
            $('.form_container_wrapperBox:first-child').hide();
            $('#js-popupModalDialog span.delete').css("display", "none");

            if ($(this).parents('.personal-box').hasClass('profilePhone'))
            {
                $("#editPhone .top_txt h3").text('Add Phone');
                $('#editPhone').addClass('active');
                $('#phone1').val('');
                $('#phoneApplyBtn').attr('data-edit', '');
                return;
            }
            if ($(this).parents('.personal-box').hasClass('profileAddress'))
            {
                $('#profileAddressBtn').addClass('disble-btn');
                $('#profileAddressBtn').prop("disabled", true);
                $('#editAddress').addClass('active');
                $("#editAddress .top_txt h3").text('Add Address');
                clearAddressFormData();
                return;
            }
        })

        $('.profileBtn').off('click').on('click', function ()
        {
            if(profileVars.allowSubmit){
                profileVars.allowSubmit=false;
                profileVars.validateBtn=true;   
                profile = JSON.parse(localStorage.getItem('profile'));
                var profileBtnId = $(this).attr('id');
                var fields = 'phone';
                profileVars.editId = $(this).attr('data-edit');
                profileVars.type = "POST";
                let payload: any;
                switch (profileBtnId)
                {
                    case 'phoneApplyBtn':
                        var phoneNumber = $('#phone1').val();
                        var mediaType = $("input[name=verifyno]:checked").val();
                        phoneNumber = phoneNumber.toString().replace(/[- )(]/g, '');
                        var phoneNumbers = profile.phone.map(function (item: any)
                        {
                            if (profileVars.editId == item.neighborlyAccountContactId)
                            {
                                return false;
                            }
                            return item.contactDetail;
                        })
                        if (phoneNumbers.indexOf(phoneNumber) >= 0)
                        {
                            if (profileVars.editId)
                            {
                                addProfilePoneNumber();
                            } else
                            {
                                $('.custom-error').show();
                            }
                        } else
                        {
                            if (phoneNumber)
                            {
                                var phoneItems = profile.phone.filter(function (item: any)
                                {
                                    return item.contactDetail == phoneNumber;
                                })

                                if (phoneItems.length && phoneItems[0].contactVerificationStatusId == 2)
                                {
                                    addProfilePoneNumber();
                                    return;
                                }
                                clearOtpVerification();
                                $("#editPhone").removeClass('active');
                                $('#generateOtp').addClass('active');
                                resendCodeFun(phoneNumber);
                                generateOtpNumber(phoneNumber, mediaType, function (data: any, success: any)
                                {
                                    if (data.status == 'Success')
                                    {
                                        validatePhoneNumber(data.neighborlyAccountOtpid);
                                    }
                                });
                            }
                        }
                        break;
                    case 'profileNameBtn':
                        var fields = "First Name,Last Name";
                        profileVars.type = "PUT";
                        payload = {
                            "neighborlyAccountId": 0, //profileVars.neighborlyAccountId,
                            "firstName": $('#profileFName').val(),
                            "lastName": $('#profileLName').val()
                        }
                        callAddEDitApi(payload, apiConfig.profileApi, function (data: any, status: any)
                        {
                            $("#editName").removeClass('active');
                            $('.form_container_wrapperBox:first-child').show();
                            updateProfile();
                        }, profileVars.type);
                        break;
                    case 'profileAddressBtn':
                        var fields = 'Address,Address2,City,State,Zip Code,Type';
                        var address = $('#profileAddress').val();
                        var address2 = $("#profileAddress2").val();
                        var city = $("#profileCity").val();
                        var zipCode = $("#profileZipCode").val();
                        let state = $("#profileState").val();   //.children(":selected").attr("id");
                        var profileType = $("#profileType").val();
                        var primaryCheckBox = function ()
                        {
                            if ($('#customCheck-nbAddress').is(':checked')) { return 1; }
                            return 0;

                        }
                        payload = {
                            "AddressLine1": address,
                            "AddressLine2": address2,
                            "Zip": zipCode,
                            "City": city,
                            "State": state,
                            "Country": profileVars.country,
                            "PrimaryAddressID": primaryCheckBox(),
                            "AccountAddressTypeID": profileType
                        }
                        if (profileVars.editId)
                        {
                            payload.NeighborlyAccountAddressId = profileVars.editId;
                            profileVars.type = "PUT";
                        } else
                        {
                            payload.NeighborlyAccountId = 0; //profileVars.neighborlyAccountId;
                            profileVars.type = "POST";
                        }
                        payload.State = $("#profileState").children(":selected").attr("id");
                        callAddEDitApi(payload, apiConfig.adddressApi, function (data: any, status: any)
                        {

                            $("#editAddress").removeClass('active');
                            $('.form_container_wrapperBox:first-child').show();
                            updateProfile();
                        }, profileVars.type);
                        break;
                }
            }  
        })
    }
});


$('#editAddress .delete').click(function ()
{
    if(profileVars.deleteButton){
        profileVars.deleteButton = false;
        var neighborlyAccountAddressId = $(this).attr('data-nblyacntId');
        var url = apiConfig.adddressApi + '/' + neighborlyAccountAddressId;
        /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
         start_loader();
         */
        $.ajax({
            url: url+"?"+getNblyApiHeaders(),
            headers: getNblyMicroServiceApiHeaders(),
            type: "DELETE",
            success: function (data, status)
            {
                // stop_loader();
                $("#editAddress").removeClass('active');
                $('.form_container_wrapperBox:first-child').show();
                updateProfile();
            },
            error: function (xhr, status, error)
            {
                // stop_loader();
                console.log(error);
            }
        });
    }
})

$('#editPhone .delete').click(function (){
 if(profileVars.deleteButton){
    profileVars.deleteButton = false;
        if($(this).css('display') == "none"){
            return;
        }
        var neighborlyAccountContactId = $(this).attr('data-nblyPhoneId');
        var url = apiConfig.editPhoneApi + '/' + neighborlyAccountContactId;
        /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
        start_loader();
        */
        $.ajax({
            url: url+"?"+getNblyApiHeaders(),
            headers: getNblyMicroServiceApiHeaders(),
            type: "DELETE",
            success: function (data, status)
            {
                // stop_loader();
                $("#editPhone").removeClass('active');
                $('.form_container_wrapperBox:first-child').show();
                updateProfile();
            },
            error: function (xhr, status, error)
            {
                // stop_loader();
                console.log(error);
            }
         });
    }  
})

$("#changePassword").click(function ()
{
    changePassword();
})


$('.digit-group').find('input').each(function ()
{
    $(this).attr('maxlength', 1);
    $(this).on('keyup', function (e)
    {
        var parent = $($(this).parent());

        if (e.keyCode === 8 || e.keyCode === 37)
        {
            var prev = parent.find('input#' + $(this).data('previous'));

            if (prev.length)
            {
                $(prev).select();
            }
        } else if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 39)
        {
            var next = parent.find('input#' + $(this).data('next'));

            if (next.length)
            {
                $(next).select();
            } else
            {
                if (parent.data('autosubmit'))
                {
                    parent.submit();
                }
            }
        }
    });
});

$('.welContinue').click(function ()
{
    $('.wlcmMsgModal').hide();
})


$(document).ready(function ()
{
    var profile = JSON.parse(localStorage.getItem('profile'));
    if (profile)
    {
        profileVars.neighborlyUserId = profile.neighborlyUserId
        var ProfileScreen = (localStorage.getItem("ProfileScreen") && localStorage.getItem("ProfileScreen") !== null) ? localStorage.getItem("ProfileScreen") : "false";
        if (neiLocalStorage.accessToken && ProfileScreen == "true")
        {
            var decodeData = neiLocalStorage.decodedAccessToken;
            var isPassUpdatedForLocalAcc = localStorage.getItem('isPassUpdatedForLocalAcc');
            updateProfile();
            $('#js-popupModalDialog').show();
            if( $('#js-popupModalDialog').css('display') == 'block' ) {
                getProfileState();
            }
            if (isPassUpdatedForLocalAcc == 'false' && decodeData.isPassUpdatedForLocalAcc == true)
            {
                $('body').addClass('scrollHide popUpHeaderFixed');                
                $('.succesfullyAccount').show();
            }
        }
    }
    $('.succesfullyAccountBtn').click(function ()
    {
        // var loginWith = localStorage.getItem('loginWith');
        localStorage.removeItem('isPassUpdatedForLocalAcc');
        $('.succesfullyAccount').hide();
        // if(loginWith){
        //     logout();
        // }
    })
    $('#profileAddressBtn').on('click', function ()
    {
        var post_vars = $('#signInForm3').serializeArray();
        localStorage.setItem('signInForm3Data', post_vars as any); //TODO ensure this serializes correctly instead of passing in array.
    });
    phoneVerification();

});

$('.link-socials').click(function ()
{
    var link = $(this).data('link');
    var linkText = $(this).text();
    var profile = JSON.parse(localStorage.getItem('profile'));
    let decodeData = neiLocalStorage.decodedAccessToken;
    if (link == 'fb')
    {
        if (linkText == "Link")
        {
            linkingSocial(profile.email, 1, decodeData.sub);
        } else
        {
            if (decodeData.isPassUpdatedForLocalAcc == false)
            {
                $('.linkingPopWindow').show();
                unLinkingPopUp(profile.email, 1, decodeData)
            } else
            {
                unlinkSocial(profile.email, 1, decodeData.sub);
            }
        }
    } else if (link == 'gmail')
    {
        if (linkText == "Link")
        {
            linkingSocial(profile.email, 2, decodeData.sub);
        } else
        {
            if (decodeData.isPassUpdatedForLocalAcc == false)
            {
                $('.linkingPopWindow').show();
                unLinkingPopUp(profile.email, 2, decodeData)
            } else
            {
                unlinkSocial(profile.email, 2, decodeData.sub);
            }
        }
    } else if (link == 'apple')
    {
        if (linkText == "Link")
        {
            linkingSocial(profile.email, 3, decodeData.sub);
        } else
        {
            if (decodeData.isPassUpdatedForLocalAcc == false)
            {
                $('.linkingPopWindow').show();
                unLinkingPopUp(profile.email, 3, decodeData)
            } else
            {
                unlinkSocial(profile.email, 3, decodeData.sub);
            }
        }
    }
})
$('#phone1').keyup(function ()
{
    $('.custom-error').hide();
})

$('.errorContinue').click(function ()
{
    $('.errorMsgPopUp').hide();
})

$("#cancelOtpReg").click(function ()
{
    $('.phoneVerificationNumberOtp').hide();
    $('.phoneVerificationNumber').show();
})

$('#generateOtp .inputRow input').keyup(function ()
{
    $('.otpError').text('');
})
$(".phoneVerificationNumberOtp .inputRow input").keyup(function ()
{
    $('.otpError').text('');
})

$('#js-popupModalDialog .close').click(function ()
{
    localStorage.removeItem("ProfileScreen");
});

$('#profileState').off('change').on('change',function(){
    var selectedItem =  $(this).children("option:selected").val()
    // var address = $("#profileAddress").val();
    // var city = $("#profileCity").val();
    selectCountry(selectedItem);
    if($("#profileAddressForm").valid()){
        $("#profileAddressBtn").removeClass("disble-btn");
        $("#profileAddressBtn").prop("disabled", false);
    }
    else{
        $("#profileAddressBtn").addClass("disble-btn");
        $("#profileAddressBtn").prop("disabled", true);
    }
    // $("#editAddress .proAddEdit").valid();
    // if(selectedItem != '' && address !='' && city !='') {
    //     $('#profileAddressBtn').prop("disabled", false);
    //     $("#profileAddressBtn").removeClass("disble-btn");
    // }else{
    //     $('#profileAddressBtn').prop("disabled", true);
    //     $("#profileAddressBtn").addClass("disble-btn")
    // }
});

function selectCountry(state:any){
    if(profileVars.usStates.length!==0){ 
        var usa  = profileVars.usStates.some((st: any)=>st == state);
        if(usa){profileVars.country = "USA";}
    }
    if(profileVars.usStates.length!==0){ 
        var ca  = profileVars.caStates.some((st: any)=>st == state);
        if(ca){profileVars.country = "CANADA";}
    }
}