import { keywordSearch } from "./keywordSearch";
import { $ } from "../third-party-imports"

export function attachSearchEvent()
{
    $('.srchBtnn').find('button').click(function (e)
    {
        e.preventDefault();
        keywordSearch();
    });
	const getSearchButtons = () => document.querySelectorAll(".srchBtnn button");
    const searchValue = $('#keywordSearch').val() as string;

	getSearchButtons().forEach((button) => {
        if(!searchValue)
            $('.srchBtnn').find('button').attr('disabled', 'disabled');
        button.addEventListener("click", () => keywordSearch());
    });
	
    $('#keywordSearch').keyup(function (e: any)
    {
		getSearchButtons().forEach((button) => {
            $('.srchBtnn').find('button').prop('disabled', !e.target.value);
        });
       
        const searchRegex = /^[a-zA-Z0-9-\s]+$/g;
        if (searchRegex.test(e.target.value))
        {
            const errMsg = document.querySelector('#searchInputErrMsg');
            if (errMsg)
                $('#searchInputErrMsg').remove();
        }
        if ($('#keywordSearch').is(":focus") && e.key == "Enter")
        {
            keywordSearch();
        }
    });
}
