import { addClassInModalWindow } from "./addClassInModalWindow";
import { sortResults } from "./sortResults";
import { get_data } from "./get_data";
import { NPSScore } from "./NPSScore";
import { $ } from "../third-party-imports"
import { ServiceResultItem } from "./ServiceResultItem";
import { apiConfig } from "../config";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { start_loader } from "./start_loader";
import { stop_loader } from "./stop_loader";

export async function serviceResults(result: ServiceResultItem[])
{
    var allFranchise: any[] = [];
    $('.result-stack-row .col-md-12').empty();
    if (result.length != 0)
    {   
        result = sortResults(result, "npsScore", false);
        result = sortResults(result,'netPromoterStarRating',false);
        let brandList = window.brandList;
        $('#result-address').text(get_data('zipcode')+":");
        for (const value of result){
            const {stars } = await NPSScore(value);
            let imgUri = '/us/en-us/_assets/images/aire_serv_logo.png';
            let brandUrl = '';
            let altText = "img";
            const brandIndex = brandList.findIndex(function (entry: any, i: any)
            {
                if (entry.id == value.conceptID)
                {
                    return true;
                }
            });
            imgUri = brandIndex != -1 ? brandList[brandIndex].image : "";
            let concepCode = brandIndex != -1 ? brandList[brandIndex].code : ''
            //brandUrl = brandIndex != -1 ? brandList[brandIndex].br_href : "";
            brandUrl = value.locationWebsiteUrl ? (value.locationWebsiteUrl.indexOf("http") != -1 ? value.locationWebsiteUrl : "https://" + value.locationWebsiteUrl) : "";
            window.objOSGlobal.paramsUI.franchiseLogoPath = imgUri;
            if (value.alternateConcept !== null)
            {
                const alternateBrandList = window.brandList.find(function (item)
                {
                    return item.id == value.conceptID

                });
                if (alternateBrandList)
                {
                    const brandLogo = alternateBrandList.AlternateBrandList.find(function (itm: any)
                    {
                        return itm.alternate_brand == value.alternateConcept
                    })
                    if (brandLogo.image)
                    {
                        imgUri = brandLogo.image;
                    }
                }
            }

            let reviewClass = !value.npsScore ? "noReview" : "";
            altText = brandIndex != -1 ? brandList[brandIndex].name : "";
            const afterCom = window.location.href.split('.com')[1];
            const isWebSite = afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
            const url = isWebSite == "ca" ? "/ca" : "";
            const brandName = isWebSite == "ca" ? "neighbourly" : "neighborly";
            //var licenseText = value.licenseNumber ? "<a class=\"more-info-txt no-link license-number\">License #"+value.licenseNumber+"</a>" : "";
            allFranchise.push(`
                <div class="result-stack-display ${reviewClass}">
                    <div class="result-stack-info">
                        <div class="result-info-logo clearfix">
                            <div class="result-info-logo-img">
                                <img src="${imgUri}" alt="${altText}" loading="lazy">
                                <a class="more-info-txt no-link">${value.doingBusinessAs}</a>
                                <a class=\"more-info-txt no-link license-number\" data-license="${value.franchiseWebLocationId}"></a>
                                <a href=${brandUrl} target="_blank" class="more-info-txt">More Info</a>
                            </div>
                        </div>
                        ${stars}
                    </div>
                    <div class="result-stack-contact" data-concept ="${concepCode}">
                        <a href="" class="contact-details" data-phone="${value.franchiseWebLocationId}"></a>
                        <a href="#" data-franchise="${value.franchiseWebLocationId}##${value.doingBusinessAs}##${value.conceptID}##${value.franchiseId}"  role="button" class="primary-btn js-next-btn btn-schedule-now"  data-attr="service-personal">
                            Schedule Now
                        </a>
                    </div>
                    <p class="foot-note">
                        All ${brandName} services are covered by the <a href="${url}/${brandName}-done-right-promise">Done Right Promise.</a>
                    </p>
                </div>
            `);
        }

        $('.result-stack-row .col-md-12').append(allFranchise);

        for (const value of result) 
        {
            start_loader();
            $.ajax({
                url: apiConfig.fullAttribute+"?franchiseWeblocationId=" + value.franchiseWebLocationId + "&apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
                type: "GET",
                success: function (data)
                {
                    var licenseNumber = data.stateLicenseNumbers ? data.stateLicenseNumbers.join(',') : "";
                    if(licenseNumber)
                    $("a[data-license =" + value.franchiseWebLocationId + "]").text("License #" + licenseNumber);
                    else
                    $("a[data-license =" + value.franchiseWebLocationId + "]").addClass("d-none");

                    var phoneNumber = data.mainPhoneNumber;                    
                    // var calltrackObj = data.callTrackings.filter((obj: any) => obj.franchiseWebLocationCallTrackingTypeID == 9);
                    // if (calltrackObj[0])
                    // {
                    //     var phoneNumber = calltrackObj[0].callTrackingNumber ? calltrackObj[0].callTrackingNumber.toString().match(/(\d{3})(\d{3})(\d{4})/) : null;
                        if (phoneNumber)
                        {
                            if(!(/^(\([0-9]{3}\)(\s)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(phoneNumber))){
                                phoneNumber = phoneNumber.toString().match(/(\d{3})(\d{3})(\d{4})/);
                                phoneNumber = "(" + phoneNumber[1] + ") " + phoneNumber[2] + "-" + phoneNumber[3];
                            }

                            $('.result-stack-contact .contact-details').removeClass("hidden");
                            //$('.col-md-4.callUsTextCont .contact-details').removeClass("hidden");
                          //  $('.col-md-4.callUsTextCont .callUsText').removeClass("hidden");
                            $("a[data-phone =" + value.franchiseWebLocationId + "]").empty();
                            $("a[data-phone =" + value.franchiseWebLocationId + "]").append("<img src=\"/us/en-us/_assets/images/phone-wam.svg\" alt=\"phone wam\" width=\"13\" height=\"27\">");
                            $("a[data-phone =" + value.franchiseWebLocationId + "]").append(phoneNumber);
                            $("a[data-phone =" + value.franchiseWebLocationId + "]").attr("href", "tel:" + phoneNumber);
                            window.objOSGlobal.paramsUI.brandPhoneNumber = phoneNumber;                            
                        }
                        else
                        {
                            $('.result-stack-contact .contact-details').addClass("hidden");
                            //$('.col-md-4.callUsTextCont .contact-details').addClass("hidden");
                            //$('.col-md-4.callUsTextCont .callUsText').addClass("hidden");
                        }
                    // }
                    // else
                    // {
                    //     $('.result-stack-contact .phone_call_icon').addClass("hidden");
                    //     //$('.col-md-4.callUsTextCont .phone_call_icon').addClass("hidden");
                    //    // $('.col-md-4.callUsTextCont .callUsText').addClass("hidden");
                    // }
                }
            });
        }
        stop_loader();
    }
    else
    {
        $(".servive-form-wrapperBox").removeClass("active");
        $('#no-search-result').addClass("active");
        addClassInModalWindow('no-search-result');
    }
}
