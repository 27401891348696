import { 
    BroadlyEmbededReviewClient, 
    CustomerReviewService, 
    ReviewClient 
} from "@nbly/crownpeak";

const parseFn = (html: string) => { return Promise.resolve(new DOMParser().parseFromString(html, "text/html")) };
export const customerReviewService = new CustomerReviewService(
    new ReviewClient(
        globalThis["crownpeak-components-environment"].reviewBaseUrl, 
        globalThis["crownpeak-components-environment"].apiKey
    ),
    new BroadlyEmbededReviewClient(parseFn)
);