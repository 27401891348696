import { getAllNotification } from "./getAllNotification";
import { $ } from "../third-party-imports"

export function clickOnNotification()
{
    $('.notify').click(function (e)
    {
        e.stopPropagation();
        //isVisibleNotification = true;
        $('.nbly_notification li').remove();
        getAllNotification();
    });
}
