import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import {serviceResults} from "./serviceResults";
import {start_loader} from "./start_loader";
import { stop_loader } from "./stop_loader";

export function getAllFranchise(address: any, serviceID: any, RoundRobin: any)
{
    $('.result-stack-row .col-md-11').empty();
    start_loader();
    $.ajax({
        url: apiConfig.FranchiseLookUpByServiceAddress+"?apikey=" + window.DEFINE_VALUE.JS_API_KEY +"&Address=" + encodeURIComponent(address) + "&serviceID=" + serviceID + "&IsRoundRobin=" + RoundRobin+"&"+getNblyApiHeaders(),        
        type: "GET",
        dataType: 'json',
        headers: {
            'Cache-Control': 'max-age=0' 
        },
        success: async function (result, status, xhr)
        {
            if(result.length>1 && RoundRobin == false){
                getAllFranchise(address, serviceID, true)
            }
            else{
                await serviceResults(result);
            }
            stop_loader();
        },
        error: function (e: any)
        {
            stop_loader();
            //console.log(e.message);
        }
    });

}
