import { $ } from "../third-party-imports"

export function emptyAddressFilds()
{
    $("#state").siblings('label').addClass('v-hidden');
    //$("#Zip-Code").siblings('label').addClass('v-hidden');
    $('form').find("#Address").val('');
    $('form').find("#Address1").val('');
    $('form').find("#Address2").val('');
    $('form').find("#City").val('');
    $('form').find("#state").val('');
    $('form').find("#stateList").val('');
    $('form').find("#Zip-Code").val('');
    $('.toggle-disabled').prop("disabled", true);
    $("#signInForm #service-next").addClass("disble-btn");
}
