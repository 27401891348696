import { $ } from "../third-party-imports";
export function fillPersonalInfoFromLocalstorage(){
    if ("profile" in localStorage)
    {
        $(".para-faster").addClass("hidden");

        let profileObj = JSON.parse(localStorage.getItem("profile")); //  {"neighborlyUserId":639,"neighborlyAccountId":650,"firstName":"jatin","lastName":"thripola","email":"ram@gmail.com","phone":[{"neighborlyAccountContactId":881,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(704) 243-6665","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":892,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"(865) 002-2391","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false},{"neighborlyAccountContactId":1198,"neighborlyAccountId":650,"contactTypeId":1,"contactDetail":"9037315821","contactVerificationStatusId":2,"primaryPhoneID":0,"isOptIn":false}],"accountAddresses":[{"neighborlyAccountAddressId":340,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Rampur road 123456789","addressLine2":"Haldwani","city":"Haldwani","state":"PW","country":"USA","zip":"10005","primaryAddressID":1},{"neighborlyAccountAddressId":345,"neighborlyAccountId":650,"accountAddressTypeID":1,"addressLine1":"Haripur LalMani Ganna center rampur road Dev","addressLine2":"Address2","city":"Dehradun","state":"Alaska","country":"USA","zip":"60005","primaryAddressID":0},{"neighborlyAccountAddressId":392,"neighborlyAccountId":650,"accountAddressTypeID":2,"addressLine1":"4321 Main St","addressLine2":"","city":"Dallas","state":"IL","country":"USA","zip":"76102","primaryAddressID":0}],"userSocialProfiles":[],"isOptInForText":false,"isOptInForEmail":false,"isOptInForNotification":true,"communicationModeId":2};
        window.objOSGlobal.requestBodyValues.NeighborlyAccountId = profileObj && profileObj?.neighborlyAccountId;
        $("#PersonalInfoForm #f-name").val(profileObj.firstName);
        $("#PersonalInfoForm #l-name").val(profileObj.lastName);
        $("#PersonalInfoForm #inputEmail").val(profileObj.email);
        if (profileObj.phone)
        {
            var x = profileObj.phone[0].contactDetail.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            var profileObjVal = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            $("#PersonalInfoForm #name").val(profileObjVal);
        }
        if (profileObj.communicationModeId == 3)
            $("#PersonalInfoForm #Phone2").prop("checked", true);
        if (profileObj.communicationModeId == 1)
            $("#PersonalInfoForm #standard3").prop("checked", true);
        if (profileObj.communicationModeId == 2)
            $("#PersonalInfoForm #Email2").prop("checked", true);

        if ($("#PersonalInfoForm").valid())
        {
            $("#personal-next").removeClass("disble-btn");
            $("#personal-next").prop("disabled", false);
        }
        else
        {
            $("#personal-next").addClass("disble-btn");
            $("#personal-next").prop("disabled", true);
        }
    }
}
