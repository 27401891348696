import { get_data } from "./get_data";
import { getAllFranchise } from "./getAllFranchise";


export function onSubmitAddress()
{
    var serviceId = get_data('serviceID');
    var address = get_data('address');
    address = address.map(function (el: any)
    {
        return el.value;
    });

    address = '"' + address.join() + '"';
    getAllFranchise(address, serviceId, "true");
}
