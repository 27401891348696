import { $ } from "../third-party-imports"


export function clearOtpVerification()
{
    $('#digit-1').val('');
    $('#digit-2').val('');
    $('#digit-3').val('');
    $('#digit-4').val('');
    $('#digit-5').val('');
    $('#digit-6').val('');
}
