import { apiConfig } from "../config";
import { getCountAllNotification } from ".";
import { getNblyMicroServiceApiHeaders } from '../api/getNblyMicroServiceApiHeaders';
import { $ } from "../third-party-imports"
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { start_loader, stop_loader } from "../util";

//Mark as all read notification
export function notificationsAsReadByUser()
{
    // start_loader();
    $.ajax({
        url: apiConfig.notificationApi + 'WebNotificationsAsRead'+"?"+getNblyApiHeaders(),
        type: 'PUT',
        headers: getNblyMicroServiceApiHeaders(),
        success: function (data, status)
        {
            // stop_loader();
            if (data == "success")
            {
                $('.nbly_notification li').remove();
                getCountAllNotification();
            }
        },
        error: function (error)
        {
            // stop_loader();
            console.log(error);
        }
    });
}
