import { chunkArray, get_data, store_data, stop_loader} from "../util";
import { storeProTypeID} from ".";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config/apiConfig";
export function serviceEntryButton(searchTerm:any){
    $.ajax({
    type: "GET",
    url: apiConfig.serviceProtypeAggregates + '?ZipCode='+searchTerm+'&ServiceModifier=4&Take=12&apikey=' + window.DEFINE_VALUE.JS_API_KEY+'&'
    +getNblyApiHeaders(),
    dataType: 'json',
    success: function (result, status, xhr)
    { 
        stop_loader();
        if (result.length == 0){
            $('.tab-pane.fieldset').removeClass('active');
            $('#no-search-result').addClass('active');
            $('.noResultCatSpan').hide();
            $('.noResultZipSpan').text(searchTerm);
            $('.modal-fullscreen.serviceModalDialog .backButton').css("display","block"); 
        }
        else{
            window.objOSGlobal.requestBodyValues.PostalCode = searchTerm;
            window.objOSGlobal.requestBodyValues.serviceModifier = "Residential";
            sessionStorage.setItem('ServiceModifierValue',"Residential");
            $('#located-page').removeClass('active');
             $('#service-search-page').addClass('active');
            $('.modal-fullscreen.serviceModalDialog .backButton').css("display","block");
            var splitCount;
            if (result.length < 3) splitCount = 1;
            else splitCount = Math.round(result.length / 3);
            result = chunkArray(result, splitCount);

            var colmd4Elements = $.map(result, function (el){
                var sub_ul = $('<ul class="list-unstyled services-link-list"></ul>  ');
                $.each(el, function (index, service){
                    let liEl = window.document.createElement("li");
                    liEl.id = `serviceProLi${service.proTypeId}`;
                    liEl.addEventListener("click", (event: any) => {
                        return storeProTypeID(service.proTypeId, event)
                    });
                    liEl.innerHTML = `<a href="#">${service.proTypeName}</a>`;
                    var sub_li = $(liEl);
                    sub_ul.append(sub_li);
                });
                var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
                return colmd4;
            })

            var rowClass = $('<div class="row service-search-row" id="service-search-row"> <div> ').append(colmd4Elements);
            var serviceSearchActive = $("#service-search-page").hasClass("active");
            if(serviceSearchActive)
                $("#service-protype-link-list").append(rowClass);
            else
                $("#serviceProtypeLinkListFinal").append(rowClass);
        }
    },
    error: function (data, status, error){
        stop_loader();
        $(this).removeClass('prevent-click');
        if (data.status === 404)
        {
         $('.tab-pane.fieldset').removeClass('active');
         $('#no-search-result').addClass('active');
         $('.noResultZipSpan').text(get_data('zipcode'));
         $('.noResultCatSpan').show();
         $('.modal-fullscreen.serviceModalDialog .backButton').css("display","block");
         store_data('located-page', 'locatedPage');
        }
    }
});
}