
export function checkZip(value: any, btn: any)
{
    try {
    var currentLocation = document.getElementById("currentLocationId");
    var notValidZipCode = document.getElementById("notValidZipCode");
    if (notValidZipCode)
    {
        document.getElementById("notValidZipCode").style.display = "none";

    }
    var zipRegex = new RegExp(/^(\d{5}(-\d{4})?|[A-Z]\d[A-Z] ?\d[A-Z]\d)$/);
    if (currentLocation)
    {
        currentLocation.style.display = "block";
    }
    if (value !== '' && zipRegex.test(value))
    {
        btn.disabled = false;
        btn.classList.add("active-btn");
        if (notValidZipCode) {
        notValidZipCode.style.display ="none";
        }
    } else if (value === '')
    {
        btn.disabled = true;
        btn.classList.remove("active-btn");
        if (notValidZipCode) {
        notValidZipCode.style.display ="none";
        }
        if (currentLocation) {
        currentLocation.style.display = "none";
        } 
    }
    else
    {
        btn.disabled = true;
        btn.classList.remove("active-btn");
        if (notValidZipCode) {
        notValidZipCode.style.display ="block";
        }
    }
}catch(e){
    //console.log(e)
}
}
