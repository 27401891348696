import { getBlogRow } from "./getBlogRow";
import { $ } from "../third-party-imports";
export function createArticleList()
{
    const blogs = window.g2Data.response.docs;
    const blogWrapper = $('.search-result-wrap');
    blogs.forEach(function (e: any, i: any)
    {
        const row = getBlogRow(e, i);
        blogWrapper.append(row);
    });
}
