import { $ } from "../third-party-imports";
import { apiConfig } from "../config/apiConfig"
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { get_data } from "../util";
import { start_loader, stop_loader, errorModal, hideCommonRow} from "../util";
import { HEADERS } from "../api";

if ($('#datepicker').length) {
    $(function () {
        $("#datepicker").datepicker({
            autoSize: true,
            dayNames: $.datepicker.regional["en"].dayNames,
            monthNames: $.datepicker.regional["en"].monthNames
        })
    });
}

$('body').on('keyup', function () {
    let $input = $(this);
    let value = $input.val() as string;
    let length = value.length;
    let inputCharacter = parseInt(value.slice(-1));

    if (!((length > 1 && inputCharacter >= 0 && inputCharacter <= 9) || (length === 1 && inputCharacter >= 7 && inputCharacter <= 9))) {
        $input.val(value.substring(0, length - 1));
    }
});

function daysInMonth (month: any, year: any) {
    return new Date(year, month, 0).getDate();
}

export const schedulerState = {
    profileObj: {} as any,
    dateStart: '' as any,
    page: 0,
    numberOfSlot: 1,
    incr: 1,
    decr: 0,
    selectedDate: undefined as Date,
    postalCode: "",
    apiCal:1,
}
var slotsByDate = [] as any;
var apiCall = 1;
var selectedDate:any;
var slotIndex:any;
var disableNextBtn:Boolean = false;

function nextPrevDatesValues(year:any,month:any,dateForUrl:any,callback:any){
    var isEstimate = get_data("selectedAboutHome") == "recurring" ? true : false;
    var isSchedule = window.objOSGlobal.requestBodyValues.ConceptCalledId == 1 ? "&IsEstimate="+isEstimate : "";
    var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId;
    var serviceSkuId = window.objOSGlobal.requestBodyValues.ServiceSkuId;
    var zipCode = localStorage.getItem('zipcode').replace(/['"]+/g, '');
    var pcode = zipCode.length == 6 ? zipCode.replace(/^(.{3})(.*)$/, "$1 $2") : zipCode;
    var date = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    var numberOfDays = 31;
    var dateForUrl;
    if (year == date.getFullYear() && month == (date.getMonth() + 1)){
        numberOfDays = daysInMonth(month, year);
    } else {
        numberOfDays = daysInMonth(month, year);
    }
    start_loader();
    $.ajax({
       url: apiConfig.techAvailability + '?PostalCode='+pcode+'&WebLocationId='+webLocationId+'&NumberOfDays='+numberOfDays+'&ServiceSkuId='+serviceSkuId+'&DateStart='+dateForUrl+isSchedule+'&apikey='+window.DEFINE_VALUE.JS_API_KEY+'&'+getNblyApiHeaders(),
       type: "GET",
       headers: HEADERS,
       dataType: 'json',
       success: function (results) {
        stop_loader();   
        // localStorage.setItem("scheduleData", JSON.stringify(results));
        // var resultData = JSON.parse(localStorage.getItem('scheduleData'));
        if(results.resultCollection.length !=0){ 
            results.resultCollection.forEach((element:any) => {
                var date = element.startDate.split('T')[0]
                var item = slotsByDate.find((itm:any)=>itm.date==date);
                if(!item){
                item = {date,slots:[]}
                slotsByDate.push(item);
                }
                item.slots.push(element)
            });
            dateResultDisplay(slotIndex);
        }else{
            disableNextBtn = true;
            callback();
        }
        // slotIndex = slotsByDate.findIndex((itm:any)=>itm.date==results.resultCollection[0].startDate.split('T')[0])
       
       },
       error: function (data, status, error) {
           stop_loader();
           if(status == "error"){
            let errval = "";
            hideCommonRow();
            $('#calendar-screen').removeClass('active')
            errorModal(errval)
           }
           console.log(data);
       }
   });
}

function generateDateBlock(month:any, date:any, week:any, id:any, isactive:any){
    var activecls = "";
    if(isactive){
        activecls = 'active';
    }
    return '<button class="date-details ' + activecls + '" data-index="id'+id+'">' +
        '<strong class="body-text-sm">' + month + ' <span>' + date + '</span></strong>' +
        '<span class="body-text-sm">' + week + '</span>' +
        '</button>'
}

function generateSlotButtonBlock(btnText:any, isDisable:any, obj:any,dt:any){
    var modifiedTime = btnText.replace(/(?:^|\W)AM|PM(?:$|\W)/i,"");    
    var displayTime = obj.weeks + ' ' + obj.monthName + ' ' + obj.dates + ', ' + (isDisable ? 'Unavailable' : modifiedTime); //obj.startTimehour + '-' + obj.endTimehour;
    return '<button  data-scheduleId="' + dt.scheduleID + '" data-selectedDate="'+dt.startDate+'" data-time="'+displayTime+'"  class="calendar-btn ' + (isDisable ? 'disble-btn' : '') + '" ' + (isDisable ? 'disabled' : '') + '>' + (isDisable ? 'Unavailable' : modifiedTime) + '</button>'
}

function generateSlotRows(btnArray:any, colId:any){
    return '<div class="calendar-col" id="id'+colId+'">' + btnArray.join('') + '</div>';
}

$(".calendar-row").on("click", ".calendar-btn", function (){
    $('.calendar-btn').removeClass('active-cal');
    if ($(this).hasClass('disabled')){
        return;
    }
    var displayText = $(this).attr('data-time');
    var schduleId = $(this).attr('data-scheduleid');
    selectedDate = $(this).attr('data-selectedDate');
    window.objOSGlobal.requestBodyValues.ScheduleId = schduleId;
    $(this).addClass('active-cal');
    var Preferredtime = $('.active-cal').html();
    sessionStorage.setItem('CTime', Preferredtime);
    $('.selected-date-txt').text(displayText);
    $('.date-details.selected-date').removeClass('hidden');
    $('#calendar-screen .next').removeClass('disble-btn').prop('disabled', false);
});

$(".date-details-wrapper").on("click", ".date-details", function(e){
    e.preventDefault();
    var datesIndexId = $(this).attr("data-index");
    if (window.innerWidth < 767) {        
        $('.calendar-col').hide();
        $('#'+datesIndexId).show();
    }
    else{
        $('.calendar-col').show();
    }
});

$('#calendar-screen .js-next-btn').on('click', function() {
    window.objOSGlobal.paramsUI.selectedDate = selectedDate;
});


$('.calendar-wrapper .next-btn').on('click', function() {
    slotIndex = slotIndex + 5;
    disableNextBtn = false;
    dateResultDisplay(slotIndex);
});

$('.calendar-wrapper .prev-btn').on('click', function() {
    slotIndex = Math.max(slotIndex - 5, 0)
    dateResultDisplay(Math.max(slotIndex));

});

function lastUpdateData(count:any,finalData:any){
    var slotRows=[];
    var datesArray=[];
    for(var i=0+count;i<finalData+count;i++){
        var stringDate = slotsByDate[i].date;
        var monthName = new Date(stringDate).toLocaleString("default", { month: "short" });
        var weeks = new Date(stringDate).toLocaleDateString('en-US', { weekday: 'short' });
        var dates = new Date(stringDate).getDate();
        var allAvailable = slotsByDate[i].slots.reduce((a:any,b:any)=>a||b.isAvailable,false);
        datesArray.push(generateDateBlock(monthName, dates, weeks,i,allAvailable));
        var temp:any = [];
        slotsByDate[i].slots.forEach((element:any) => {
            temp.push(generateSlotButtonBlock(element.displayText, !element.isAvailable,{ monthName, weeks, dates},element));
        });
        slotRows.push(generateSlotRows(temp,i));
    }
    $('.date-details-wrapper').html(datesArray.join(''));
    $('.calendar-row').html(slotRows.join(''));
}

function dateResultDisplay(count:any){
    var slotRows=[];
    var datesArray=[];
    
    for(var i=0+count;i<5+count;i++){
        if(i>=slotsByDate.length){
            var dateArray =  slotsByDate[slotsByDate.length-1].date.split('-');
            var mm = dateArray[1];
            var dd = parseInt(dateArray[2]) + 1;
            var yyyy = dateArray[0]
            // var dateUrl = mm+'/'+dd+'/'+yyyy;
            // nextPrevDatesValues(new Date().getFullYear(), new Date().getMonth(),dateUrl);
            // return;
            var datesInMonth  = daysInMonth(mm,yyyy);
            if(dd == datesInMonth){
                dd = 1;
                mm = parseInt(dateArray[1])+1;
            }else{
                dd = dd + 1;
            }
            var dateUrl = mm+'/'+dd+'/'+yyyy;
            if(!disableNextBtn){
                nextPrevDatesValues(new Date().getFullYear(), new Date().getMonth(),dateUrl,function(){
                    var finalData = slotsByDate.length - count;
                    if(finalData > 0){
                        lastUpdateData(count,finalData);
                        return;
                    }
                });
            }
            return;
        }
        var stringDate = slotsByDate[i].date;
        var monthName = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleString("default", { month: "short" });
        var weeks = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).toLocaleDateString('en-US', { weekday: 'short' });
        // var dates = new Date(stringDate).getDate();
        var dates = new Date(stringDate.replace(/-/g, '\/').replace(/T.+/, '')).getDate();
        var allAvailable = slotsByDate[i].slots.reduce((a:any,b:any)=>a||b.isAvailable,false);
        datesArray.push(generateDateBlock(monthName, dates, weeks,i,allAvailable));
        var temp:any = [];
        slotsByDate[i].slots.forEach((element:any) => {
            temp.push(generateSlotButtonBlock(element.displayText, !element.isAvailable,{ monthName, weeks, dates},element));
        });
        slotRows.push(generateSlotRows(temp,i));
    }
    $('.date-details-wrapper').html(datesArray.join(''));
    $('.calendar-row').html(slotRows.join(''));
}

export function refreshCalendarData(year:any, month:any) {
    slotsByDate=[];
    var postalCode = localStorage.getItem('zipcode').replace(/['"]+/g, '');
    var pcode = postalCode.length == 6 ? postalCode.replace(/^(.{3})(.*)$/, "$1 $2") : postalCode;
    var isEstimate = get_data("selectedAboutHome") == "recurring" ? true : false;
    var isSchedule = window.objOSGlobal.requestBodyValues.ConceptCalledId == 1 ? "&IsEstimate="+isEstimate : "";
    var webLocationId = window.objOSGlobal.requestBodyValues.WebLocationId ? window.objOSGlobal.requestBodyValues.WebLocationId : '8113';
    var serviceSkuId = window.objOSGlobal.requestBodyValues.ServiceSkuId;
    
    var date = new Date();
    var today = new Date();
    date = new Date(date.getFullYear(), date.getMonth(), 1);
    var numberOfDays = 31;
    var dd = today.getDate();
    var mm = today.getMonth()+1; 
    var yyyy = today.getFullYear();
    if(dd<10){dd='0'+dd;}
    if(mm<10){mm='0'+mm;} 
    today = mm+'/'+dd+'/'+yyyy;
    if (year == date.getFullYear() && month == (date.getMonth() + 1)) {
        numberOfDays = daysInMonth(month, year);
    } else {
        numberOfDays = daysInMonth(month, year);
    }
    start_loader();
        $.ajax({
            url: apiConfig.techAvailability + '?PostalCode='+pcode+'&WebLocationId='+webLocationId+'&NumberOfDays='+numberOfDays+'&ServiceSkuId='+serviceSkuId+'&DateStart='+today+isSchedule+'&apikey='+window.DEFINE_VALUE.JS_API_KEY+'&'+getNblyApiHeaders(), 
            type: "GET",
            headers: HEADERS,
            dataType: 'json',
            success: function (results) {
                stop_loader();
                localStorage.setItem("scheduleData", JSON.stringify(results));
                if (results.resultCollection.length > 0) {
                    var currentIndex = results.resultCollection.find((itm:any)=>itm.isAvailable);
                }
                if(currentIndex){
                        var resultData = JSON.parse(localStorage.getItem('scheduleData'));
                        resultData.resultCollection.forEach((element:any) => {
                            var date = element.startDate.split('T')[0]
                            var item = slotsByDate.find((itm:any)=>itm.date==date);
                            if(!item){
                            item = {date,slots:[]}
                            slotsByDate.push(item);
                            }
                            item.slots.push(element)
                        });
                        slotIndex = slotsByDate.findIndex((itm:any)=>itm.date==currentIndex.startDate.split('T')[0]);
                        dateResultDisplay(slotIndex);
                }else{
                    $('.tab-pane.fieldset').removeClass('active');
                    $('#no-search-result').addClass('active');
                    $('#no-search-result .head-title.dark-blue-clr').text('Please call us and we’ll help you find a time that works for you.');
                    $('.progress, .progressbar').hide();
                    $('.modal-header-two').hide();
                    $('.modal-header-one').show();
                }
            },
            error: function (data, status, error) {
                stop_loader();
                localStorage.removeItem("scheduleData");
                if (apiCall < 2) {
                    refreshCalendarData(new Date().getFullYear(), new Date().getMonth() + 1);
                    apiCall++;
                }
                else {
                    if(status == "error"){
                        let errval = "";
                        hideCommonRow();
                        $('#calendar-screen').removeClass('active')
                        errorModal(errval)
                       }
                    // var host = window.location.hostname;
                    // var brand = window.location.href.split("/")[3] == 'ca' ? 'ca/' + window.location.href.split("/")[4].replace("#","") + '/error' : window.location.href.split("/")[3].replace("#","") + '/error';
                    // window.location.href  = 'https://'+ host + '/'+ brand;
                    apiCall = 1; 
                }
            }
        });
}