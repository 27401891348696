import { HEADERS } from "../api";
export function getFranchiseWeblocationID(addressParam:any, successHandler:any){
    var conceptID = window.objOSGlobal.requestBodyValues.conceptId
    var postalCode =window.objOSGlobal.requestBodyValues.PostalCode;
    var apiUrl = 'https://api2-test-unifiedsyncplatform.dwyergroup.com/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey='+window.DEFINE_VALUE.JS_API_KEY+'&Address=sAddressParamValue&IsRoundRobin=true&conceptID=' + conceptID;
    apiUrl = apiUrl.replace("sAddressParamValue", encodeURIComponent(addressParam));
    $.ajax({
        type: "GET",
        url: apiUrl,
        headers: HEADERS,
        dataType: 'json',
        success: successHandler,
        error: function (error){
            $('.progressbar, .progress').hide();
            $('#js-schedulerDialog .modal-header-two').css('display', 'none');
            $('#js-schedulerDialog .modal-header-one').show();
            $('#no-search-result .head-title').html(`We’re sorry. We don’t currently provide service to <span class="noservice-zipcode">${postalCode}</span>`);
            $('#no-search-result').addClass('active');
        }
    });
}