
import { HEADERS } from "../api";
import { $ } from "../third-party-imports";

export function getBrandList()
{
    var country = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    var language = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") === "ca" ? "en-ca" : "en-us";
    /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
    $.ajax({
        url: window.DEFINE_VALUE.JS_redirectUri + country+"/"+language+"/_assets/brands.json",
        /*headers: {
         Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer_token')).access_token
        },*/
        type: "GET",
        headers: HEADERS,
        dataType: 'json',
        success: function (result, status, xhr){
            window.brandList = result;
        },
        error: function (e: any){
            //console.log(e.message);
        }
    });
}
