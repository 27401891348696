
export const loginRequest = {
    scopes: ["openid"]
};

export const tokenRequest = {
    scopes: [window.DEFINE_VALUE.JS_B2C_SCOPES]
}


