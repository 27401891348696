import { addClassInModalWindow } from "./addClassInModalWindow";
import { getState } from "./getState";
import { get_data } from "./get_data";
import { emptyAddressFilds } from "./emptyAddressFilds";
import { $ } from "../third-party-imports"
import {serviceResults} from "./serviceResults";
import { apiConfig } from "../config";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { store_data, start_loader,stop_loader } from "../util";


export function serviceCategoryByName(zipcode: any, serviceID: any, serviceCategoryValue: any,isRoundRobin:any){    
    store_data(serviceID, 'serviceID'); // reset serviceID value
    store_data(serviceCategoryValue, 'serviceName');
    start_loader();
    $.ajax({
        url: apiConfig.FranchiseLookUpByServiceAddress + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY + "&Address=" + zipcode + "&serviceID=" + serviceID + "&IsRoundRobin="+isRoundRobin+"&RequireAddressOnPartialPostalCode=true&"+getNblyApiHeaders(),
        type: "GET",
        dataType: 'json',
        headers: {
            'Cache-Control': 'max-age=0' 
        },
        success: async function (result: any, status: any, xhr: any)
        {
            stop_loader();            
            if (result.length != 0){
                if(result.length > 1 && isRoundRobin == false){
                serviceCategoryByName(zipcode, serviceID, serviceCategoryValue, true);
                }
                else{
                    $(".tab-pane.fieldset").removeClass("active");
                    $('#js-schedulerDialog').modal("show");
                    $("#service-slide-4").addClass("active");
                    $("#Zip-Code").val(zipcode);
                    await serviceResults(result);
                    addClassInModalWindow("service-slide-4");
                }
            }
            else{
                $(".tab-pane.fieldset").removeClass("active");
                $(".servive-form-wrapperBox").removeClass("active");
                if(!$('.scheduler-modal').is(':visible')){
                $('#js-schedulerDialog').modal("show");
                }
                $('#no-search-result').addClass("active");
                if (serviceCategoryValue) { $('.noResultCatSpan').text(serviceCategoryValue); }
                $('.noResultZipSpan').text(get_data('zipcode'));
                addClassInModalWindow("no-search-result");
            }
        
        },
        error: function (data: any) {
            stop_loader();
            var duplicate = data.responseJSON; 
            if (duplicate?.Message == "Full address is needed to narrow down results"){
                $('#service-address-bumper .next').addClass('disble-btn').prop('disabled',true);
                $(".tab-pane.fieldset").removeClass("active");
                emptyAddressFilds();
                if(!$('.scheduler-modal').is(':visible')){
                    $('#js-schedulerDialog').modal("show");
                }
                $("#service-address-bumper").addClass("active");
                $("#Zip-Code").val(zipcode);
                addClassInModalWindow("service-address-bumper");
                getState();
                $('#service-address-bumper .next').removeClass('js-next-btn')
                setAddressBumperValue(serviceCategoryValue);
            }
        }
    });
}

function setAddressBumperValue(serviceName:String){
    $('#service-address-bumper .next').off().on('click',function(){
        let setAddressBumperObj:Object = {}
        let address = $('#service-address-bumper input[name="address"]').val();
        let apartment = $('#service-address-bumper input[name="address2"]').val();
        let city = $('#service-address-bumper input[name="city"]').val();
        let state = $('#service-address-bumper select[name="state"]').val();
        let zipCode = $('#service-address-bumper input[name="zipcode"]').val();

        setAddressBumperObj.address = address;
        setAddressBumperObj.apartment = apartment;
        setAddressBumperObj.city = city;
        setAddressBumperObj.state = state;
        setAddressBumperObj.zipcode = zipCode;
        store_data(setAddressBumperObj,'addressBumper');
        apartment = apartment ? apartment + " ": "";
        let addressParam = address + " " + apartment +city + " " + state + " " + zipCode;
        
        let serviceID = get_data('serviceID');
        serviceCategoryByName(encodeURIComponent(addressParam),serviceID,serviceName,true)
    });
    $('#service-address-bumper .back').on('click',function(){
        $(".tab-pane.fieldset").removeClass("active");
        $('#service-category-page').addClass('active');
    });
}