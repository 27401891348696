import { neiLocalStorage } from "..";
import { getMSALInstance } from "../config";

export function linkingSocial(userEmailAddress: any, type: any, subObjId: any)
{
    const myMSALObj = getMSALInstance();
    const linkingRequest = {
        scopes: ["openid"],
        authority: window.DEFINE_VALUE.JS_APP_ADB2C_INSTANCE + "/" + window.DEFINE_VALUE.JS_APP_ADB2C_TENANT + "/" + window.DEFINE_VALUE.JS_linkAccountPolicy,
        extraQueryParameters: { ObjId: subObjId, LogonEmail: userEmailAddress, SocialAccountType: type }
    };
    localStorage.setItem('previousToken', neiLocalStorage.accessToken);
    myMSALObj.loginRedirect(linkingRequest);
}
