import { $ } from "../third-party-imports"


export function setProfileEmail(profile: any)
{
    $('.profileEmail').append('<div class="text-withedit primaryEmail">' +
        '<div class="label-name">' +
        '<div class="labelTxtBox">' +
        '<div class="labelTxt">' + profile.email + '</div>' +
        '</div>' +
        '<div class="primaryWithStar">' +
        '<img src="/us/en-us/_assets/images/gold-star-outline.svg" alt="gold star" loading="lazy">Primary' +
        '</div>' +
        '</div>' +
        '</div>'
    );
}
