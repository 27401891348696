export function getAboutHomeValues(){
    var MlyHomeDetails:any
    var modifier = sessionStorage.getItem('ServiceModifierValue');
    var squareFeet = $('#about-home-screen input[name="squarefeet"]').val();
    var bedrooms = $('select[name="bedrooms"] option:selected').val();
    var bathrooms = $('select[name="bathrooms"] option:selected').val();
    if(modifier == 'Commercial'){
        MlyHomeDetails =  bedrooms + ' Office(s), ' +bathrooms + ' Bath(s), '+ squareFeet+' sq.ft';
    }else{
        MlyHomeDetails =  bedrooms + ' Bedrooms(s), ' +bathrooms + ' Bath(s), '+ squareFeet+' sq.ft';
    }
    sessionStorage.setItem('MlyHomeDetails',MlyHomeDetails);
    var cleaning = $('input[name="chkcleaning"]:checked').siblings('.radio-txt').text();

    window.objOSGlobal.requestBodyValues.EstimateSquareFeet = squareFeet;
    window.objOSGlobal.requestBodyValues.EstimateBedrooms = bedrooms;
    window.objOSGlobal.requestBodyValues.EstimateBathrooms = bathrooms;
    window.objOSGlobal.requestBodyValues.Notes = cleaning;
}