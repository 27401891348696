
export function chunkArray(myArray: any[] | JQuery, chunk_size: number)
{
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [];

    for (index = 0; index < arrayLength; index += chunk_size)
    {
        let myChunk = myArray.slice(index, index + chunk_size);
        // Do something if you want with the group
        tempArray.push(myChunk);
    }

    return tempArray;
} // Split in group of 4 items 'var result = chunkArray(result, 4); '   // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]    

