import { stripURL } from "./stripURL";

/* * * * * * * * * * * * * * * * *
 * Pagination
 * javascript page navigation
 * * * * * * * * * * * * * * * * */
export const Pagination = {
    code: '',
    size: 0,
    page: 1,
    step: 3,
    e: (undefined as any),
    innerHTML: "",

    // --------------------
    // Utility
    // --------------------

    // converting initialize data
    Extend: function(data: any) {
        data = data || {};
        Pagination.size = data.size || 0;
        Pagination.page = data.page || 1;
        Pagination.step = data.step || 3;
    },

    // add pages by number (from [s] to [f])
    Add: function(s: any, f: any) {
        for (var i = s; i < f; i++) {
            Pagination.code += `<li class="page-item"><a id="pagination${i}" href="${stripURL(window.location.href)}/page/${i}" class="page-link">${i}</a></li>`;
        }
    },

    // add last page with separator
    Last: function() {
        Pagination.code += `<li class="page-item">[…]</li><li class="page-item"><a id="paginationLast" href="${stripURL(window.location.href)}/page/${Pagination.size}" class="page-link">${Pagination.size}</a></li>`;
    },

    // add first page with separator
    First: function() {
        Pagination.code += `<li class="page-item"><a id="pagination1" href="${stripURL(window.location.href)}/page/1" class="page-link">1</a></li><li class="page-item">[…]</li>`;
    },
    // --------------------
    // Handlers
    // --------------------

    // change page
    Click: function() {
        Pagination.page = +this.innerHTML;
        Pagination.Start();
    },

    // previous page
    Prev: function() {
        Pagination.page--;
        if (Pagination.page < 1) {
            Pagination.page = 1;
        }
        Pagination.Start();
    },

    // next page
    Next: function() {
        Pagination.page++;
        if (Pagination.page > Pagination.size) {
            Pagination.page = Pagination.size;
        }
        Pagination.Start();
    },
    // --------------------
    // Script
    // --------------------

    // binding pages
    Bind: function() {
        var a = Pagination.e.getElementsByTagName('a');
        for (var i = 0; i < a.length; i++) {
            if (+a[i].innerHTML == Pagination.page) {
                a[i].parentNode.classList.add('blackColor');
            }
        }
    },
    // write pagination
    Finish: function() {
        Pagination.e.innerHTML = Pagination.code;
        Pagination.code = '';
        Pagination.Bind();
    },
    // find pagination type
    Start: function() {
        if (Pagination.size < Pagination.step * 2 + 6) {
            Pagination.Add(1, Pagination.size + 1);
        }
        else if (Pagination.page < Pagination.step * 2 + 1) {
            Pagination.Add(1, Pagination.step * 2 + 4);
            Pagination.Last();
        }
        else if (Pagination.page > Pagination.size - Pagination.step * 2) {
            Pagination.First();
            Pagination.Add(Pagination.size - Pagination.step * 2 - 2, Pagination.size + 1);
        }
        else {
            Pagination.First();
            Pagination.Add(Pagination.page - Pagination.step, Pagination.page + Pagination.step + 1);
            Pagination.Last();
        }
        Pagination.Finish();
    },
    // --------------------
    // Initialization
    // --------------------

    // binding buttons
    Buttons: function(e: any) {
        var nav = e.getElementsByTagName('a');
        nav[0].addEventListener('click', Pagination.Prev, false);
        nav[1].addEventListener('click', Pagination.Next, false);
    },

    // create skeleton
    Create: function(e: any) {
        const nextPage = Pagination.page == Pagination.size ? '#' : `${stripURL(window.location.href)}/page/${parseInt(Pagination.page as any) + 1}`;
        const prevPage = Pagination.page == 1 ? '#' : `${stripURL(window.location.href)}/page/${parseInt(Pagination.page as any) - 1}`;
        var html = [
            `<li class="page-item backLink"><a id="paginationBack" href="${prevPage}" class="page-link ${prevPage == '#' ? 'pagination-disable-back blackColor' : 'pagination-enable-back' }">Back</a></li>`, // previous button
            '<span></span>',  // pagination container
            `<li class="page-item nextLink"><a id="paginationNext" href="${nextPage}" class="page-link ${nextPage == '#' ? 'pagination-disable-next blackColor' : 'pagination-enable-next' }">Next</a></li>`  // next button
        ];
        e.innerHTML = html.join('');
        Pagination.e = e.getElementsByTagName('span')[0];
        // Pagination.Buttons(e);
    },

    // init
    Init: function(e: any, data: any) {
        Pagination.Extend(data);
        Pagination.Create(e);
        Pagination.Start();
    }
};