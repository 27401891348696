
import { $ } from "../third-party-imports"

export function getCurrentContext()
{
    $('article .learn-more .whiteTextLink').click(function (e)
    {
        localStorage.setItem('current_context_url', window.location.href);
    });
}
