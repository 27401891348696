export function youtubeInit() 
{
    const youtubeElements = Array.from(document.querySelectorAll( ".youtube" )) as HTMLElement[];	

    for (const youtube of youtubeElements) 
    {
		const source = `https://img.youtube.com/vi/${youtube.dataset.embed}/sddefault.jpg`;		
		const image = new Image();
        image.src = source;

        image.addEventListener("load", () => {  youtube.appendChild( image ); });		

        youtube.addEventListener("click", (e) => {
            var youtubeSrc = (e.currentTarget as HTMLElement).getAttribute('data-embed');
            const iframe = globalThis.document.createElement( "iframe" );
            iframe.setAttribute( "frameborder", "0" );
            iframe.setAttribute( "allowfullscreen", "" );
            iframe.setAttribute( "allow", "autoplay");
            iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ youtubeSrc +"?autoplay=1&rel=0&showinfo=0" );
            youtube.innerHTML = "";
            youtube.appendChild( iframe );
        });	
	};	
}
