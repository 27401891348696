import { HEADERS } from "..";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { $ } from "../third-party-imports"
import { start_loader, stop_loader } from "../util";

$(document).ready(function ()
{
    // $(document).on('click', '#primaryHerolocalHelpBtn', function ()
    // {
    //     $('a#js-serviceModal').addClass('prevent-click')
    // });

    if ($('#main').find('div.market-section').length !== 0)
    {
        var marketId = parseInt($("[name='MarketID']").val() as string);
        var requestBody = { "customerMarketingRegionId": marketId, "isIncludeLatLong": true };
        start_loader();
        $.ajax({
            type: "POST",
            headers: HEADERS,
            url: apiConfig.serviceMarketWeblocation + '?apikey=' + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
            data: JSON.stringify(requestBody),
            dataType: 'json',
            success: function (result, status, xhr)
            {
                stop_loader();
                $('.infoContainer.market-section .flex-wrapper').empty();
                $('.infoContainer.market-section .videoContainer iframe').css("width", "100%");
                $('.infoContainer.market-section .videoContainer iframe').css("height", "341px");
                $('.infoContainer.market-section .videoContainer iframe').css("position", "absolute");

                $.each(result.webLocations, function (index, weblocation)
                {
                    var ul = $('<ul class="dottedBulletList list-inline officeList">');
                    $.each(weblocation.cities.sort(), function (index, service)
                    {
                        var li = $('<li class="list-inline-item"><a href="#">' + service + '</a></li>');
                        ul.append(li);
                    });

                    var span = $('<span class="ofice-label">' + weblocation.webLocationName + '</span>');
                    var divEle = $('<div class="infoContainerLeft"></div>');
                    $(divEle).append(span);
                    $(divEle).append(ul);

                    $('.infoContainer.market-section .flex-wrapper').append(divEle);
                });

                if (result.webLocations.length > 2)
                {
                    $('.infoContainer.market-section .infolinksidemedia').hide();
                    $('.infoContainer.market-section .infoContainerLeft').css('padding-right', '20%');
                    $('.infoContainer.market-section .infoContainerLeft').css('padding-left', '0px');

                }
            },
            error: function (xhr, status, error)
            {
                stop_loader();
                console.log(xhr, status, error);
            }
        });
    }

    // var pageURL = window.location.href;
    // var lastItem = pageURL.split('/')[3];
    var afterCom =window.location.href.split('.com')[1];
    var isWebSite =  afterCom.split('/')[1].replace(/[^a-zA-Z ]/g, "") === "ca" ? "ca" : "us";
    if (isWebSite == "us")
    {
        $('.find-row').append('<span class="invalid-zip-meg invalid-feedback">Invalid ZIP/Postal Code</span>');
        $('.formZipCodeBox').append('<span class="invalid-zip-meg invalid-feedback">Invalid ZIP/Postal Code</span>');

        //$('.invalid-zip-meg').hide()

        // $('.invalid-zip-meg').hide()
        $('.postalSearchInput').on('keyup', function ()
        {
            var lengthVal = ($(this).val() as string).length
            if (lengthVal <= 4)
            {

                $('.invalid-zip-meg').css('visibility','visible');
                $('.findHelpBtnReuseClass').addClass('disble-btn').removeClass('active-btn')
                $('.findHelpBtnReuseClass').prop('disabled', true);
            } 
            else
            {

                $('.invalid-zip-meg').css('visibility','hidden');
                $('.findHelpBtnReuseClass').removeClass('disble-btn').addClass('active-btn')
                $('.findHelpBtnReuseClass').prop('disabled', false);
            }

            if (lengthVal == 0)
            {
                $('.invalid-zip-meg').css('visibility','hidden');

            }
        });

        $<HTMLInputElement>('.postalSearchInput').keypress(function (e)
        {
            var regex = new RegExp("^[0-9]$");
            var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (!regex.test(key))
            {
                e.preventDefault();
                return false;
            }
            /*if(charLength > 4){
             $('.invalid-zip-meg').hide()
             
             return false;  
            }*/

            if (this.value === "0000" && e.which == 48)
            {
                return false;
            }
        });

        // $(".postalSearchInput").on("paste", function ()
        // {
        //     return false;
        // });
    } 
    else
    {
        /// this will run for CANADA

        $<HTMLInputElement>('.postalSearchInput').keypress(function (e)
        {
            if (this.value === "00000" && e.which == 48)
            {
                return false;
            }
        });
        $('.container').addClass('canada-sites')
        $('.find-row').append('<span class="invalid-zip-meg invalid-feedback">Invalid ZIP/Postal Code</span>');
        $('.formZipCodeBox').append('<span class="invalid-zip-meg invalid-feedback">Invalid ZIP/Postal Code</span>');

        $(document).on('keyup', '.postalSearchInput', function (e)
        {
            var lengthVal = ($(this).val() as string).length
            if (lengthVal <= 5)
            {
                $('.invalid-zip-meg').css('visibility','visible');
                // console.log('canad sites less than 5')
                $('.findHelpBtnReuseClass').addClass('disble-btn').removeClass('active-btn')
                $('.findHelpBtnReuseClass').prop('disabled', true);
            } else
            {
                $('.invalid-zip-meg').css('visibility','hidden');
                // console.log('canad sites grtr than 5')
                //  $('.findHelpBtnReuseClass').removeClass('disble-btn').addClass('active-btn')
                //  $('.findHelpBtnReuseClass').prop('disabled', false);
            }
            if (lengthVal == 0)
            {
                $('.invalid-zip-meg').css('visibility','hidden');
            }
        });
        // $(document).click(function ()
        // {
        //     $('.findHelpBtnReuseClass').addClass('disble-btn').removeClass('active-btn')
        //     $('.findHelpBtnReuseClass').prop('disabled', true);
        // });


        var checkZip = function (value: any, btn: any)
        {
            var currentLocation = document.getElementById("currentLocationId");
            var notValidZipCode = document.getElementById("notValidZipCode")
            if (notValidZipCode)
            {
                document.getElementById("notValidZipCode").style.display = "none";
            }

            // var zipRegex = new RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/);
            var zipRegex = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
            if (currentLocation)
            {
                currentLocation.style.display = "block";
            }
            if (value !== '' && zipRegex.test(value))
            {
                btn.disabled = false;
                btn.classList.add("active-btn");
            } else if (value === '')
            {
                btn.disabled = true;
                btn.classList.remove("active-btn");

                currentLocation.style.display = "none";
            } else
            {
                btn.disabled = true;
                btn.classList.remove("active-btn");
            }
        }
    }
    var findLocalHelpBtn = document.getElementById('primaryHerolocalHelpBtn');
    var zipCode = document.getElementById("primaryHeroInput") as HTMLInputElement;
    if (zipCode)
    {
        zipCode.addEventListener('input', function ()
        {
            if (isWebSite !== 'us')
                checkZip(zipCode.value, findLocalHelpBtn);

        });
    }
});



$(document).on('click','.removal-button',function(e) {
    // $('#optionaldetails-os .skip-btn').get(0).firstChild.nodeValue = "Skip ";
    //$('.skip-btn').text('Skip')
});
  

  //------------------------------------------ InfoLink code  ends here ---------------------------------








