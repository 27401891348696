

// The current application coordinates were pre-registered in a B2C tenant.
export const apiConfig = {
    profileApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/`,
    updateLoginApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/UserAuthentication/Log?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    editPhoneApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/Contact`,
    adddressApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/protected/UserProfile/Address`,
    notificationApi: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_NOTIFICATION_API_SERVICE_NAME}/v1/protected/Notification/`,
    stateApi: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_PROFILE_API_SERVICE_NAME}/v1/public/IdentityExperience/States/`,
    mobileAppPromo: `${window.DEFINE_VALUE.JS_WEB_URL}/${window.DEFINE_VALUE.JS_NOTIFICATION_API_SERVICE_NAME}/v1/public/PushNotification/MobileAppPromo?apikey=${window.DEFINE_VALUE.JS_API_KEY}`,
    techAvailability: `${window.DEFINE_VALUE.JS_API_URL}/unifiedsyncplatform-api/v1/public/UnifiedSyncPlatform/TechAvailability`,
    serviceProtypeAggregates: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/protypes/serviceprotypes-aggregates/search`,
    fullAttribute: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/fullattribute/franchiseWeblocations/search`,
    serviceSeacrhTerm: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public`,
    calculateEstimate: `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/CalculateEstimate`,
    serviceMarketing: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/customer-marketing-regions/`,
    serviceMarketWeblocation: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/franchiseWebLocationCustomerMarketingRegion/weblocations`,
    serviceAggregates: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/`,
    serviceProtype: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/service-pro-types/`,
    serviceByNEI: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/api/services/search`,
    FranchiseLookUpByServiceAddress: `${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress`,
    genericLeadSources: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/generic-lead-sources`,
    franchiseWebLocationProfileFees: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_FranchiseGroup_URL}/v1/public/franchiseWebLocationProfileFees/franchiseWeblocations/`,
    serviceAggregatesWeblocation: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/franchise-web-locations/`,
    makeByConcept: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/`,
    serviceTypeProblemAggregates: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/concepts/`,
    serviceWebcategoriesAggregates: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/services/servicewebcategories/serviceprotypes-aggregates/search`,
    digitalOrchestrationFileUploadApi: `${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_DigitalOrchestrations_URL}/v1/public/OnlineSchedulerFileUpload/getpresignedurl`,
    reviewaggregates:`${window.DEFINE_VALUE.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Reviews/review-aggregates/search?apikey=${window.DEFINE_VALUE.JS_API_KEY}&PageNumber=1&PageSize=10&`,
    serviceWebcategoriesLeadAggregates:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/franchiseWebLocations/service-aggregates/search?`,
    serviceAggregatesWeblocationLead:`${window.DEFINE_VALUE.JS_API_URL}/${window.DEFINE_VALUE.JS_ServiceOrchestrations_URL}/v1/public/service/config/menu/franchise_web_location_id/`,
};