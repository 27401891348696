import { makeid } from ".";
import { neiLocalStorage } from '../config';


export function getSessionID()
{
    if(neiLocalStorage){
        let decodeData = neiLocalStorage.decodedAccessToken;
        let userStatus = decodeData.nonce;
        let uniqueId = makeid(5);
        if (userStatus)
        {
            return decodeData.nonce;
        }
        return uniqueId;
    }
    else{
       return sessionStorage.getItem('sessionID'); 
    }
}
