export function setAddressPrimaryForm(address:any){
    if(address){
       if(address.zip == window.objOSGlobal.requestBodyValues.PostalCode){
        $("#service-address-screen #input-service-address").val(address.addressLine1);
        $("#service-address-screen #input-service-address-2").val(address.addressLine2);
        $("#service-address-screen #input-service-city").val(address.city);
        $("#input-service-state option[value="+address.state+"]").attr('selected','selected');
        $("#service-address-screen #input-service-zipcode").val(address.zip);
        $("#service-address-screen .next").removeClass("disble-btn").prop("disabled", false);
       }         
    }
}