import {phoneFormatterScheduler} from './phoneFormatterScheduler'
export function defaultFunctionCall(){
    phoneFormatterScheduler('[name="phonenumber"]');
    $('.about-home-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#calendar-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#contact-information-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#service-address-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#service-details-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#warranty-screen .next').addClass('disble-btn').prop('disabled', true);
    $('#vehicle-information-1 .next').addClass('disble-btn').prop('disabled', true);
    $("#vehicle-information-3 .next").addClass('disble-btn').prop('disabled', true);
}