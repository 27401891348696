import { $ } from "../third-party-imports"

export function clearAddressFormData()
{
    $('#profileAddress').val('');
    $("#profileAddress2").val('');
    $("#profileCity").val('');
    $("#profileZipCode").val('');
    $("#profileState").val($("#profileState option:first").val());
    //$("#profileState").val('');
    //$("#profileType").val('');
    $('#profileAddressBtn').attr('data-edit', '');
}
