import { serviceCategoryId } from ".";
import { addClassInModalWindow, chunkArray, get_data, remove_data, store_data, start_loader, stop_loader } from "../util";
import { $ } from "../third-party-imports"
import { apiConfig } from "../config";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";

//----------------------------------------------  service Prototype Logic starts here--------------------------------

export function storeProTypeID(protypeid: any, event: any)
{
    // var liText = document.getElementById(serviceid).innerText;
    //$('.js-serviceListControl').val(liText);
    event.preventDefault();
    $('#category-search-row').remove();
    if (!$('#confirmation-os').hasClass('hidden'))
    {
        $('#confirmation-os').addClass('hidden');
        $('#js-onlineScheduleDialog').removeClass("active");
        $('#js-serviceTypeDialog').css("display", "block");
        $('#service-slide-4').removeClass('active');
        $('.personalInfoEntry.servive-form-wrapper').removeClass('hidden');
        window.objOSGlobal.paramsUI = {};
    }
    $('#service-search-page').removeClass('active');
    if(window.location.href.indexOf("confirmation") > -1){
        $('#js-schedulerDialog').modal("show");
    }
    $('#service-category-page').addClass('active');
    addClassInModalWindow("service-category-page");

    var searchTerm = get_data('zipcode');

    remove_data('protypeid');
    store_data(protypeid, 'protypeid');
    var idProTypeLi = 'serviceProLi' + protypeid;
    var proTypeName = $('#' + idProTypeLi).text();
    $('.zipcodeServiceCatSearch').text(searchTerm);
    $('.protypeServiceCatSearch').text(proTypeName);
    var protypeId = get_data('protypeid');
    var serviceModifier = $('input[name="chkServiceType"]:checked').val();
    var country = window.location.href.split('/')[3].replace(/[^a-zA-Z ]/g, "") == "ca" ? "2" : "1";
    store_data(serviceModifier, "serviceModifierId");
    window.objOSGlobal.requestBodyValues.serviceModifier = $('input[name="chkServiceType"]:checked').parent().find('.radio-text').text();
    sessionStorage.setItem('ServiceModifierValue',window.objOSGlobal.requestBodyValues.serviceModifier);
    start_loader();
    $.ajax({
        type: "GET",
        // url: apiConfig.serviceProtype+searchprotypeid+"/services?zip_code="+searchTerm+"&apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        url: apiConfig.serviceWebcategoriesAggregates+"?ProtypeId="+protypeId+"&countryid="+country+"&ZipCode="+searchTerm+"&ServiceModifier="+serviceModifier+"&apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        success: function (result, status, xhr)
        {
            stop_loader();
            
            if (!result || result.length == 0)
            {
                $('#service-category-page').removeClass('active');
                $('#no-search-result').addClass('active');
                $('.noResultZipSpan').text(get_data('zipcode'));
                $('.noResultCatSpan').show();
                $('.noResultCatSpan').text(proTypeName);
            }
            else
            {
                store_data(result, 'seriveCategoryList');
                var categoryArr = result || [];

                var splitCount;
                if (result.length < 3)
                    splitCount = 1;
                else
                    splitCount = Math.round(result.length / 3);
                // Split in group of 4 items
                result = chunkArray(categoryArr, splitCount);
                // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
                if(result.length === 1) {
                  var zipcode = get_data('zipcode');
                  $.each(result, function(index, element){
                    $.each(element, function (index, proptype)
                    {
                        serviceCategoryId(zipcode, proptype, "");
                    })
                  })
                } else {
                var colmd4Elements = $.map(result, function (el)
                {
                    var sub_ul = $('<ul class="list-unstyled services-link-list"></ul>  ');
                    var zipcode = get_data('zipcode');
                    $.each(el, function (index, proptype)
                    {
                        let liEl = window.document.createElement("li");
                        liEl.id = `${proptype.serviceWebCategoryId != 0 ? proptype.serviceWebCategoryId : proptype?.services[0]?.serviceId}`;
                        liEl.addEventListener("click", (event: any) => {
                            return serviceCategoryId(zipcode, proptype, event);
                        });
                        liEl.innerHTML = `<a href="#" data-id="${liEl.id}">${proptype.categoryName ? proptype.categoryName :proptype?.services[0]?.serviceName}</a>`;
                        var sub_li = $(liEl);
                        sub_ul.append(sub_li);
                    });
                    var colmd4 = $('<div class="col-md-4"></div>').append(sub_ul);
                    return colmd4;
                });

                var rowClass = $('<div class="row" id="category-search-row"> <div> ').append(colmd4Elements);
                $("#service-cat-link-list").append(rowClass);
                //$("#serviceProtypeLinkListFinal").append(rowClass);
            }
        }

        },
        error: function (xhr, status, error)
        {
            stop_loader();
            console.log(xhr, status, error);
        }
    });

}
