import { apiConfig, neiLocalStorage, tokenRequest } from "../config";
import { acquireToken } from "./acquireToken";
import { isAccessTokenExpire } from "./isAccessTokenExpire";
import { updateUI } from "../ui"
import { callApiWithAccessToken } from "../api";


export function passTokenToApi()
{
    var tokenExpire = isAccessTokenExpire();
    if (tokenExpire)
    {
        acquireToken(tokenRequest);
    } 
    else
    {
        try
        {
            let optionKey = {
                endpoint: apiConfig.profileApi,
                token: neiLocalStorage.accessToken,
                neighborlyUserId: 0, //neighborlyUserId,
            };
            callApiWithAccessToken(optionKey).then(() =>
            {
                updateUI();
            });
        } 
        catch (err)
        {
            console.log(err);
        }
    }
}
