
export function getStar(value: any)
{
    if (!value)
        return '';
    var starValue = value.toString().split('.');
    var str = '';
    for (let i = 0; i < starValue[0]; i++)
    {
        str += '<li><img src="/us/en-us/_assets/images/star-full.svg" alt="star full" loading="lazy"></li>';
    }
    if (starValue[1] > 0)
    {
        str += '<li><img src="/us/en-us/_assets/images/star-half.svg" alt="star half" loading="lazy"></li>';
    }
    return str;
}
