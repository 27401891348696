import {apiConfig} from '../config/apiConfig';
export function getReviewBrandBox(conceptid:any){
    $.ajax({
        url: `${apiConfig.reviewaggregates}ConceptId=${conceptid}`,
        dataType: 'json',
        type: 'GET',
        success: function (data: any){
          let overallRating;
          if(data[0]['overallRating'].toString().indexOf('.') === -1) {
            overallRating = `${data[0]['overallRating']}.0`
          }else {
            overallRating = data[0]['overallRating'];
          }
          var starRating = genrateStarRating(overallRating);
          var displayCount = data[0]['grandTotal']?.toLocaleString();
          $('.rating-circle').addClass('heroBannerStar');
          $('.rating-circle').empty();
          $('.rating-circle').append(starRating);
          $('#five-star-badge-display-1-review-count').html(`${displayCount} Reviews`);
        }, error: function (e: any){
           console.log(e)
        }
    }); 
}
function genrateStarRating(rating:any){
    return `<five-star-badge-display
                rating="${rating}"
            >
            </five-star-badge-display>`
}