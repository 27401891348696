import * as Msal from 'msal';
import { apiConfig, b2cPolicies, neiLocalStorage } from ".";
import { updateProfilInfo } from "../auth-redirect";
import { $ } from "../third-party-imports"
import { setSessionRememberMe, updateUI } from '../ui';
import { callApiWithAccessToken } from "../api";

const msalConfig: Msal.Configuration = {
    auth: {
        clientId: window.DEFINE_VALUE.JS_APP_ADB2C_CLIENT_ID,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        redirectUri: '',
        validateAuthority: false,

    },
    system: {
        loadFrameTimeout: 35000
    },
    cache: {
        cacheLocation: window.DEFINE_VALUE.JS_CACHE_LOCATION,
        storeAuthStateInCookie: false
    }
};

function languageTrans(msalConfig: Msal.Configuration) 
{
    var slug = window.location.href.split('/');
    if(slug[3].replace(/[^a-zA-Z ]/g, "") =="ca") {
        msalConfig.auth.redirectUri = window.DEFINE_VALUE.JS_redirectUri + 'ca/';
    } else {
        msalConfig.auth.redirectUri = window.DEFINE_VALUE.JS_redirectUri
    }
    return msalConfig;
}

languageTrans(msalConfig);

const myMSALObj = new Msal.UserAgentApplication(msalConfig);

myMSALObj.handleRedirectCallback((error: any, response: any) =>
{
    window.history.pushState('', document.title, window.location.pathname + window.location.search);
    $(() =>{
        if (error)
        {
            if (error.errorMessage.indexOf("AADB2C90118") > -1)
            {
                try
                {
                    myMSALObj.loginRedirect(b2cPolicies.authorities.forgotPassword);
                } catch (err)
                {
                    console.log(err);
                }
            }
        }
        else
        {
            const account = myMSALObj.getAccount();
            if (response.tokenType.toLowerCase() === "id_token" && response.idToken.claims['acr'].toLowerCase() === b2cPolicies.names.signUpSignIn.toLowerCase())
            {                
                if (account)
                {
                    updateUI();
                    setSessionRememberMe();
                }
            }
            else if (response.tokenType.toLowerCase() === "id_token" && response.idToken.claims['acr'].toLowerCase() === b2cPolicies.names.signUpPolicy.toLowerCase())
            {                
                if (account)
                {
                    updateUI();
                    setSessionRememberMe();
                }
            }
            else if (response.tokenType.toLowerCase() === "id_token")
            {
                if (account)
                {
                    let decodeData = neiLocalStorage.decodedAccessToken;
                    if (decodeData.status !== 'Success')
                    {
                        setTimeout(function ()
                        {
                            var userDetails = JSON.parse(localStorage.getItem('profile'));
                            updateProfilInfo(userDetails);
                        }, 30);
                        var previousToken = localStorage.getItem('previousToken');
                        if (previousToken)
                        {
                            neiLocalStorage.accessToken = previousToken
                            $('.errorMsgPopUp').show();
                        }
                        $('body').addClass('scrollHide popUpHeaderFixed');
                        $('#js-popupModalDialog').show();
                    }
                    localStorage.removeItem('previousToken');
                    updateUI();
                    // setSessionRememberMe();
                }
            }
            else if (response.tokenType.toLowerCase() === "access_token")
            {
                neiLocalStorage.accessToken = response.accessToken;
                if (neiLocalStorage.accessToken)
                {
                    try
                    {
                        //let decodeData = neiLocalStorage.decodedAccessToken;
                       // let neighborlyUserId = decodeData.neighborlyUserId;
                        let optionKey = {
                            endpoint: apiConfig.profileApi,
                            token: neiLocalStorage.accessToken,
                           // neighborlyUserId: 0, //neighborlyUserId,
                        };
                        callApiWithAccessToken(optionKey).then(() =>
                        {
                            updateUI();
                            // const updateLoginKey = {
                            //     endpoint: apiConfig.updateLoginApi,
                            //     socialProfileDomain: 1,
                            //     neighborlyUserId: 0, //neighborlyUserId,
                            //     method: "post",
                            //     header: HEADERS
                            // };
                            // updateLogin(updateLoginKey);
                        });
                    } catch (err)
                    {
                        console.log(err);
                    }
                }
            } 
        }
    });
});

export const getMSALInstance = () => myMSALObj; 