import { neiLocalStorage } from '..';
import { phoneVerification } from '../profile/phoneVerification';
import { clickOnNotification } from "./clickOnNotification";
import { notificationsAsReadByUser } from "./notificationsAsReadByUser";
import { ProfileDropdownClick } from "./ProfileDropdownClick";
import { $ } from "../third-party-imports"

// Click on Profile Dropdown Click 
//var verifyProfile = JSON.parse(localStorage.getItem('profile'));
// Document is ready 

$(function ()
{
    var msalToken = neiLocalStorage.accessToken;
    if (msalToken)
    {
        ProfileDropdownClick();
        clickOnNotification();
        $('.neighbor-dropdown .back-btn').click(function ()
        {
            notificationsAsReadByUser();
        });
    }
    phoneVerification();
});


