import { HEADERS } from "../api";
export function setDynamicMessages(isleadonly: Boolean) {
    var country = window.location.href.split('/')[3].replace('#',"") === "ca" ? "ca" : "us";
    var language = window.location.href.split('/')[3].replace('#',"") === "ca" ? "en-ca" : "en-us";
     /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
    $.ajax({
        url: window.DEFINE_VALUE.JS_redirectUri + country+"/"+language+"/_assets/brands.json",
        type: "GET",
        headers: HEADERS,
        success: function (data)
        {
            // stop_loader();
            $.map(data, function (el)
            {
                if (el.id === window.objOSGlobal.requestBodyValues.ConceptCalledId)
                {                    
                    $('#calendar-screen .sub-heading').html(el.schedule_subtitle);
                    //$('#calendar-screen .sub-heading').text().replace("{phoneNumber}", window.objOSGlobal.paramsUI.brandPhoneNumber)
                    var brandNumber = localStorage.getItem('brandPhoneNumber');
                    let currentText = $('#calendar-screen .sub-heading').text();
                    let updatedPhone = currentText.replace("{phoneNumber}", brandNumber !=null ? brandNumber.replace(/['"]+/g, ''):'');
                    $('#calendar-screen .sub-heading').text(updatedPhone);
                    var confirmTitle = isleadonly ? el.confirmation_title_lead : el.confirmation_title_schedule;
                    var confirmSubText = isleadonly ? el.confirmation_subtext_lead : el.confirmation_subtext_schedule;
                    sessionStorage.removeItem('confirmTitle');
                    sessionStorage.removeItem('confirmSubText');
                    sessionStorage.setItem('confirmTitle', confirmTitle);
                    sessionStorage.setItem('confirmSubText', confirmSubText);
                }
            });
        }
    });
}