import {start_loader,stop_loader} from "../util";
import { apiConfig } from "../config/apiConfig";
import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { HEADERS } from "../api";
export function mollyMaidEstimate(){
    var {WebLocationId,Note,PostalCode,EstimateSquareFeet,EstimateBedrooms,EstimateBathrooms,licenseNumber,Notes} = window.objOSGlobal.requestBodyValues
    var payload ={
        "franchiseWebLocationId": WebLocationId,
        "LicenseNumber": licenseNumber,
        "LeadSourceId": "20",
        "SecondaryLeadSourceId": 0,
        "Note": Note,
        "EstimateTitle": "null",
        "FirstName": "test",
        "LastName": "test",
        "Address": "asd asd",
        "City": "Salado",
        "State": "CA",
        "PostalCode": PostalCode,
        "Email": "test@test.com",
        "Phone": "(111) 111-1111",
        "PreferredCommunicationType": "null",
        "EstimateSquareFeet": Number(EstimateSquareFeet),
        "EstimateBedrooms": Number(EstimateBedrooms),
        "EstimateBathrooms": Number(EstimateBathrooms),
        "ReceiveEmailUpdates": true
    }
    $('.result-stack-logo').removeClass('hidden');
    $('.progressbar, .progress').show();
    $("#modal-header-contact,#schedulermodallabel").removeClass('hidden');
    start_loader();
    $.ajax({
        url: apiConfig.calculateEstimate + "?apikey=" + window.DEFINE_VALUE.JS_API_KEY+"&"+getNblyApiHeaders(),
        type: "POST",
        headers: HEADERS,
        data: JSON.stringify(payload),
        success: function (data: any){  
            stop_loader();
        $('.estimation-text').css("display","inline-block");
		$('#estimate-amount').addClass("d-none");
        if(Notes =="Yes"){
            $('.estimation-text').text('$' + Math.round(data.estimateMoveRangeLow) + "-$" +Math.round(data.estimateMoveRangeHigh));
        }else{
            $('.estimation-text').text('$' + Math.round(data.estimateOccasionalRangeLow) + "-$" +Math.round(data.estimateOccasionalRangeHigh));
        }
           var note = window.objOSGlobal.requestBodyValues.Note;
           if(note !=''){
              window.objOSGlobal.requestBodyValues.Note = note + '\nMLY Estimate: ' + $('.estimation-text').text();
           }
        },
        error: function (status, statusText,  responseText) {
            stop_loader();
           var phoneNumber = window.objOSGlobal.paramsUI.brandPhoneNumber;
           $('.estimation-text').css("display","none");
		   $('#estimate-amount').removeClass("d-none");
		   $('#estimate-amount a').empty();
           $('#estimate-amount').append("<a href=\"tel:"+phoneNumber+"\">"+phoneNumber+"</a>");
        }
    });
}
