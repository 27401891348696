import { getNblyApiHeaders } from "../api/getNblyApiHeaders";
import { apiConfig } from "../config";
import { $ } from "../third-party-imports"
import { start_loader, stop_loader } from ".";

export function mobileAppPromo(currEle:any,payload: any)
{
    $(".mobileInputBox span").empty();
    start_loader();
    $.ajax({
        type: "POST",
        url: apiConfig.mobileAppPromo+"&"+getNblyApiHeaders(),
        data: JSON.stringify(payload),
        headers: {
            // Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('bearer_token')).access_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        success: function (result, status, xhr)
        {
            stop_loader();
            $(currEle).parent(".mobileInputBox").append("<span>You will receive a text to the phone number entered.</span>");
            // $('.inputMobileNumberMobileApp').val('');
            // $('#inputMobileNumberBtn').removeClass('active-input');
        },
        error: function (xhr, status, error)
        {
            stop_loader();
            $(".mobileInputBox span").empty();
            console.log(xhr, status, error);
        }
    });
}
