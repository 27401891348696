import { $ } from "../third-party-imports"

//var isVisibleNotification = false;
export function isVisibleNotificaionPopUp()
{
    $("body").one('click', function (e)
    {
        //if (isVisibleNotification)
        //{
        //    e.stopPropagation();
        //    notificationsAsReadByUser(msalToken);
        //    isVisibleNotification = false;
        //}
    });

}
