import { $ } from "../third-party-imports"

export function media_css()
{
    var win_height = $(window).height();
    if ($(window).width() < 480)
    {
        if (win_height > 624 && win_height < 660)
        {
            $('body').removeClass('hei_670 hei_850 hei_900 hei_750 hei_800 hei_780');
            $('body').addClass('hei_650');
        }
        if (win_height > 670 && win_height < 700)
        {
            $('body').removeClass('hei_850 hei_900 hei_750 hei_800 hei_780 hei_600');
            $('body').addClass('hei_670');
        }
        if (win_height > 749 && win_height < 781)
        {
            $('body').removeClass('hei_850 hei_900 hei_670 hei_800 hei_780 hei_600');
            $('body').addClass('hei_750');
        }
        if (win_height > 780 && win_height < 801)
        {
            $('body').removeClass('hei_850 hei_900 hei_670 hei_800 hei_750 hei_600');
            $('body').addClass('hei_780');
        }
        if (win_height > 800 && win_height < 851)
        {
            $('body').removeClass('hei_850 hei_900 hei_670 hei_750 hei_780 hei_600');
            $('body').addClass('hei_800');
        }
        if (win_height > 850 && win_height < 881)
        {
            $('body').removeClass('hei_800 hei_670 hei_750 hei_780 hei_900 hei_600');
            $('body').addClass('hei_850');
        }
        if (win_height > 882)
        {
            $('body').removeClass('hei_850 hei_800 hei_670 hei_750 hei_780 hei_600');
            $('body').addClass('hei_900');
        }
    }
}
