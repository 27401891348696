import { attachSearchEvent } from "./attachSearchEvent";
import { createArticleList } from "./createArticleList";
import { getCurrentContext } from "./getCurrentContext";
import { initPagination } from "./initPagination";
import { $ } from "../third-party-imports"



$(document).ready(function() {
    // Check whether g2Data is available
    if (window.g2Data) {
        const totalCount = window.g2Data.response.numFound;
        
        createArticleList();
    
        initPagination(totalCount);
    
        attachSearchEvent();
		
		getCurrentContext(); // This is to get the current page URL which can be used on blog article page return button
    };
});
