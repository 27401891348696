import { updateFooterLinks } from "./updateFooterLinks";
import { attachNextPrevClickEvent } from "./attachNextPrevClickEvent";
import { $ } from "../third-party-imports";
// import { start_loader, stop_loader} from "../util";

/**
 * Get All Blogs
 */

export function getAllBlogs(g2CollectionURL: any){
    /*To show the loader only in online scheduler flow so commenting the loader [+57326] 
    start_loader();
    */
    $.ajax({
        type: 'GET',
        url: g2CollectionURL,
        dataType: 'json',
        headers : {'Accept-Encoding': 'gzip'},
        async: true,
        cache: false,
        success: function (data){
            var allBlogs = data.response.docs;
            // Update Footer Links
            updateFooterLinks(allBlogs);
            // attach click event only if links are enabled.
            attachNextPrevClickEvent();
            // stop_loader();
        },
        error: function (e: any){
            // stop_loader();
            //console.log(e.message);
        }
    });
}
