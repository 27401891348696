import { isValidUsOrCanadaZip, remove_data, store_data } from "../util";
import { $, googleMaps } from "../third-party-imports"



export async function validateCurrentLocZip(btnEle: any)
{
    const { LatLng, Geocoder } = await googleMaps();

    var divEle = $(btnEle).parent();

    var notValidEle = $(divEle).find('#notValidZipCode');
    var inputEle = $(divEle).find('.serviceSearchInputReuseClass');
    var localHelpBtnEle = $(divEle).find('.findHelpBtnReuseClass');

    if (navigator.geolocation)
    {
        navigator.geolocation.getCurrentPosition(function (position)
        {
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var point = new LatLng(lat, long);
            var zipcode = '';

            new Geocoder().geocode({ 'location': point }, function (res, status)
            {
                var address = res[0].address_components;
                for (var i = 0; i < address.length; i++)
                {
                    if (address[i].types.includes("postal_code"))
                    {
                        zipcode = address[i].short_name;
                        $(inputEle).val(zipcode);
                        if (isValidUsOrCanadaZip(zipcode))
                        {
                            $(localHelpBtnEle).removeClass('disble-btn');
                            $(localHelpBtnEle).prop('disabled', false);
                            $(localHelpBtnEle).addClass('active-btn');

                            remove_data('zipcode');
                            store_data(zipcode, 'zipcode');
                        }
                        else
                        {
                            $(localHelpBtnEle).addClass('disble-btn');
                            $(localHelpBtnEle).prop('disabled', true);
                            $(notValidEle).show();
                        }
                    }
                }
            });
        },
            function error(err)
            {
                console.warn(`ERROR(${err.code}): ${err.message}`);
            }, {
            enableHighAccuracy: true
        });
    }
}
