import { $, googleMaps } from "../third-party-imports"
import { isValidUsOrCanadaZip, remove_data,store_data} from "../util";
export async function  currentLoadationBtn(){
        const { LatLng, Geocoder } = await googleMaps();
        navigator.geolocation.getCurrentPosition(function (position){
            var lat = position.coords.latitude;
            var long = position.coords.longitude;
            var point = new LatLng(lat, long);
            var zipcode = '';

            new Geocoder().geocode({ "location": point }, function (res, status){
                var address = res[0].address_components;
                for (var i = 0; i < address.length; i++){
                    if (address[i].types.includes("postal_code")){
                        zipcode = address[i].short_name;
                        $('.location-services input[name="locationSearch"]').val(zipcode);
                        $('.location-services').valid();
                        if (isValidUsOrCanadaZip(zipcode)){
                            $('#service-entry-btn').removeClass('disble-btn');
                            $('#service-entry-btn').prop('disabled', false);

                            $('#findLocalHelp').prop('disabled', false);
                            remove_data('zipcode');
                            store_data(zipcode, 'zipcode');
                        } else{
                            $('.invalid-zip-meg').css('visibility','hidden');
                            $('#service-entry-btn').addClass('disble-btn');
                            $('#service-entry-btn').prop('disabled', true);
                            $('.notValidZip').show();
                            $('#notValidZipCodeServiceEntry').show();
                        }
                    }
                }
            });
        });
}