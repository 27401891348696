import { $ } from "../third-party-imports"



export function getMultipleData(itrator: any, mapper: any, elementConstructor: any, editHander: any)
{
    $.each(itrator, function (index, value)
    {
        var val = mapper(value);
        elementConstructor(val, index);
    });
    editHander(itrator);
}
