import { verificatinPhoneNumber } from "./verificatinPhoneNumber";
import { profileVars } from ".";

export function phoneVerification()
{
    var isProfile = JSON.parse(localStorage.getItem('profile'));
    if (isProfile)
    {
        profileVars.neighborlyUserId = isProfile.neighborlyUserId;
        var arrayPhoneNumber = isProfile.phone.filter(function (item: any)
        {
            return item.primaryPhoneID == 1 && item.contactVerificationStatusId == 1;
        });
        if (arrayPhoneNumber.length != 0)
        {
            verificatinPhoneNumber(arrayPhoneNumber[0]);
        }
    }
}
